export const generateFullName = (
  firstName: string | undefined,
  lastName: string | undefined,
  seperator?: string | undefined
): string => {
  const fullName = `${firstName ?? ''}${seperator ?? ' '}${lastName ?? ''}`.trim();
  if (fullName === '') {
    return 'Unnamed Customer';
  }
  return fullName;
};
