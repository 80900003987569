import * as React from 'react';

import { InfoTooltip } from './Tooltip';
import { InlineSpinner } from '../assets/Spinner';
import { formatDateTime } from '../dateUtils';
import {
  ChangelogItem,
  ChangelogOperation,
  CustomerChangelogItem,
  SystemChangelogItem,
} from '../pages/types';

const symbolMap = {
  CREATE: '➕',
  UPDATE: '✏️',
  DELETE: '❌',
};

const FormattedCustomerChangelogItem = ({ change }: { change: CustomerChangelogItem }) => {
  let rowContent = null;

  if (change.operationType === ChangelogOperation.CREATE)
    rowContent =
      change.fieldName === 'id'
        ? `created new ${change.table} <${change.newValue}>`
        : `set new field ${change.fieldName} to ${change.newValue} on the ${change.table} table`;
  else if (change.operationType === ChangelogOperation.UPDATE)
    rowContent = `updated ${change.fieldName} ${
      change.table !== 'Payment Method' && `to ${change.newValue} on the ${change.table} table`
    }`;
  else if (change.operationType === ChangelogOperation.DELETE)
    rowContent = `deleted <${change.newValue}> from the ${change.table} table`;

  return (
    <div className="flex">
      <div className="mt-2 text-sm min-w-[200px]">{`[${formatDateTime(change.createdAt)}]`}</div>
      <div className="mt-2 text-sm">
        {symbolMap[change.operationType]} <b>{change.authorEmail}</b> {rowContent}{' '}
        <div className="ml-1 inline-flex">
          <InfoTooltip
            width="13"
            height="13"
            text={`Application: ${change.application ?? 'n/a'}, Primary Key: ${
              change.rowPrimaryKey
            }`}
          />
        </div>
      </div>
    </div>
  );
};

const FormattedSystemChangelogItem = ({ change }: { change: SystemChangelogItem }) => {
  const createdAt = change.createdAt.split('#')[0];
  let rowContent = '';

  if (change.category === 'Item') {
    rowContent = `created new item ${change.newValue}`;
  } else if (change.category === 'Product Item') {
    if (change.operationType === 'CREATE')
      rowContent = `added product-item mapping for ${change.newValue}`;
    else if (change.operationType === 'UPDATE')
      rowContent = `updated product-item mapping for ${change.newValue}`;
  } else if (change.category === 'Discount') {
    if (change.operationType === 'CREATE')
      rowContent = `created new discount code ${change.newValue}`;
    else if (change.operationType === 'UPDATE') {
      const actionIcon = change.newValue.startsWith('ACTIVATED') ? '✅' : '🛑';
      rowContent = `${actionIcon} ${change.newValue}`;
    }
  }

  return (
    <div className="flex">
      <div className="mt-2 text-sm min-w-[200px]">{`[${formatDateTime(createdAt)}]`}</div>
      <div className="mt-2 text-sm">
        {symbolMap[change.operationType]} <b>{change.authorEmail}</b> {rowContent}{' '}
        <div className="ml-1 inline-flex">
          <InfoTooltip
            width="13"
            height="13"
            text={`Application: ${change.application ?? 'n/a'}, ${change.category} ID: ${
              change.objectId
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export const ChangelogList = ({
  type,
  isLoading,
  changelogError,
  changelogItems,
}: {
  type: 'customer' | 'system';
  isLoading: boolean;
  changelogError: string;
  changelogItems: ChangelogItem[];
}) => {
  return (
    <div className="mt-2 overflow-y-auto max-h-[400px] bg-white border rounded-lg p-4">
      {isLoading && <InlineSpinner />}
      {!isLoading && changelogError && <div>{changelogError}</div>}
      {!isLoading && !changelogError && changelogItems.length === 0 && (
        <div className="mt-2 text-sm">No Changelog to Display</div>
      )}
      {changelogItems.length !== 0 &&
        changelogItems.map((change: ChangelogItem, index) => {
          return type === 'customer' ? (
            <FormattedCustomerChangelogItem change={change as CustomerChangelogItem} key={index} />
          ) : (
            <FormattedSystemChangelogItem change={change as SystemChangelogItem} key={index} />
          );
        })}
    </div>
  );
};
