import * as React from 'react';
import { useEffect, useState } from 'react';

import { Customer, CustomerChangelogItem } from './types';
import { axios } from '../api';
import { Spinner } from '../assets/Spinner';
import { ChangelogList } from '../component/ChangelogList';
import { NotFound } from '../component/NotFound';
import { formatDateTime } from '../dateUtils';
import { useCustomParams } from '../hooks/useCustomParams';
import { generateFullName } from '../nameUtils';
import { formatPhoneNumber } from '../phoneUtils';

export const CustomerChangelog = () => {
  const { customerId } = useCustomParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [selectedPet, setSelectedPet] = useState<string>('All_Changes');
  const [changelog, setChangelog] = useState<CustomerChangelogItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changelogError, setChangelogError] = useState('');

  const getCustomer = () => {
    axios
      .get(`accounts/${customerId}`)
      .then((response) => {
        setCustomer(response.data);
      })
      .catch(() => <NotFound />);
  };

  const fetchChangelogResolvePart = () =>
    axios.get(`customer_changelog/${customerId}`).then((response) => {
      setChangelog(response.data);
      setIsLoading(false);
    });

  const getChangelog = () => {
    fetchChangelogResolvePart().catch(() => {
      fetchChangelogResolvePart().catch(() => {
        setIsLoading(false);
        setChangelogError('Request failed, please try to refresh the page');
      });
    });
  };

  useEffect(() => {
    getCustomer();
    getChangelog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!customer) return <Spinner />;

  const pets = customer.pets.map((pet) => ({ id: pet.id, name: pet.name }));
  const logs = [{ id: 'All_Changes', name: 'All Changes' }, ...pets];

  const filteredAndSortedChangelog = changelog
    .filter((change: any) => {
      if (selectedPet === 'All_Changes') return true;
      return (change.petId ?? []).includes(selectedPet);
    })
    .sort((a, b) => new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf());

  return (
    <div className="h-[calc(100vh-77px)] bg-slate50 p-8 w-full pr-12">
      <div className="py-3">
        <div className="flex justify-between">
          <h1>
            <b>Customer:</b>{' '}
            {`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
          </h1>
        </div>
        <div className="bg-white border rounded-lg mt-3 p-4 shadow-md w-[30em]">
          <div className="mb-2">
            <strong>Customer ID:</strong> {customer.id}
          </div>
          <div className="mb-2">
            <strong>Email address:</strong> {customer.email}
          </div>
          <div className="mb-2">
            <strong>Phone number:</strong> {formatPhoneNumber(customer.phone_number)}
          </div>
          <div className="mb-2">
            <strong>Customer since:</strong> {formatDateTime(customer.created)}
          </div>
        </div>
      </div>
      <h2 className="mb-1 flex items-center">Changelog</h2>

      <div>
        {logs.map((pet: any) => {
          return (
            <a
              key={pet.id}
              href="#"
              onClick={() => setSelectedPet(pet.id)}
              className={`inline-block px-2 py-1 m-1 rounded border cursor-pointer ${
                pet.id === selectedPet ? 'bg-primary' : ''
              }`}
            >
              {pet.name}
            </a>
          );
        })}
      </div>
      <ChangelogList
        type="customer"
        isLoading={isLoading}
        changelogError={changelogError}
        changelogItems={filteredAndSortedChangelog}
      />
    </div>
  );
};
