import React, { useState, useEffect, useRef } from 'react';

import { DownArrow } from '../assets/DownArrow';
import { useOutsideHandler } from '../hooks/useOutsideHandler';
import { Option } from '../pages/types';

interface MultiSelectProps {
  options: Option[];
  onSelect: (selectedOptions: string[]) => void;
  selectedOptions?: string[];
  placeholder?: string;
  selectedOptionAsObject?: boolean;
}

export const MultiSelectDropdown = ({
  options,
  onSelect,
  selectedOptions: initialSelectedOptions,
  placeholder = 'Select options',
  selectedOptionAsObject = false,
}: MultiSelectProps) => {
  const [selectedOptions, setSelectedOptions] = useState<any[]>(initialSelectedOptions || []);
  const [isOpen, setIsOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const filteredOptions = options.filter((option) =>
    option.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    onSelect(selectedOptions);
  }, [selectedOptions, onSelect]);

  const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const {
      value,
      dataset: { label },
    } = event.target;
    setSelectedOptions((prevSelected) =>
      !selectedOptionAsObject
        ? prevSelected.includes(value)
          ? prevSelected.filter((item) => item !== value)
          : [...prevSelected, value]
        : prevSelected.filter((i: any) => i.value === value).length
        ? prevSelected.filter((item: any) => item.value !== value)
        : [...prevSelected, { value, label }]
    );
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const selectedLabels = selectedOptions.map((value) => {
    const option = options.find((o) => o.value === (!selectedOptionAsObject ? value : value.value));
    return option ? option.label : '';
  });

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full">
      <div className="w-full">
        <div>
          <button
            onClick={toggleDropdown}
            type="button"
            className="inline-flex w-full px-4 py-2 text-sm font-medium bg-secondary border border-black rounded overflow-x-auto"
          >
            <div className="flex justify-between w-full">
              {selectedLabels.length > 0 ? selectedLabels.join(', ') : placeholder}
              <DownArrow className="w-5 h-5" />
            </div>
          </button>
        </div>
      </div>

      {isOpen && (
        <div className="absolute rounded-md border border-black bg-secondary w-full max-h-48 overflow-y-auto z-50">
          <div className="p-2">
            <input
              type="text"
              placeholder={placeholder}
              className="w-full border border-black rounded px-2 py-1"
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
          </div>
          <div className="max-h-36 overflow-y-auto">
            {filteredOptions.map((option) => (
              <label key={option.value} className="px-4 py-2 cursor-pointer items-center flex">
                <input
                  type="checkbox"
                  value={option.value}
                  data-label={option.label}
                  checked={
                    !selectedOptionAsObject
                      ? selectedOptions.includes(option.value)
                      : !!selectedOptions.filter((i) => i.value === option.value).length
                  }
                  onChange={handleOptionChange}
                  className="form-checkbox h-5 w-5 text-indigo-600 mr-2"
                />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
