import * as React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { UpdateDiscount } from './UpdateDiscount';
import { getDiscountByCode } from '../api/getDiscountCode';
import { Button } from '../component/Button';
import { TextInput } from '../component/TextInput';
import { BOTTOM_TOAST } from '../toastUtils';

export const DiscountLookup = () => {
  const [discountCode, setDiscountCode] = React.useState('');
  const navigate = useNavigate();
  const { discountId } = useParams<{ discountId: string }>();

  const getDiscountCodeId = async () => {
    const discount = await getDiscountByCode(discountCode);
    if (discount.data.id) {
      navigate(`/discount-lookup/${discount.data.id}`);
      return;
    }
    toast.error('Discount code not found', BOTTOM_TOAST);
  };

  if (discountId) {
    return <UpdateDiscount isStatusEditable={false} />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8 flex flex-col items-center justify-center space-y-4">
      <div className="text-2xl font-bold">Search for a discount code</div>
      <div className="flex space-x-4 w-96">
        <TextInput
          placeholder="None"
          value={discountCode}
          setTextChange={(value) => setDiscountCode(value.toUpperCase())}
        />
        <Button variant="primary" label="Search" onClick={() => getDiscountCodeId()} />
      </div>
    </div>
  );
};
