import { format as formatDate } from 'date-fns';

import { axios } from './axios';

export const getProductItemsExport = async () => {
  const response = await axios({
    url: 'product_items_export',
    method: 'GET',
    responseType: 'blob',
  });
  // create file link in browser's memory
  const href = URL.createObjectURL(response.data);
  // create "a" HTML element with href to file & click
  const link = document.createElement('a');
  link.href = href;
  const timestamp = formatDate(new Date(), 'yyyy-MM-dd_hh.mm.ss');
  link.setAttribute('download', `productitems_${timestamp}.csv`);
  document.body.appendChild(link);
  link.click();
  // clean up "a" element & remove ObjectURL
  document.body.removeChild(link);
  URL.revokeObjectURL(href);
};
