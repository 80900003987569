import * as React from 'react';
import { toast } from 'react-toastify';

import { AccountAndContactInfo } from './AccountAndContactInfo';
import { CustomerStats } from './CustomerStats';
import { CustomerTreatments } from './CustomerTreatments';
import { axios } from '../../api';
import { generateFullName } from '../../nameUtils';
import { Customer, Treatment } from '../../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../../toastUtils';
import { Button } from '../Button';

interface CustomerDetailProps {
  customer: Customer;
  treatments?: Treatment[];
}

export const CustomerDetail = ({ customer, treatments }: CustomerDetailProps) => {
  const handleImpersonateClick = () => {
    axios
      .get(`impersonate/?account_id=${customer.id}`)
      .then((response) => {
        window.open(`${response.data.redirect_to}`, '_blank');
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="py-3 flex flex-col justify-center align-center gap-4">
      <div className="flex justify-between align-top">
        <h1>
          <b>Customer:</b>{' '}
          {`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
        </h1>
        <div className="flex justify-center align-top">
          <Button label="Login as Customer" variant="primary" onClick={handleImpersonateClick} />
        </div>
      </div>
      <div className="flex flex-row flex-wrap justify-start gap-4">
        <AccountAndContactInfo customer={customer} />
        <CustomerStats customer={customer} />
        {treatments?.length ? <CustomerTreatments treatments={treatments} /> : null}
      </div>
    </div>
  );
};
