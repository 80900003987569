import * as React from 'react';
import { Link } from 'react-router-dom';

import { ProductItem } from './types';
import { getProductItemsExport } from '../api';
import { Spinner } from '../assets/Spinner';
import { Table } from '../component/Table';
import { TableTools } from '../component/TableTools';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { ProductItemsHeader } from '../tableHeaders';

export const ProductItems = () => {
  const mapItem = (productItem: ProductItem) => {
    return {
      Products: (
        <Link
          to={`/product-items/update-product-items/${productItem.id}`}
          className="text-blue-500 hover:underline"
        >
          {productItem.code}
        </Link>
      ),
      'Product Name': productItem.name,
      'Product Status': `${productItem.status}`,
      Items: productItem.items.map((item) => `${item.sku} x ${item.quantity}`),
    };
  };

  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<ProductItem>({
    endpoint: '/product_items/',
    mapper: mapItem,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Product-Items</h1>
      </div>
      <TableTools
        renderPagination={renderPagination}
        forPagination={forPagination}
        isLoading={isLoading}
        searchBy={searchBy}
        reFetch={reFetch}
        hasData={data.length > 0}
        createLabel="Create New Product-Item Mapping"
        createLink="/product-items/create-product-items"
        changelogLink="/product-items/changelog"
        onClickDownload={getProductItemsExport}
      >
        <Table header={ProductItemsHeader} data={data} internalPagination={false} />
      </TableTools>
    </div>
  );
};
