import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface UpdateUserStatusProps {
  isActive: string;
  email: string;
  handleUserUpdated: () => void;
}

export const UpdateUserStatus = ({ isActive, email, handleUserUpdated }: UpdateUserStatusProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleToggleUserStatus = () => {
    axios
      .put(`bark_user`, {
        email,
        is_active: !isActive,
      })
      .then(() => {
        const action = isActive ? 'deactivated' : 'reactivated';
        toast.info(`${email} is now ${action} as a BARK user.`, BOTTOM_TOAST);
        setShowModal(false);
        handleUserUpdated();
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="inline">{isActive ? 'Active' : 'Deactivated'}</div>
      <Pencil className="w-5 h-5 cursor-pointer" onClick={() => setShowModal(true)} />
      {showModal && (
        <ConfirmationModal
          title={`${isActive ? 'Deactivate' : 'Reactivate'} BARK user ${email}?`}
          confirmLabel={isActive ? 'Deactivate' : 'Reactivate'}
          onConfirm={handleToggleUserStatus}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
