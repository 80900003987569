import React, { useState, useRef, useEffect } from 'react';

import { Badge } from './Badge';
import { DownArrow } from '../assets/DownArrow';
import { useOutsideHandler } from '../hooks/useOutsideHandler';
import { disabledBackgroundGray } from '../pages/constants';
import { Option } from '../pages/types';

interface DropdownProps {
  title?: string;
  options: Option[];
  onSelect: (option: any) => void;
  selectedOption?: Option;
  variant?: 'default' | 'badge';
  badge?: {
    text?: string;
  };
  isDisabled?: boolean;
  bottomText?: string;
  defaultText?: string;
  noDefaultSelection?: boolean;
}

export const Dropdown = ({
  title,
  options,
  onSelect,
  selectedOption: initialSelectedOption,
  variant = 'default',
  badge = {},
  isDisabled = false,
  bottomText,
  defaultText = 'Select an option',
  noDefaultSelection = false, // If true, no default selection will be made only if initialSelectedOption is undefined
}: DropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<Option | undefined>(
    initialSelectedOption ?? (noDefaultSelection ? undefined : options[0])
  );

  const wrapperRef = useRef(null);
  useOutsideHandler(wrapperRef, () => {
    setIsOpen(false);
  });

  useEffect(() => {
    if (isOpen) {
      setIsOpen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDisabled]);

  return (
    <div ref={wrapperRef} className="relative inline-block text-left w-full">
      {isDisabled && (
        <div
          className={`absolute w-full top-[0] left-[0] ${disabledBackgroundGray} h-full rounded`}
        />
      )}
      <div>
        {title && <div className="pb-1">{title}</div>}
        <button
          onClick={() => setIsOpen(!isOpen)}
          type="button"
          className="inline-flex w-full px-4 py-2 text-sm font-medium bg-secondary border border-black rounded"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
        >
          <div className="flex justify-between w-full">
            {selectedOption?.label ?? <div className="text-gray">{defaultText}</div>}
            <DownArrow className="w-5 h-5" />
          </div>
        </button>
        {!isOpen && bottomText && (
          <p className="px-1 mt-1 text-xs text-gray font-normal">{bottomText}</p>
        )}
      </div>
      {isOpen && options.length > 0 && (
        <div className="absolute rounded-md border border-black bg-secondary w-full max-h-48 overflow-y-auto z-50">
          {options.map((option) => (
            <button
              key={option.value}
              onClick={() => {
                setSelectedOption(option);
                onSelect(option);
                setIsOpen(false);
              }}
              className="px-4 py-2 w-full text-left rounded-md hover:bg-primary"
              role="menuitem"
            >
              {variant === 'default' ? (
                <div className="text-sm">{option.label}</div>
              ) : (
                <div className="text-sm flex">
                  {option.label}
                  {option.hasBadge && (
                    <span className="ml-[8px]">
                      <Badge label={badge.text || ''} />
                    </span>
                  )}
                </div>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
