import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface ChangeDiscountStatusProps {
  isActive: boolean;
  discountId: string;
  discountCode: string;
  reFetch: () => void;
}

export const ChangeDiscountStatus = ({
  isActive,
  discountId,
  discountCode,
  reFetch,
}: ChangeDiscountStatusProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleChangeDiscountStatus = () => {
    axios
      .put(`discounts/${discountId}`, {
        code: discountCode,
        is_active: !isActive,
      })
      .then(() => {
        if (isActive) {
          toast.info(`${discountCode} is now a deactivated discount code.`, BOTTOM_TOAST);
        } else {
          toast.info(`${discountCode} is now an activated discount code.`, BOTTOM_TOAST);
        }

        setShowModal(false);
        reFetch();
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="inline">
        {isActive ? (
          <span className="bg-green-300  px-2 py-0.5 rounded">Active</span>
        ) : (
          <span className="bg-red-400  px-2 py-0.5 rounded">Inactive</span>
        )}
      </div>
      <Pencil className="w-5 h-5 cursor-pointer" onClick={() => setShowModal(true)} />
      {showModal && (
        <ConfirmationModal
          title={
            !isActive
              ? `Activate discount code: ${discountCode}?`
              : `Deactivate discount code: ${discountCode}?`
          }
          message={
            !isActive
              ? 'Customers will be able to use this discount code.'
              : 'Customers will not be able to use this discount code.'
          }
          confirmLabel={!isActive ? 'Activate' : 'Deactivate'}
          onConfirm={() => handleChangeDiscountStatus()}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
