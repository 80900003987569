import React, { useState } from 'react';

import { CustomerLookupForm, CustomerSearchParams } from './CustomerLookupForm';
import { CustomerLookupResults } from './CustomerLookupResults';

export const CustomerLookup = () => {
  const [showResults, setShowResults] = useState(false);
  const [searchParams, setSearchParams] = useState<CustomerSearchParams>({
    email: '',
    order_id: '',
    phone_number: '',
    first_name: '',
    last_name: '',
    address_1: '',
    address_2: '',
    city: '',
    state: '',
    zip_code: '',
  });

  const handleSearch = (params: CustomerSearchParams) => {
    setSearchParams(params);
    setShowResults(true);
  };

  return (
    <div className="flex h-[calc(100vh-77px)] overflow-auto items-center justify-center bg-slate50 px-4">
      <div className="min-w-[300px] w-full flex items-center justify-center">
        {!showResults && <CustomerLookupForm handleSearch={handleSearch} />}
        {showResults && (
          <CustomerLookupResults
            searchParams={searchParams}
            onClick={() => setShowResults(false)}
          />
        )}
      </div>
    </div>
  );
};
