import * as React from 'react';

import { Spinner } from '../assets/Spinner';
import { CustomerDetail } from '../component/CustomerDetail';
import { CustomerOrders } from '../component/CustomerOrders';
import { CustomerPlans } from '../component/CustomerPlans';
import { NotFound } from '../component/NotFound';
import { useGetCustomer } from '../hooks/getCustomer';
import { useUserTreatments } from '../hooks/getUserTreatments';
import { useCustomParams } from '../hooks/useCustomParams';

export const CustomerProfile = () => {
  const { customerId } = useCustomParams();
  const {
    customer,
    isLoading: isLoadingCustomer,
    isError: customerIsError,
    refreshCustomer,
  } = useGetCustomer(customerId);
  const {
    treatments,
    isLoading: isLoadingTreatments,
    isError: treatmentsIsError,
  } = useUserTreatments(customerId);

  if (isLoadingCustomer || isLoadingTreatments) return <Spinner />;

  if (customerIsError || treatmentsIsError) return <NotFound />;

  return (
    <div className="h-[calc(100vh-77px)] bg-slate50 p-8">
      <CustomerDetail customer={customer} treatments={treatments} />
      <CustomerPlans customer={customer} />
      <CustomerOrders customer={customer} refreshCustomer={refreshCustomer} />
    </div>
  );
};
