import React from 'react';

import { Pencil } from '../assets/Pencil';

interface EditButtonProps {
  onClickHandler: React.MouseEventHandler<HTMLAnchorElement>;
}

export const EditButton = ({ onClickHandler }: EditButtonProps) => {
  return (
    <a onClick={onClickHandler} className="cursor-pointer underline text-anchor ml-1 self-center">
      <Pencil className="w-4 h-4 text-black" />
    </a>
  );
};
