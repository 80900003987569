import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { Button } from './Button';
import { Pagination, PaginationProps } from './Pagination';
import { TextInput } from './TextInput';
import { Download } from '../assets/Download';

type TableToolsProps = {
  children: React.ReactNode;
  forPagination: PaginationProps;
  renderPagination: boolean;
  isLoading?: boolean;
  searchBy?: (t: string, field: string) => void;
  reFetch: () => void;
  hasData: boolean;
  createLabel: string;
  createLink: string;
  changelogLink?: string;
  onClickDownload?: () => void;
};

export const TableTools = ({
  renderPagination,
  forPagination,
  searchBy,
  reFetch,
  isLoading = false,
  children,
  hasData,
  createLabel,
  createLink,
  changelogLink,
  onClickDownload,
}: TableToolsProps) => {
  const [searchText, setSearchText] = useState('');

  if (isLoading) return null;
  return (
    <>
      <div className="flex pb-[10px] justify-between">
        {searchBy && (
          <div className="flex items-center mr-[10px]">
            <TextInput className="w-[150px] mr-[10px]" label="" setTextChange={setSearchText} />
            <div className="flex items-center justify-center">
              <Button label="Search" onClick={() => searchBy(searchText, 'search')} />
            </div>
          </div>
        )}
        <div className="flex flex-row space-x-4">
          {changelogLink && (
            <div className="ml-2 flex flex-row items-center">
              <Link className="cursor-pointer underline text-anchor" to={changelogLink}>
                View Changelog
              </Link>
            </div>
          )}
          {onClickDownload && (
            <div
              className="flex flex-row items-center space-x-1 cursor-pointer"
              onClick={onClickDownload}
            >
              <Download className="w-4 h-4 " />
              <a href="#" className="font-bold underline">
                Download
              </a>
            </div>
          )}
          <Button
            label={createLabel}
            onClick={() => {
              location.href = createLink;
            }}
          />
        </div>
      </div>
      {hasData ? (
        <>
          {children}
          {renderPagination && <Pagination {...forPagination} />}
        </>
      ) : (
        <>
          <div className="text-center items-center justify-center mt-8">
            <span> No results </span>
            <br />
            <Link to="#" onClick={reFetch} className="font-bold underline">
              Go Back
            </Link>
          </div>
        </>
      )}
    </>
  );
};
