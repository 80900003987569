import * as React from 'react';
import { Link, Navigate, Outlet, useLocation, useMatches } from 'react-router-dom';

import { Nav } from './Nav';
import { useAuth } from '../context/AuthContext';
import { customerDataFromParamsUtils } from '../customerDataFromParamsUtils';
import { dynamicBreadCrumbsNames } from '../pages/constants';

export const ProtectedRoute = () => {
  const { isAuthenticated } = useAuth();

  const matches = useMatches();
  const location = useLocation();
  const crumbs = matches.filter((match: any) => Boolean(match.handle?.title));

  const list = crumbs.map((crumb: any, index: number) => {
    let title = crumb.handle.title;

    if (dynamicBreadCrumbsNames.includes(title) && crumb.params) {
      if (title === 'customerId') {
        title = customerDataFromParamsUtils(crumb?.params[title])[1];
      } else {
        title = crumb.params[title];
      }
    }

    return (
      <li key={index}>
        <Link
          to={crumb.pathname}
          className={`${crumb.pathname === location.pathname && 'font-bold underline'}`}
        >
          {title}
        </Link>
        {crumbs.length - index > 1 && '/'}
      </li>
    );
  });

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }
  return (
    <div>
      <Nav breadcrumbs={list} />
      <Outlet />
    </div>
  );
};
