import { toast } from 'react-toastify';

import { BOTTOM_TOAST } from './toastUtils';

export const checkDailyLoginExpiration = () => {
  const tokenEntryDate = localStorage.getItem('tokenEntryDate');
  const todayDate = new Date().toDateString();
  if (todayDate !== tokenEntryDate) {
    toast.error('Daily login expired', BOTTOM_TOAST);
    localStorage.removeItem('token');
    localStorage.removeItem('account');
    localStorage.removeItem('email');
    localStorage.removeItem('tokenEntryDate');
    return true;
  }
  return false;
};
