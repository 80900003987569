import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { PromotionStatus } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface ChangePromotionStatusProps {
  isActive: boolean;
  id: string;
  name: string;
  reFetch: () => void;
}

export const ChangePromotionStatus = ({
  isActive,
  id,
  name,
  reFetch,
}: ChangePromotionStatusProps) => {
  const [showModal, setShowModal] = useState(false);

  const handleChangePromotionStatus = () => {
    axios
      .patch(`promotions/${id}`, {
        status: isActive ? PromotionStatus.INACTIVE : PromotionStatus.ACTIVE,
      })
      .then(() => {
        if (isActive) {
          toast.info(`${name} promotion is now deactivated.`, BOTTOM_TOAST);
        } else {
          toast.info(`${name} promotion is now active.`, BOTTOM_TOAST);
        }

        setShowModal(false);
        reFetch();
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex justify-between items-center">
      <div className="inline">
        {isActive ? (
          <span className="bg-green-300  px-2 py-0.5 rounded">Active</span>
        ) : (
          <span className="bg-red-400  px-2 py-0.5 rounded">Inactive</span>
        )}
      </div>
      <Pencil className="w-5 h-5 cursor-pointer" onClick={() => setShowModal(true)} />
      {showModal && (
        <ConfirmationModal
          title={!isActive ? `Activate promotion: ${name}?` : `Deactivate promotion: ${name}?`}
          message={
            !isActive
              ? 'Customers will be able to use this promotion.'
              : 'Customers will not be able to use this promotion.'
          }
          confirmLabel={!isActive ? 'Activate' : 'Deactivate'}
          onConfirm={() => handleChangePromotionStatus()}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
