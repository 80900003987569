import { ToastOptions } from 'react-toastify';

export const PET_PLAN_HOLD_STATUS_ERROR = 'Pet Plan has order on HOLD';
export const MULTIPLE_MEAL_ONE_TIME_PRODUCTS =
  'A one-time order can only have a maximum of one meal plan product. Please create a separate one-time order for each additional meal plan product.';

export const generateErrorMsg = (error: any) => {
  if (error?.response?.data) {
    return `Error: ${JSON.stringify(error.response.data)}`;
  }
  return 'Unable to perform the requested operation';
};

export const BOTTOM_TOAST: ToastOptions = {
  hideProgressBar: true,
  position: 'bottom-center',
};
