export const formatPhoneNumber = (phoneNumber: string | null | undefined): string => {
  if (!phoneNumber) {
    return '--';
  }

  // Remove all non-digit characters from the input phone number
  const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');

  // Check if the cleaned phone number has a valid length (10 digits)
  if (cleanedPhoneNumber.length !== 10) {
    // Return the original phone number if it's not valid
    return phoneNumber;
  }

  // Format the phone number as (XXX) XXX-XXXX
  const formattedPhoneNumber = `(${cleanedPhoneNumber.slice(0, 3)}) ${cleanedPhoneNumber.slice(
    3,
    6
  )}-${cleanedPhoneNumber.slice(6)}`;

  return formattedPhoneNumber;
};
