import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from './Button';
import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { mapSntApiDate } from '../dateUtils';
import { Customer } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface OrderDetailProps {
  customer: Customer;
  order: any;
  refreshCustomer: () => void;
}

export const OrderDetail = ({ customer, order, refreshCustomer }: OrderDetailProps) => {
  const [showCancelConfirmModal, setShowCancelConfirmModal] = useState(false);
  const [showRefundConfirmModal, setShowRefundConfirmModal] = useState(false);

  if (!order) {
    return <></>;
  }

  const orderDate = order.scheduled ? order.scheduled : order.charged;

  let fullAddress = '--';
  if (order.fulfillments.length > 0) {
    // TODO: Come up with a common Address Formatter
    const { address1, address2, city, state, zip, country } = order.fulfillments[0];
    fullAddress = [address1, address2, city, state, zip, country].filter(Boolean).join(', ');
  }

  let petNamesStr = '';
  const petNames: string[] = [];
  if (order.products.length > 0) {
    order.products.forEach((item: any) => {
      // TODO: pet_plan is null when we add one-time product to order, need to fix it.
      const petName = item.pet_plan?.pet_name;
      if (petName && !petNames.includes(petName)) {
        petNames.push(petName);
      }
    });

    // Create a deduplicated string of pet names
    petNamesStr = petNames.join(', ');
  }

  const changeOrderStatusToCancelled = () => {
    axios
      .put(`orders/mark_order_cancelled`, {
        order_id: order.id,
      })
      .then(() => {
        order.status = 'CANCELLED';
        setShowCancelConfirmModal(false);
        refreshCustomer();
        toast.success('Order status was marked as cancelled.', BOTTOM_TOAST);
        setShowRefundConfirmModal(true);
      })
      .catch((e) => {
        setShowCancelConfirmModal(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="pb-3">
      <h2 className="mb-1 font-bold">Order Details</h2>
      <div className="bg-white border rounded-lg p-4 shadow-md w-[30em]">
        <div className="mb-2 flex">
          <strong className="mr-1">Order Status: </strong> {order.status}
          {order.status === 'FULFILLING' && (
            <Button
              className="px-[2px] py-[2px] ml-2"
              variant="secondary"
              textClassName="text-sm font-normal"
              label="Mark as cancelled"
              onClick={() => setShowCancelConfirmModal(true)}
            />
          )}
        </div>
        <div className="mb-2">
          <strong>Order Date:</strong> {mapSntApiDate(orderDate)}
        </div>
        <div className="mb-2">
          <strong>Order Type:</strong> {order.order_type}
        </div>
        <div className="mb-2">
          <strong>Order Total:</strong> ${order.total_price}
        </div>
        <div className="mb-2">
          <strong>Discount Code:</strong>{' '}
          {order.discounts.length > 0 ? order.discounts[0]?.discount?.code : '--'}
        </div>
        <div className="mb-2">
          <strong>Pets:</strong> {petNames.length > 0 ? petNamesStr : '--'}
        </div>
        <div className="mb-2">
          <strong>Primary Shipping Address:</strong> {fullAddress}
        </div>
        <div className="mb-2">
          <strong>Pricing Model:</strong> {order.pricing_model?.name}
        </div>
      </div>
      {showCancelConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed?"
          message={`By clicking "Confirm" below,
            you will change order status to cancelled`}
          confirmLabel="Confirm"
          onConfirm={changeOrderStatusToCancelled}
          onCancel={() => setShowCancelConfirmModal(false)}
        />
      )}
      {showRefundConfirmModal && (
        <ConfirmationModal
          title="Do you want to refund the order?"
          message={`By clicking "Confirm" below,
            you will be taken to the refunds page`}
          confirmLabel="Confirm"
          onConfirm={() => {
            setShowRefundConfirmModal(false);
            window.location.href = `${window.location.href}/refund`;
          }}
          onCancel={() => setShowRefundConfirmModal(false)}
        />
      )}
    </div>
  );
};
