import * as React from 'react';

export const Unauthorized = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Unauthorized</h1>
      <p className="text-gray-600 text-lg mb-8">
        You do not have sufficient permissions to view this page
      </p>
    </div>
  );
};
