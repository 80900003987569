import * as React from 'react';

import { Spinner } from '../assets/Spinner';
import { NotFound } from '../component/NotFound';
import { OrderDetail } from '../component/OrderDetail';
import { OrderFulfillments } from '../component/OrderFulfillments';
import { PaymentDetail } from '../component/PaymentDetail';
import { Products } from '../component/Products';
import { useGetCustomer } from '../hooks/getCustomer';
import { useCustomParams } from '../hooks/useCustomParams';
import { generateFullName } from '../nameUtils';
import { getOrder } from '../orderUtils';

export const OrderDetailFull = () => {
  const { customerId, orderId } = useCustomParams();
  const { customer, isLoading, isError, refreshCustomer } = useGetCustomer(customerId);

  if (isLoading || !orderId) return <Spinner />;

  if (isError) return <NotFound />;

  let order = null;
  if (!isLoading) {
    order = getOrder(customer.orders, orderId);
  }

  if (!order) {
    return <NotFound />;
  }

  const isPreviewOrder = order.status === 'PREVIEW';

  return (
    <div className="h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="w-full pr-4">
        <h1>
          <b>Customer:</b>{' '}
          {`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
          <br />
          <b>Order:</b> {order.id}
        </h1>
      </div>
      <div className="flex pt-3">
        <div className="w-1/2 pr-4">
          <OrderDetail customer={customer} order={order} refreshCustomer={refreshCustomer} />
        </div>
        <div className="w-1/2 pr-4">
          <PaymentDetail order={order} />
        </div>
      </div>
      <div className="w-full">
        {isPreviewOrder && (
          <Products
            customer={customer}
            refreshCustomer={refreshCustomer}
            products={order.products}
            order={order}
          />
        )}
        {!isPreviewOrder && (
          <OrderFulfillments customer={customer} order={order} refreshCustomer={refreshCustomer} />
        )}
      </div>
    </div>
  );
};
