import React from 'react';
import { Tooltip } from 'react-tooltip';

interface LargeTooltipProps {
  textArray: string[];
  width?: string;
  height?: string;
}

interface InfoTooltipProps {
  text: string | JSX.Element;
  width?: string;
  height?: string;
}

interface SurveyResponseToolTipProps {
  date: string;
  primaryReason: string;
  secondaryReason: string;
  comments: string;
}

export const LargeTooltip = ({ textArray, width = '24', height = '24' }: LargeTooltipProps) => {
  const randomNumber = Math.floor(Math.random() * 1000001);
  const anchorClass = `bark-cancellation-anchor-${randomNumber}`;
  return (
    <>
      <a className={anchorClass}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="16" x2="12" y2="12" />
          <line x1="12" y1="8" x2="12.01" y2="8" />
        </svg>
      </a>
      <Tooltip anchorSelect={`.${anchorClass}`} place="top" className="z-[9999]">
        <ul className="list-disc p-2 max-w-[750px] text-sm">
          {textArray.map((item: string, index: number) => (
            <li key={index}>{item}</li>
          ))}
        </ul>
      </Tooltip>
    </>
  );
};

export const InfoTooltip = ({ text, width = '24', height = '24' }: InfoTooltipProps) => {
  const randomNumber = Math.floor(Math.random() * 1000001);
  const anchorClass = `bark-cancellation-anchor-${randomNumber}`;
  return (
    <>
      <a className={anchorClass}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={width}
          height={height}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="16" x2="12" y2="12" />
          <line x1="12" y1="8" x2="12.01" y2="8" />
        </svg>
      </a>
      <Tooltip anchorSelect={`.${anchorClass}`} place="top">
        {text}
      </Tooltip>
    </>
  );
};

export const SurveyResponseToolTip = ({
  date,
  primaryReason,
  secondaryReason,
  comments,
}: SurveyResponseToolTipProps) => {
  const randomNumber = Math.floor(Math.random() * 1000001);
  const anchorClass = `bark-cancellation-anchor-${randomNumber}`;
  return (
    <>
      <a className={anchorClass}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        >
          <circle cx="12" cy="12" r="10" />
          <line x1="12" y1="16" x2="12" y2="12" />
          <line x1="12" y1="8" x2="12.01" y2="8" />
        </svg>
      </a>
      <Tooltip anchorSelect={`.${anchorClass}`} place="top">
        <div>Cancelled on {date}</div>
        <div>Primary: {primaryReason}</div>
        <div>Secondary: {secondaryReason}</div>
        <div>Comments: {comments}</div>
      </Tooltip>
    </>
  );
};
