import * as React from 'react';

interface CheckboxProps {
  label: string;
  isChecked: boolean;
  onChange: (isChecked: boolean) => void;
  disabled?: boolean;
}

export const Checkbox = ({ label, isChecked, onChange, disabled }: CheckboxProps) => {
  return (
    <label className="flex items-center space-x-2 cursor-pointer">
      <input
        type="checkbox"
        className="form-checkbox h-5 w-5 bg-primary"
        checked={isChecked}
        onChange={() => onChange(!isChecked)}
        disabled={disabled}
      />
      <span className="text-gray-700">{label}</span>
    </label>
  );
};
