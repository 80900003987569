import { Option } from './pages/types';

// TODO: type arguments for formatingOptions by generating type Option
type StructureKeys = keyof Option;
type Structure = {
  [key in StructureKeys]?: string;
};
type Operator = 'is' | 'isnot' | 'enters' | string;
type Filter = {
  value: string;
  operator: string;
  property: string;
};

const operate = (valueFromElement: any, operator: Operator, value: string) => {
  if (operator === 'is') {
    return valueFromElement === value;
  } else if (operator === 'isnot') {
    return valueFromElement !== value;
  } else if (operator === 'enters') {
    return JSON.parse(value).includes(valueFromElement);
  } else {
    return true;
  }
};

const filterBy = (element: any, filter: Filter) => {
  const { property, operator, value } = filter;
  if (!property || !operator || !value) {
    console.error('The filter condition is formatting incorrectly, Filtering is skipped');
    return true;
  }
  return operate(element[property], operator, value);
};

export const formatingOptions = (data: any, structure: any, filter: Filter | boolean = false) => {
  const result: Option[] = [];

  for (const element of data) {
    const item: any = {};
    if (filter ? filterBy(element, filter as Filter) : !filter) {
      for (const property in structure) {
        const generatedValue = [];
        for (const part of structure[property as keyof Structure].split(' ')) {
          if (part.includes(',')) {
            const valueIsExisted = element[part.slice(0, part.length - 1)];
            if (valueIsExisted) generatedValue.push(element[part.slice(0, part.length - 1)] + ',');
          } else {
            generatedValue.push(element[part]);
          }
        }
        // check if value s boolean
        if (
          generatedValue.length === 1 &&
          ['true', 'false'].includes(generatedValue[0].toString())
        ) {
          item[property] = generatedValue[0];
        } else {
          item[property] = generatedValue.join(' ');
        }
      }
      result.push(item);
    }
  }

  return result;
};
