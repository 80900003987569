import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { Button } from '../component/Button';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { Dropdown } from '../component/Dropdown';
import { TextInput } from '../component/TextInput';
import { Option } from '../pages/types';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const CreateItem = () => {
  const [sku, setSku] = useState('');
  const [name, setName] = useState('');
  const [weight_oz, setWeightOz] = useState(0);
  const [volume, setVolume] = useState(0);
  const [recipeId, setRecipeId] = useState<Option>();
  const [recipeOptions, setRecipeOptions] = useState<Option[]>([]);

  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [showConfirmCreateItemModal, setShowConfirmCreateItemModal] = useState(false);
  const navigate = useNavigate();

  const requiredFieldsAreValid =
    sku.length > 0 && name.length > 0 && weight_oz >= 0 && volume >= 0 && recipeId;

  useEffect(() => {
    axios
      .get(`recipes/ids`)
      .then((response) => {
        setRecipeOptions(response.data.map((value: string) => ({ value, label: value })));
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleCreateItem = () => {
    const requestBody = {
      sku,
      name,
      weight_oz,
      volume,
      recipe: recipeId?.value,
    };

    axios
      .post(`items`, requestBody)
      .then(() => {
        navigate('/items');
        toast.success(`Successfully created item ${name}`, BOTTOM_TOAST);
      })
      .catch((e) => {
        setValidationErrors(e.response.data);
        setShowConfirmCreateItemModal(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  const handleSkuChange = (value: string) => {
    setSku(value.toUpperCase());
  };

  return (
    <div className="flex justify-center items-center justify-self-start bg-slate50 p-8">
      <div>
        <h1 className="text-left">Create new item</h1>
        <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] w-3/5 relative border padd">
          <div className="text-md text-left">
            <div className="w-full mb-2">
              <h2>Item SKU</h2>
              <TextInput
                placeholder="Enter item SKU (e.g. U-B-001)"
                value={sku}
                setTextChange={(value) => handleSkuChange(value)}
                error={validationErrors?.sku}
              />
            </div>

            <div className="w-full mb-2">
              <h2>Name</h2>
              <TextInput
                placeholder="Enter item name (e.g. Unkibble Beef & Barley Fill Size 001)"
                value={name}
                setTextChange={setName}
                error={validationErrors?.name}
              />
            </div>

            <div className="w-full mb-2">
              <h2>Weight</h2>
              <div className="flex">
                <div className="w-2/5 mb-2 items-center">
                  <TextInput
                    placeholder="Enter weight"
                    value={weight_oz.toString()}
                    setTextChange={(value) => setWeightOz(Number(value))}
                    type="number"
                    error={validationErrors?.weight_oz}
                  />
                </div>
                <div className="w-3/4 mb-2 flex items-center">
                  <span className="ml-2"> ounces (oz)</span>
                </div>
              </div>
            </div>

            <div className="w-full mb-2">
              <h2>Volume</h2>
              <div className="flex">
                <div className="w-2/5 mb-2 items-center">
                  <TextInput
                    placeholder="Enter volume"
                    value={volume.toString()}
                    setTextChange={(value) => setVolume(Number(value))}
                    type="number"
                    error={validationErrors?.volume}
                  />
                </div>
                <div className="w-3/4 mb-2 flex items-center">
                  <span className="ml-2"> Cubic Inches </span>
                </div>
              </div>
            </div>

            <div className="w-full mb-2">
              <h2>Recipe ID</h2>
              <Dropdown
                options={recipeOptions}
                selectedOption={recipeId}
                onSelect={(newSelection) => setRecipeId(newSelection)}
                bottomText='Select the recipe ID for this new item. For example, the recipe id for U-B-001 is "U-B"'
                noDefaultSelection
              />
            </div>
          </div>
        </div>

        <div className="flex justify-center flex-col items-center mt-2">
          <Button
            variant={requiredFieldsAreValid ? 'primary' : 'secondary'}
            label="Create Item"
            isDisabled={!requiredFieldsAreValid}
            onClick={() => setShowConfirmCreateItemModal(true)}
          />
        </div>

        {showConfirmCreateItemModal && (
          <ConfirmationModal
            title="Are you sure you want to create this new item?"
            message="Please ensure all fields are correct before proceeding, as they are not editable after the item is created."
            confirmLabel="Create Item"
            onConfirm={() => handleCreateItem()}
            onCancel={() => setShowConfirmCreateItemModal(false)}
          />
        )}
      </div>
    </div>
  );
};
