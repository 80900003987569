import React from 'react';
import Select, { Props as SelectProps } from 'react-select';

export const SearchableDropdown = (props: SelectProps) => {
  return (
    <Select
      {...props}
      className="p-0 text-sm bg-secondary border border-black rounded hover:bg-primary"
      isClearable={false}
    />
  );
};
