import * as React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Customer } from './types';
import { axios } from '../api';
import { Spinner } from '../assets/Spinner';
import { Button } from '../component/Button';
import { CheckboxTable } from '../component/CheckboxTable';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { NotFound } from '../component/NotFound';
import { TextInput } from '../component/TextInput';
import {
  calculatePriceAfterDiscount,
  calculateOrderProductRefundAmount,
  calculateOrderProductRefundTaxAmount,
  calculateAmountRefundedWithoutTax,
  calculateUnitPriceAfterDiscount,
} from '../financialUtils';
import { useCustomParams } from '../hooks/useCustomParams';
import { generateFullName } from '../nameUtils';
import { getOrder } from '../orderUtils';
import { CustomerRefundsHeader } from '../tableHeaders';
import { BOTTOM_TOAST } from '../toastUtils';

export const CustomerRefund = () => {
  const { customerId, orderId } = useCustomParams();
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [refundAmounts, setRefundAmounts] = useState<string[]>([]);
  const [isProcessLoading, setIsProcessLoading] = useState(false);

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCheckboxChange = (index: number) => {
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelected) => prevSelected.filter((rowIndex) => rowIndex !== index));
    } else {
      setSelectedRows((prevSelected) => [...prevSelected, index]);
    }
  };
  const getCustomer = () => {
    axios
      .get(`accounts/${customerId}`)
      .then((response) => {
        setCustomer(response.data);
        const order = getOrder(response.data.orders, orderId);
        const unit_prices = order.products.map(() => '0.00');
        setRefundAmounts(unit_prices);
      })
      .catch(() => <NotFound />);
  };

  const isRefundDisabled = (index: number, availableForRefund: string) => {
    if (Number(availableForRefund) === 0) {
      return true;
    }
    return selectedRows.indexOf(index) === -1;
  };

  const mapOrderProducts = (order: any) => {
    return order.products.map((product: any, i: number) => {
      const unitPricePaidAfterDiscount = calculateUnitPriceAfterDiscount(product);
      const priceAfterDiscount = calculatePriceAfterDiscount(product);
      const alreadyRefundedAmount = calculateAmountRefundedWithoutTax(
        priceAfterDiscount,
        product.refund_amount ?? '0.00',
        product.tax_amount
      );
      const availableForRefund = (
        Number(priceAfterDiscount) - Number(alreadyRefundedAmount)
      ).toFixed(2);
      return {
        'Product Code': product.code,
        'Product Description': product.name,
        'Pet Name': product.pet_plan?.pet_name,
        'Price Paid (after discount)': `${product.quantity} x $${unitPricePaidAfterDiscount}`,
        'Already Refunded': `$${alreadyRefundedAmount}`,
        'Available for Refund': `$${availableForRefund}`,
        'Amount to Refund': (
          <TextInput
            placeholder="$0.00"
            disabled={isRefundDisabled(i, availableForRefund)}
            setTextChange={(value) => {
              const newRefundAmounts = [...refundAmounts];
              newRefundAmounts[i] =
                Number(value) > Number(availableForRefund) ? availableForRefund : value;
              setRefundAmounts(newRefundAmounts);
            }}
          />
        ),
        'Total Refund Including Tax': `$${calculateOrderProductRefundAmount(
          priceAfterDiscount,
          refundAmounts[i],
          product
        )}`,
      };
    });
  };

  const order = useMemo(() => {
    return getOrder(customer?.orders, orderId);
  }, [customer?.orders, orderId]);

  const totalRefund = useMemo(() => {
    const totalRefundAmount = selectedRows.reduce((total, index) => {
      const orderProduct = order.products[index];
      const totalRefundAmount = calculateOrderProductRefundAmount(
        calculatePriceAfterDiscount(orderProduct),
        refundAmounts[index],
        orderProduct
      );
      return total + Number(totalRefundAmount);
    }, 0);

    return totalRefundAmount.toFixed(2);
  }, [order?.products, refundAmounts, selectedRows]);

  const handleProcessRefund = () => {
    setIsProcessLoading(true);
    const orderProductsPayload = selectedRows.map((index) => {
      const orderProduct = order.products[index];
      return {
        order_product_id: orderProduct.id,
        amount: refundAmounts[index],
        tax_amount: calculateOrderProductRefundTaxAmount(
          calculatePriceAfterDiscount(orderProduct),
          refundAmounts[index],
          orderProduct
        ),
      };
    });
    axios
      .post(`refunds`, {
        account: customerId,
        order_id: orderId,
        order_product_refunds: orderProductsPayload,
      })
      .then(() => {
        const urlParts = window.location.href.split('/');
        urlParts.pop(); // Remove the last segment (one level up)
        location.href = urlParts.join('/');
        setIsProcessLoading(false);
        setShowModal(false);
      })
      .catch(() => {
        toast.error('Error processing refund', BOTTOM_TOAST);
        setIsProcessLoading(false);
        setShowModal(false);
      });
  };

  if (!customer) return <Spinner />;

  return (
    <div className="h-[calc(100vh)] bg-slate50 p-8">
      <div className="pt-3 pb-3">
        <h2 className="mb-1 flex items-center">Refund Order</h2>
        <b>Customer:</b>{' '}
        {`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`} <br />
        <b>Order:</b> {order.id}
      </div>
      <div className="bg-white">
        <CheckboxTable
          header={CustomerRefundsHeader}
          data={mapOrderProducts(order)}
          selectedRows={selectedRows}
          onCheckboxChange={handleCheckboxChange}
        />
      </div>
      <div className="text-right pt-2">
        Total Refund Amount: <b>{totalRefund}</b>
      </div>
      <div className="flex items-center justify-center pt-5 pb-4">
        <Button
          label="Process Refund"
          onClick={() => {
            setShowModal(true);
          }}
          isDisabled={selectedRows.length === 0}
          variant={selectedRows.length > 0 ? 'primary' : 'secondary'}
        />
        <br />
      </div>
      {showModal && (
        <ConfirmationModal
          isProcessLoading={isProcessLoading}
          title={`Are you sure you want to proceed with this refund of ${totalRefund}?`}
          message={`The customer will receive an automated refund confirmation email at ${customer.email} in approximately 20 minutes.`}
          confirmLabel="Confirm"
          onConfirm={handleProcessRefund}
          onCancel={() => setShowModal(false)}
        />
      )}
    </div>
  );
};
