import { isSameDay, isAfter } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { isActiveOptionsList } from './constants';
import { Option, ProductCodes, PromotionBannerTypes } from './types';
import { axios } from '../api';
import { getFreeProducts } from '../api/getFreeProducts';
import { getProducts } from '../api/getProducts';
import { Pencil } from '../assets/Pencil';
import { Button } from '../component/Button';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { Dropdown } from '../component/Dropdown';
import { MultiSelectDropdown } from '../component/MultiSelectDropdown';
import { SearchableDropdown } from '../component/SearchableDropdown';
import { TextInput } from '../component/TextInput';
import { InfoTooltip } from '../component/Tooltip';
import { formatDateTime } from '../dateUtils';
import { formatingOptions } from '../formatingOptions';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const CreatePromotion = () => {
  const isActiveOptions = useMemo(() => isActiveOptionsList, []);
  const [promotionName, setPromotionName] = useState('');
  const [isActive, setIsActive] = useState(isActiveOptions[0]);
  const [bannerCopy, setBannerCopy] = useState('');
  const [showConfirmCreatePromotionModal, setShowConfirmCreatePromotionModal] = useState(false);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();
  const [endDateError, setEndDateError] = useState('');
  const [products, setProducts] = useState<ProductCodes[]>([]);
  const [freeProducts, setFreeProducts] = useState<ProductCodes[]>([]);
  const productOptions = formatingOptions(products, { value: 'id', label: 'code' });
  const freeProductsOptions = formatingOptions(freeProducts, { value: 'id', label: 'code' });
  const [selectedPromoProductOption, setSelectedPromoProductOption] = useState<Option | null>(null);
  const [quantity, setQuantity] = useState('1');
  const [minimumSpendAmount, setMinimumSpendAmount] = useState('0');
  const [selectedConditionalProductIds, setSelectedConditionalProductIds] = useState<string[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    getFreeProducts().then((response) => {
      setFreeProducts(response.data);
    });
    getProducts().then((response) => {
      setProducts(response.data);
    });
  }, []);

  const isDisabled = () => {
    return promotionName === '' || !selectedPromoProductOption || +quantity <= 0;
  };

  const getStatus = () => {
    if (isActive.value) {
      return 'ACTIVE';
    }
    return 'INACTIVE';
  };

  const getShouldShowBannerLabel = () => {
    if (!bannerCopy || bannerCopy === '') {
      return PromotionBannerTypes.NO_FUNNEL_UI;
    }
    return PromotionBannerTypes.ONLY_CHECKOUT_PAGE;
  };

  const handleCreatePromotion = () => {
    const selectedConditionalProductCodes = selectedConditionalProductIds.map((productId) => {
      const targetObject = products.find(
        (productObject: ProductCodes) => productObject.id === productId
      );

      if (!targetObject) {
        throw new Error('Error: The conditional target product select does not exist!');
      }

      return targetObject.code;
    });

    const requestBody = {
      name: promotionName,
      banner_copy: bannerCopy,
      status: getStatus(),
      promotion_type: 'ORDER',
      start_timestamp: startDate ?? undefined,
      end_timestamp: endDate ?? undefined,
      banner_type: getShouldShowBannerLabel(),
      free_product_code: selectedPromoProductOption?.label ?? 'n/a',
      quantity,
      required_conditions_products: selectedConditionalProductCodes,
      minimum_order_gross_value_threshold_amount: minimumSpendAmount,
    };

    axios
      .post('promotions', requestBody)
      .then(() => {
        navigate('/promotions');
        toast.success(`${promotionName} Promotion has been created`, BOTTOM_TOAST);
      })
      .catch((e) => {
        setShowConfirmCreatePromotionModal(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex justify-center items-center justify-self-start bg-slate50 p-8">
      <div className="bg-lightGray rounded-lg shadow-md p-4 w-[750px] relative border font-semibold">
        <h1 className="text-left">View Promotion</h1>
        <p className="mt-1 text-xs text-gray font-normal">
          This promotion will apply to trial orders only. When this promotion is active, the free
          product(s) will be shown to customers on the Funnel checkout page, and a banner will alert
          them to the presence of the active promotion.
        </p>
        <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
          <div className="text-md text-left">
            <div className="w-full mb-2">
              <h2>Promotion name</h2>
              <TextInput
                placeholder="None"
                value={promotionName}
                setTextChange={(value) => setPromotionName(value)}
              />
            </div>

            <div className="w-full mb-2">
              <h2 className="flex">
                Promotion banner{' '}
                <span className="px-2">
                  <InfoTooltip text="Customer-facing banner to be displayed throughout the Funnel" />
                </span>
              </h2>
              <TextInput
                placeholder="None"
                value={bannerCopy}
                setTextChange={(value) => setBannerCopy(value)}
              />
            </div>
          </div>

          <div className="w-full mb-2">
            <div className="flex flex-row justify-between">
              <div className="flex-1 mr-4">
                <h2>Status</h2>
                <Dropdown
                  options={isActiveOptions}
                  selectedOption={isActive}
                  onSelect={(newSelection) => setIsActive(newSelection)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>
            Active dates <span className="text-xs">(Optional)</span>
          </h2>
          <p className="text-xs text-gray-500 font-normal">
            The customer will only receive the discount if applied within this time frame
          </p>

          <div className="flex mt-2">
            <div className="w-1/2 pr-2">
              <h3>Start Date</h3>
              <div className="w-full mb-2">
                <DatePicker
                  selected={startDate}
                  minDate={new Date()}
                  onChange={(date: Date) => {
                    setStartDate(date);
                    if (startDate && !endDate) {
                      setEndDateError('End date is required');
                    } else if (date && endDate && date >= endDate) {
                      setEndDateError('End time must be later than start time');
                    } else {
                      setEndDateError('');
                    }
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <div className="flex items-center cursor-pointer">
                      <span>
                        {startDate ? formatDateTime(startDate.toString(), true) : 'Select Date'}
                      </span>
                      <Pencil className="w-4 h-4 ml-1 mb-1" />
                    </div>
                  }
                />
              </div>
            </div>
            <div className="w-1/2">
              <h3>End Date</h3>
              <div className="w-full mb-2">
                <DatePicker
                  key={startDate?.toString()}
                  selected={endDate || startDate}
                  minDate={startDate || new Date()}
                  filterTime={(time) => {
                    if (startDate && isSameDay(time, startDate)) {
                      return isAfter(time, startDate);
                    }
                    return true;
                  }}
                  onChange={(date: Date) => {
                    setEndDate(date);
                    if (date && startDate && date <= startDate) {
                      setEndDateError('End time must be later than start time');
                    } else {
                      setEndDateError('');
                    }
                  }}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  customInput={
                    <div className="flex items-center cursor-pointer">
                      <span>
                        {endDate ? formatDateTime(endDate.toString(), true) : 'Select Date'}
                      </span>
                      <Pencil className="w-4 h-4 ml-1 mb-1" />
                    </div>
                  }
                />
                {endDateError && (
                  <div className="error text-red-500 text-xs font-normal">{endDateError}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full mb-2">
            <h2>Free promotional product(s)</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-3/4">
                <SearchableDropdown
                  options={freeProductsOptions}
                  value={selectedPromoProductOption ?? null}
                  onChange={(newSelection: any) => {
                    if (newSelection) {
                      setSelectedPromoProductOption(newSelection);
                    }
                  }}
                />{' '}
              </div>
              <div className="w-1/4">
                <TextInput
                  placeholder="None"
                  value={quantity}
                  setTextChange={(value) => setQuantity(value)}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full mb-2">
            <h2>Conditional product(s) for promotion eligibility</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-full">
                <MultiSelectDropdown
                  options={productOptions}
                  placeholder="Select conditional products"
                  selectedOptions={selectedConditionalProductIds}
                  onSelect={setSelectedConditionalProductIds}
                />
              </div>
            </div>
          </div>
          <div className="w-full mb-2">
            <h2>Conditional spend amount for promotion eligibility</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-full">
                <TextInput
                  placeholder="None"
                  value={minimumSpendAmount}
                  setTextChange={(value) => setMinimumSpendAmount(value)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center flex-col items-center mt-2">
          <Button
            variant="primary"
            label="Create Promotion"
            onClick={() => setShowConfirmCreatePromotionModal(true)}
            isDisabled={isDisabled()}
          />
        </div>

        {showConfirmCreatePromotionModal && (
          <ConfirmationModal
            title="Are you sure you want to create this new promotion?"
            message="Please ensure all fields are correct before proceeding, as they are not editable after the promotion is created."
            confirmLabel="Create Promotion"
            onConfirm={() => handleCreatePromotion()}
            onCancel={() => setShowConfirmCreatePromotionModal(false)}
          />
        )}
      </div>
    </div>
  );
};
