import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

import { Treatment } from '@/pages/types';

export const useUserTreatments = (customerId: any) => {
  const [treatments, setTreatments] = useState<Treatment[] | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [triggerRequest, setTriggerRequest] = useState(0);

  const refreshTreatments = () => {
    setTriggerRequest((prev) => prev + 1);
  };

  const getUserTreatments = () => {
    axios
      .get(`accounts/${customerId}/user_treatments`)
      .then((response) => {
        setTreatments(response.data);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((e) => {
        setIsError(true);
        setIsLoading(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getUserTreatments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRequest]);

  return {
    treatments,
    refreshTreatments,
    isLoading,
    isError,
  } as {
    treatments: Treatment[];
    refreshTreatments: () => void;
    isLoading: boolean;
    isError: boolean;
  };
};
