import React from 'react';
import { Link } from 'react-router-dom';

const ManageData: React.FC = () => {
  return (
    <div className="p-10">
      <h1>Manage Products & Items</h1>
      <Link to="/items">
        <button className="flex flex-col items-start w-3/5 text-xl py-4 px-8 bg-slate-50 text-black border border-black mt-4">
          <span className="font-bold py-2">Items</span>
          <span className="text-sm py-2">View and add items</span>
        </button>
      </Link>
      <Link to="/product-items">
        <button className="flex flex-col items-start justify-center w-3/5 text-xl py-4 px-8 bg-slate-50 text-black border border-black mt-4">
          <span className="font-bold py-2">Product-Items</span>
          <span className="text-sm py-2">Add or update item(s) mapped to a product</span>
        </button>
      </Link>
    </div>
  );
};

export default ManageData;
