import * as React from 'react';
import { useNavigate } from 'react-router-dom';

import { Button } from './Button';
import { buildData } from '../api';
import { HamburgerMenu } from '../assets/HamburgerMenu';
import { useAuth } from '../context/AuthContext';

export const Nav = ({ breadcrumbs }: any) => {
  const navigate = useNavigate();
  const { isAuthenticated, logout } = useAuth();
  const [shouldShowContextMenu, setShouldShowContextMenu] = React.useState(false);
  const contextMenuRef = React.useRef<HTMLDivElement>(null);

  if (!isAuthenticated) return <></>;

  return (
    <div className="flex justify-between bg-secondary border-b border-black p-3 relative">
      <img
        onClick={() => {
          location.href = '/';
        }}
        className="w-28 cursor-pointer"
        src={require('../assets/fullBarkLogo.png')}
        alt="Logo"
      />
      <div className="flex items-center">
        {breadcrumbs.length > 1 && <ol className="flex mr-2">{breadcrumbs}</ol>}
        <a
          onClick={() => {
            setShouldShowContextMenu(!shouldShowContextMenu);
          }}
          className="cursor-pointer"
        >
          <HamburgerMenu className="w-10 h-10" />
        </a>
        {shouldShowContextMenu && (
          <div
            ref={contextMenuRef}
            className="absolute right-3 top-14 bg-white shadow-lg rounded-lg p-4 w-250 z-10 border border-gray-200"
          >
            <div className="text-sm text-gray-700 mb-2">
              <strong>Email:</strong> {localStorage.getItem('email')?.replace(/"/g, '')}
            </div>
            <div className="text-sm text-gray-700 mb-2">
              <strong>Build Date:</strong> {buildData.FE_BUILD_DATE.split('T')[0]}
            </div>
            <div className="text-sm text-gray-700 mb-2">
              <strong>Build Version:</strong> {buildData.FE_GH_COMMIT_HASH}
            </div>
            <hr className="my-2" />
            <Button
              variant="secondary"
              label="Logout"
              onClick={() => {
                localStorage.removeItem('token');
                logout();
                navigate('/login');
              }}
              className="block w-full text-left px-2 py-2 text-sm font-medium  rounded"
            />
          </div>
        )}
      </div>
    </div>
  );
};
