import { BarkUser, Group, UserGroup } from './types';

const getBarkUser = (): BarkUser => {
  const userStr = localStorage.getItem('account');
  if (!userStr || userStr === '') {
    throw new Error('User is not properly authenticated');
  }
  return JSON.parse(userStr);
};

const isUserInGroup = (user: BarkUser, targetGroupName: string) => {
  return user.groups
    .map((group: Group) => group.name)
    .find((groupName: string) => groupName === targetGroupName);
};

// Returns true if the user is an Active Admin, else returns false
export const isAdmin = (user?: BarkUser) => {
  user = user ?? getBarkUser();
  return user.is_active && isUserInGroup(user, UserGroup.Admin);
};

// Returns true if the user is an Active Team Member, else returns false
export const isTeamMember = (user?: BarkUser) => {
  user = user ?? getBarkUser();
  return user.is_active && isUserInGroup(user, UserGroup.TeamMember);
};

// Returns true if the user is an Active Ops Admin, else returns false
export const isOpsAdmin = (user?: BarkUser) => {
  user = user ?? getBarkUser();
  return user.is_active && isUserInGroup(user, UserGroup.OpsAdmin);
};

// Returns true if the user is an Active AllModuleAccess user, else returns false
export const isAllModuleAccessUser = (user?: BarkUser) => {
  user = user ?? getBarkUser();
  return user.is_active && isUserInGroup(user, UserGroup.AllModuleAccess);
};

// list of keys in bread crumb object to show correct name
export const dynamicBreadCrumbsNames = ['customerId', 'orderId'];

export const disabledBackgroundGray = 'bg-[rgba(0,0,0,0.15)]';

export const discountTypesInfo = [
  'Unlimited: No usage limits',
  'X Customers: Limits the number of customers that can use this discount; each customer can redeem the \
discount code on only ONE order',
  'X Orders Per Customer: Limits the number of times this discount can be used per \
     customer, but the number of customers that can use \
this discount is unlimited',
  'X Orders Per Customer (Trial/Subscription Split): Limits \
the number of times this discount can be used per customer while also allowing for a \
different discount amount value between the trial order and subsequent subscription \
order(s); the number of customers that can use this discount is unlimited',
  'X Orders \
Per Customer For Just One Customer: Limits the number of times this discount can be \
used by a customer; only ONE customer will be able to use this discount',
];

export const discountTypesList = [
  {
    label: 'Percentage Discount',
    value: 'Percentage Discount',
  },
  {
    label: 'Amount Discount',
    value: 'Amount Discount',
  },
];

export const isActiveOptionsList = [
  {
    label: 'Active',
    value: 'true',
    className: 'bg-green-500 text-white p-4 mb-4',
  },
  {
    label: 'Inactive',
    value: 'false',
    className: 'bg-gray-500 text-white p-4',
  },
];

export const limitTypesList = [
  {
    label: 'Unlimited',
    value: 'Unlimited',
    needsValue: false,
  },
  {
    label: 'X Customers',
    value: 'X customers',
    needsValue: true,
    valueText: 'Customer(s)',
    valueBottomText:
      'Set the maximum number of customers that will be able to redeem this discount. Each customer will be able to redeem the discount code on just ONE order.',
  },
  {
    label: 'X Orders Per Customer',
    value: 'X orders per customer',
    needsValue: true,
    valueText: 'Order(s)',
    valueBottomText:
      'Set the maximum number of orders that each customer will be able to use this discount. When using this limit type, please note that there is no limit to the number of customers that can use this discount.',
  },
  {
    label: 'X Orders Per Customer (Trial/Subscription Split)',
    value: 'X orders per customer for subscription trial split',
    needsValue: true,
    valueText: 'Subscription Order(s)',
    valueBottomText:
      'Set the maximum number of subscription orders that this discount can be used per customer. The maximum number of trial orders is automatically set to 1.  When using this limit type, please note that there is no limit to the number of customers that can use this discount.',
  },
  {
    label: 'X Orders for Just One Customer',
    value: 'X orders per customer for just one customer',
    needsValue: true,
    valueText: 'Order(s)',
    valueBottomText:
      'Set the maximum number of orders for which your intended recipient will be able to use this discount. This limit type is best for creating personal codes for influencers and giveaway winners that require 100% off for a limited number of orders.',
  },
];

export enum DiscountLimitType {
  UNLIMITED = 'Unlimited',
  X_CUSTOMERS = 'X customers',
  X_ORDERS_PER_CUSTOMER = 'X orders per customer',
  X_ORDERS_FOR_JUST_ONE_CUSTOMER = 'X orders per customer for just one customer',
  X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT = 'X orders per customer for subscription trial split',
}

export const stateAbbreviations = [
  { short: 'AL', long: 'Alabama' },
  { short: 'AZ', long: 'Arizona' },
  { short: 'AR', long: 'Arkansas' },
  { short: 'CA', long: 'California' },
  { short: 'CO', long: 'Colorado' },
  { short: 'CT', long: 'Connecticut' },
  { short: 'DC', long: 'District of Columbia' },
  { short: 'DE', long: 'Delaware' },
  { short: 'FL', long: 'Florida' },
  { short: 'GA', long: 'Georgia' },
  { short: 'ID', long: 'Idaho' },
  { short: 'IL', long: 'Illinois' },
  { short: 'IN', long: 'Indiana' },
  { short: 'IA', long: 'Iowa' },
  { short: 'KS', long: 'Kansas' },
  { short: 'KY', long: 'Kentucky' },
  { short: 'LA', long: 'Louisiana' },
  { short: 'ME', long: 'Maine' },
  { short: 'MD', long: 'Maryland' },
  { short: 'MA', long: 'Massachusetts' },
  { short: 'MI', long: 'Michigan' },
  { short: 'MN', long: 'Minnesota' },
  { short: 'MS', long: 'Mississippi' },
  { short: 'MO', long: 'Missouri' },
  { short: 'MT', long: 'Montana' },
  { short: 'NE', long: 'Nebraska' },
  { short: 'NV', long: 'Nevada' },
  { short: 'NH', long: 'New Hampshire' },
  { short: 'NJ', long: 'New Jersey' },
  { short: 'NM', long: 'New Mexico' },
  { short: 'NY', long: 'New York' },
  { short: 'NC', long: 'North Carolina' },
  { short: 'ND', long: 'North Dakota' },
  { short: 'OH', long: 'Ohio' },
  { short: 'OK', long: 'Oklahoma' },
  { short: 'OR', long: 'Oregon' },
  { short: 'PA', long: 'Pennsylvania' },
  { short: 'RI', long: 'Rhode Island' },
  { short: 'SC', long: 'South Carolina' },
  { short: 'SD', long: 'South Dakota' },
  { short: 'TN', long: 'Tennessee' },
  { short: 'TX', long: 'Texas' },
  { short: 'UT', long: 'Utah' },
  { short: 'VT', long: 'Vermont' },
  { short: 'VA', long: 'Virginia' },
  { short: 'WA', long: 'Washington' },
  { short: 'WV', long: 'West Virginia' },
  { short: 'WI', long: 'Wisconsin' },
  { short: 'WY', long: 'Wyoming' },
];
