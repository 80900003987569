import * as React from 'react';

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">404 - Page Not Found</h1>
      <p className="text-gray-600 text-lg mb-8">The page you are looking for doesn't exist 😢</p>
    </div>
  );
};
