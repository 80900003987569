import React from 'react';

import { Button } from './Button';
import { Close } from '../assets/Close';
import { InlineSpinner } from '../assets/Spinner';

export interface ConfirmationModalProps {
  title: string | React.ReactNode;
  message?: string | React.ReactNode;
  confirmLabel: string;
  onConfirm: () => void;
  onCancel: () => void;
  customMessage?: any;
  isProcessLoading?: boolean;
}

export const ConfirmationModal = ({
  title,
  message = '',
  confirmLabel,
  onConfirm,
  onCancel,
  customMessage = null,
  isProcessLoading = false,
}: ConfirmationModalProps) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg shadow-md p-4 w-96 relative border">
        <Close
          className="absolute top-1 right-1 w-8 h-8 cursor-pointer"
          onClick={isProcessLoading ? undefined : onCancel}
        />
        <h3 className="text-lg font-semibold mb-4 text-center mt-4">{title}</h3>
        {message && <p className="text-sm mb-4 text-center">{message}</p>}
        {customMessage && customMessage}
        <div className="flex justify-center">
          <Button
            variant={isProcessLoading ? 'secondary' : 'primary'}
            label={isProcessLoading ? <InlineSpinner /> : confirmLabel}
            isDisabled={isProcessLoading}
            onClick={onConfirm}
          />
        </div>
      </div>
    </div>
  );
};
