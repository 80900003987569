import axios from 'axios';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import { BarkUser } from './types';
import { API_URL } from '../api';
import { Button } from '../component/Button';
import { MFAModal } from '../component/MFAModal';
import { TextInput } from '../component/TextInput';
import { useAuth } from '../context/AuthContext';
import { BOTTOM_TOAST } from '../toastUtils';

type LoginResponse = {
  account: BarkUser;
  token: string;
  token_created: boolean;
  setup_required: boolean;
  config_url: string;
};

export const Login = () => {
  const [email, setEmailChange] = useState('');
  const [password, setPasswordChange] = useState('');
  const [mfaModal, setMfaModal] = useState(false);
  const [requiresTotpSetup, setRequiresTotpSetup] = useState(false);
  const [totpSecret, setTotpSecret] = useState('');
  const { login } = useAuth();

  const navigate = useNavigate();

  const handleLogin = () => {
    if (email.length !== 0 || password.length !== 0) {
      axios
        .post<LoginResponse>(
          `${API_URL()}/login`,
          {
            email,
            password,
          },
          {
            headers: {
              'x-application': 'BARK',
            },
          }
        )
        .then((response) => {
          if (response.data.setup_required) {
            setTotpSecret(response.data.config_url || '');
            setRequiresTotpSetup(true);
          }
          setMfaModal(true);
        })
        .catch((e) => {
          if (e.response.status === 401) {
            toast.error(
              'Invalid email and password combination. Remember to use +bark@ in the email address.',
              BOTTOM_TOAST
            );
          } else {
            toast.error('Something went wrong. Please try again later!', BOTTOM_TOAST);
          }
        });
    }
  };

  function handle2FA(token: string) {
    axios
      .post<LoginResponse>(
        `${API_URL()}/totp/`,
        {
          email,
          password,
          token,
        },
        {
          headers: {
            'x-application': 'BARK',
          },
        }
      )
      .then((response) => {
        setMfaModal(false);
        setRequiresTotpSetup(false);
        setTotpSecret('');
        localStorage.setItem('token', response.data.token);
        localStorage.setItem('account', JSON.stringify(response.data.account));
        localStorage.setItem('email', JSON.stringify(response.data.account.email));
        localStorage.setItem('tokenEntryDate', new Date().toDateString());
        login();
        navigate('/');
      })
      .catch(() => {
        toast.error('Something went wrong. Please try again later!', BOTTOM_TOAST);
      });
  }

  return (
    <div className="flex h-screen items-center justify-center bg-slate50">
      <div className="grid gap-2 min-w-[300px]">
        <div className="flex justify-center mb-5">
          <img src={require('../assets/fullBarkLogo.png')} />
        </div>
        <TextInput label="Email" setTextChange={setEmailChange} />
        <TextInput
          variant="password"
          label="Password"
          setTextChange={setPasswordChange}
          onEnter={() => handleLogin()}
        />
        <Link to="/resetpassword" className="font-bold underline">
          Forgot Password?
        </Link>
        <div className="flex items-center justify-center mt-5">
          <Button
            label="Login"
            onClick={() => handleLogin()}
            isDisabled={email.length === 0 || password.length === 0}
          />
        </div>
      </div>
      {mfaModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <MFAModal
            totpSecret={totpSecret}
            requiresTotpSetup={requiresTotpSetup}
            onClose={() => setMfaModal(false)}
            onVerify={(token) => {
              handle2FA(token);
            }}
          />
        </div>
      )}
    </div>
  );
};
