import React from 'react';

interface BadgeProps {
  label: string;
  variant?: string;
}

export const Badge = ({ label, variant = 'primary' }: BadgeProps) => {
  if (variant === 'primary') {
    return (
      <div className="px-2 w-fit bg-primary rounded-md flex items-center justify-center">
        {label}
      </div>
    );
  }
  if (variant === 'secondary') {
    return (
      <div className="px-2 w-fit bg-lightGray rounded-md flex items-center justify-center">
        {label}
      </div>
    );
  }
  return <></>;
};
