import * as React from 'react';
import { Link } from 'react-router-dom';

import { CustomerSearchParams } from './CustomerLookupForm';
import { Address, Customer, Pet } from './types';
import { getAddressLines } from '../addressUtils';
import { Spinner } from '../assets/Spinner';
import { Pagination } from '../component/Pagination';
import { Table } from '../component/Table';
import { formatDateTime } from '../dateUtils';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { generateFullName } from '../nameUtils';
import { CustomerSearchHeaders } from '../tableHeaders';

interface CustomerLookupResultsProps {
  onClick: () => void;
  searchParams: CustomerSearchParams;
}

export const CustomerLookupResults = ({ searchParams, onClick }: CustomerLookupResultsProps) => {
  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<Partial<Customer>>({
    endpoint: '/customer_search',
    payload: searchParams,
    mapper: (e) => e,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="mb-5">
        <h1>Customer Lookup: {forPagination.countOfResults} Results</h1>
      </div>

      <Table
        header={CustomerSearchHeaders}
        data={data.map((data: Partial<Customer> & { primary_address: Address }) => {
          return {
            Name: (
              <Link
                className="cursor-pointer underline text-anchor"
                to={
                  `/customer-lookup/${data.id}__${generateFullName(
                    data.first_name,
                    data.last_name,
                    '_'
                  )}` ?? ''
                }
              >
                {generateFullName(data.first_name, data.last_name)}
              </Link>
            ),
            Email: data.email,
            Status: data.status ? data.status : '--',
            'Pet Names':
              data?.pets && data.pets.length > 0
                ? data.pets.map((pet: Partial<Pet>) => pet.name).join(', ')
                : '--',
            'Account Created': `${formatDateTime(data.created)}`,
            'Last Updated': `${formatDateTime(data.last_updated)}`,
            'Primary Shipping Address': <AddressRow address={data.primary_address} />,
          };
        })}
        internalPagination={false}
        tdClass="align-top"
      />
      {renderPagination && <Pagination {...forPagination} />}

      <div className="flex justify-center text-sm pt-3 pb-6">
        <a onClick={onClick} className="cursor-pointer underline text-anchor">
          Try another search
        </a>
      </div>
    </div>
  );
};

const AddressRow = ({ address }: { address: Address }) => {
  const addressLines = getAddressLines(address);
  return addressLines.map((addressLine, i) => (
    <p key={i} className="text-left">
      {addressLine}
    </p>
  ));
};
