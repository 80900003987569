import * as React from 'react';
import { Link } from 'react-router-dom';

import { Promotion, PromotionStatus } from './types';
import { Spinner } from '../assets/Spinner';
import { Table } from '../component/Table';
import { TableTools } from '../component/TableTools';
import { formatDateTime } from '../dateUtils';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { PromotionsHeader } from '../tableHeaders';

export const Promotions = () => {
  const mapPromotion = (promotion: Promotion) => {
    return {
      Promotion: (
        <Link to={`/promotions/${promotion.id}`} className="text-blue-500 hover:underline">
          {promotion.name}
        </Link>
      ),
      Status: (
        <div className="inline">
          {promotion.status === PromotionStatus.ACTIVE ? (
            <span className="bg-green-300  px-2 py-0.5 rounded">Active</span>
          ) : (
            <span className="bg-slate-300  px-2 py-0.5 rounded">Inactive</span>
          )}
        </div>
      ),
      'Start date': `${formatDateTime(promotion.start_timestamp)}`,
      'End date': `${formatDateTime(promotion.end_timestamp)}`,
      'Free Product': promotion.free_product_code,
    };
  };

  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<Promotion>({
    endpoint: '/promotions/',
    mapper: mapPromotion,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Manage Promotions</h1>
      </div>
      <TableTools
        renderPagination={renderPagination}
        forPagination={forPagination}
        isLoading={isLoading}
        searchBy={searchBy}
        reFetch={reFetch}
        hasData={data.length > 0}
        createLabel="Create Promotion"
        createLink="/promotions/create-promotion"
      >
        <Table header={PromotionsHeader} data={data} internalPagination={false} />
      </TableTools>
    </div>
  );
};
