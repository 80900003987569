import * as React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { OneTimeOrderModal } from './OneTimeOrderModal';
import { Table } from './Table';
import { mapSntApiDate } from '../dateUtils';
import { Customer } from '../pages/types';
import { CustomerOrdersHeader } from '../tableHeaders';

interface CustomerOrdersProps {
  customer: Customer;
  refreshCustomer: () => void;
}

const mapOrders = (orders: any) => {
  return orders
    .sort((a: any, b: any) => {
      const dateA: any = new Date(a.scheduled ? a.scheduled : a.charged);
      const dateB: any = new Date(b.scheduled ? b.scheduled : b.charged);

      return dateB - dateA;
    })
    .map((order: any) => {
      let fullAddress = '--';
      const orderDate = order.scheduled ? order.scheduled : order.charged;
      if (order.fulfillments.length > 0) {
        // TODO: Come up with a common Address Formatter
        const { address1, address2, city, state, zip, country } = order.fulfillments[0];
        fullAddress = [address1, address2, city, state, zip, country].filter(Boolean).join(', ');
      }

      let petNamesStr = '';
      const petNames: string[] = [];
      if (order.products.length > 0) {
        order.products.forEach((item: any) => {
          const petName = item?.pet_plan?.pet_name;
          if (petName && !petNames.includes(petName)) {
            petNames.push(petName);
          }
        });

        // Create a deduplicated string of pet names
        petNamesStr = petNames.join(', ');
      }

      return {
        'Order ID': (
          <Link
            className="cursor-pointer underline text-anchor"
            to={order.id ? `order/${order.id}` : ''}
          >
            {order.id}
          </Link>
        ),
        'Order Status': order.status,
        'Order Date': `${mapSntApiDate(orderDate)}`,
        'Order Type': order.order_type,
        'Order Total': `$${order.total_price}`,
        'Pet(s)': petNames.length > 0 ? petNamesStr : '--',
        'Payment Status': order.charge ? order.charge.status : '--',
        'Shipping Address': fullAddress,
      };
    });
};

export const CustomerOrders = ({ customer, refreshCustomer }: CustomerOrdersProps) => {
  let orders: any[] = [];
  orders = orders.concat(customer.orders.upcoming);
  orders = orders.concat(customer.orders.processing);
  orders = orders.concat(customer.orders.past);

  const [showOneTimeOrderModal, setShowOneTimeOrderModal] = useState(false);

  return (
    <div className="pt-3 pb-6">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Orders</b>
        <div className="text-sm ml-2">
          <a
            onClick={() => setShowOneTimeOrderModal(true)}
            className="cursor-pointer underline text-anchor"
          >
            Create One-Time Order
          </a>
        </div>
      </h2>
      <div className="bg-white">
        <Table header={CustomerOrdersHeader} data={mapOrders(orders)} />
      </div>
      {showOneTimeOrderModal && (
        <OneTimeOrderModal
          customer={customer}
          onConfirm={() => {
            setShowOneTimeOrderModal(false);
            refreshCustomer();
          }}
          onCancel={() => setShowOneTimeOrderModal(false)}
        />
      )}
    </div>
  );
};
