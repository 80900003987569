import React from 'react';

export const Spinner = () => {
  return (
    <div className="flex justify-center items-center h-screen">
      <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-900" />
    </div>
  );
};

export const InlineSpinner = () => {
  return (
    <div className="animate-spin rounded-full h-7 w-7 border-t-2 border-b-2 border-gray-900" />
  );
};
