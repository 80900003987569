import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { TrashIcon } from '../assets/TrashIcon';
import { isAdmin, isAllModuleAccessUser } from '../pages/constants';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface DeletePIIProps {
  email: string;
}

export const DeletePII = ({ email }: DeletePIIProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const canDeletePII = isAdmin() || isAllModuleAccessUser();

  const handleDeletePII = () => {
    axios
      .post(`delete_pii`, {
        email,
      })
      .then(() => {
        toast.success(`Success! The PII for customer ${email} has been deleted.`, BOTTOM_TOAST);
        window.location.reload();
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
    setShowConfirmModal(false);
  };
  if (email && canDeletePII) {
    return (
      <div className="flex justify-between items-center">
        <TrashIcon
          className="inline w-4 h-4 mx-1 cursor-pointer"
          onClick={() => setShowConfirmModal(true)}
        />
        {showConfirmModal && (
          <ConfirmationModal
            title={"Are you sure you want to delete this customer's PII?"}
            customMessage={
              <p className="text-sm mb-4 text-center text-red-600">
                By proceeding, you will remove all personally identifiable information from our
                database for this customer. This action is irreversible.
              </p>
            }
            confirmLabel="Confirm"
            onConfirm={handleDeletePII}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    );
  }
};
