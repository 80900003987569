import * as React from 'react';
import { Link } from 'react-router-dom';

import { Badge } from './Badge';
import { InfoTooltip } from './Tooltip';
import { formatDateTime } from '../dateUtils';
import { getCardSummary } from '../financialUtils';

interface PaymentDetailProps {
  order: any;
}

const getRefundEligibility = (charge: any, order: any) => {
  if (order.id.startsWith('SHO-')) {
    return false;
  }
  if (order?.order_type === 'REPLACEMENT') {
    return false;
  }
  return charge?.status === 'SUCCESS';
};

export const PaymentDetail = ({ order }: PaymentDetailProps) => {
  const { charge } = order;
  if (!charge) {
    return <></>;
  }

  const cardDetails = charge?.payload?.charges?.data?.[0]?.payment_method_details.card;
  const cardSummary = getCardSummary(charge?.card_number, charge?.card_type, cardDetails);
  const refundTotal = order.total_refunds ?? '0.00';
  const isRefundEligible = getRefundEligibility(charge, order);

  return (
    <>
      <h2 className="mt-4 mb-1 flex items-center">
        <b className="inline-block">Payment Details</b>
        {isRefundEligible && (
          <div className="text-sm ml-2">
            <Link className="cursor-pointer underline text-anchor" to="refund">
              Process a Refund
            </Link>
          </div>
        )}
        {!isRefundEligible && (
          <div className="ml-4 inline-flex text-sm">
            <Badge label="Refund Unavailable" variant="secondary" />
            <div className="ml-1">
              <div className="ml-1">
                <InfoTooltip text="Refund Eligibility Rules: must be Web 2.0 order, charge must be a success, and the order is not a replacement." />
              </div>
            </div>
          </div>
        )}
      </h2>
      <div className="bg-white border rounded-lg p-4 shadow-md w-[30em]">
        <div className="mb-2">
          <strong>Payment Status:</strong> {charge?.status}
        </div>
        <div className="mb-2">
          <strong>Payment Timestamp:</strong> {formatDateTime(order.charged)}
        </div>
        <div className="mb-2">
          <strong>Payment Type:</strong> {order?.payment_type}
        </div>
        <div className="mb-2">
          <strong>Payment Card:</strong> {cardSummary ?? '--'}
        </div>
        <div className="mb-2">
          <strong>Payment Failure Reason:</strong> {charge.error ?? '--'}
        </div>
        <div className="mb-2">
          <strong>Refund Total:</strong> ${refundTotal}
        </div>
      </div>
    </>
  );
};
