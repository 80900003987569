import * as React from 'react';

import { disabledBackgroundGray } from '../pages/constants';

interface TextInputProps {
  label?: string;
  placeholder?: string;
  setTextChange?: (text: string) => void;
  variant?: string;
  disabled?: boolean;
  value?: string;
  className?: string;
  type?: React.HTMLInputTypeAttribute;
  bottomText?: string;
  error?: string;
  onEnter?: () => void; // Add onEnter prop for handling Enter key press
}

export const TextInput = ({
  label,
  placeholder,
  setTextChange,
  variant = 'text',
  disabled = false,
  value,
  className,
  type = undefined,
  bottomText = '',
  error = '',
  onEnter,
}: TextInputProps) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && onEnter) {
      onEnter();
    }
  };

  return (
    <>
      {label && <div>{label}</div>}
      <input
        onWheel={(e) => (e.target as HTMLElement).blur()}
        className={`${className} border border-gray-300 rounded text-base p-2 w-full ${
          disabled ? disabledBackgroundGray : ''
        }`}
        placeholder={placeholder ?? ''}
        type={variant === 'password' ? 'password' : type ? type : 'text'}
        disabled={disabled}
        value={value}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          if (!disabled && setTextChange) {
            setTextChange(event.target.value);
          }
        }}
        onKeyDown={handleKeyDown}
      />
      {error && <p className="text-red-500 text-xs font-normal w-full overflow-auto">{error}</p>}
      {!error && bottomText && <p className="mt-1 text-xs text-gray font-normal">{bottomText}</p>}
    </>
  );
};
