import React, { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';

import { Promotion, PromotionStatus } from './types';
import { axios } from '../api';
import { Badge } from '../component/Badge';
import { ChangePromotionStatus } from '../component/ChangePromotionStatus';
import { TextInput } from '../component/TextInput';
import { InfoTooltip } from '../component/Tooltip';
import { formatDateTime } from '../dateUtils';

export const ViewPromotion = () => {
  const { promotionId } = useParams<{ promotionId: string }>();
  const [promotionData, setPromotionData] = useState<Promotion>();
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    axios
      .get(`promotions/${promotionId}`)
      .then((response) => {
        setPromotionData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center justify-self-start bg-slate50 p-8">
      <div className="bg-lightGray rounded-lg shadow-md p-4 w-[750px] relative border font-semibold">
        <h1 className="text-left">View Promotion</h1>
        <p className="mt-1 text-xs text-gray font-normal">
          This promotion will apply to trial orders only. When this promotion is active, the free
          product(s) will be shown to customers on the Funnel checkout page, and a banner will alert
          them to the presence of the active promotion.
        </p>
        <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
          <div className="text-md text-left">
            <div className="w-full mb-2">
              <h2>Promotion name</h2>
              <TextInput placeholder="None" value={promotionData?.name} disabled />
            </div>

            <div className="w-full mb-2">
              <h2 className="flex">
                Promotion banner{' '}
                <span className="px-2">
                  <InfoTooltip text="Customer-facing banner to be displayed throughout the Funnel" />
                </span>
              </h2>
              <TextInput placeholder="None" value={promotionData?.banner_copy} disabled />
            </div>
          </div>

          <div className="w-full mb-2">
            <div className="flex flex-row justify-between">
              <div className="flex-1 mr-4">
                <h2>Status</h2>
                <div className="flex">
                  {promotionData && (
                    <ChangePromotionStatus
                      isActive={promotionData?.status === PromotionStatus.ACTIVE}
                      id={promotionData.id}
                      name={promotionData.name}
                      reFetch={fetchData}
                    />
                  )}
                </div>
              </div>
              <div className="flex-1">
                <h2>Creator</h2>
                <Badge label={promotionData?.created_by ?? 'n/a'} />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>
            Active dates <span className="text-xs">(Optional)</span>
          </h2>
          <p className="text-xs text-gray-500 font-normal">
            The customer will only receive this promotion if they check out with their trial order
            within this timeframe
          </p>

          <div className="flex mt-2">
            <div className="w-1/2 pr-2">
              <h3>Start Date</h3>
              <div className="w-full mb-2">
                <span>
                  {promotionData?.start_timestamp
                    ? formatDateTime(promotionData.start_timestamp, true)
                    : 'Not set'}
                </span>
              </div>
            </div>
            <div className="w-1/2">
              <h3>End Date</h3>
              <div className="w-full mb-2">
                <span>
                  {promotionData?.end_timestamp
                    ? formatDateTime(promotionData.end_timestamp, true)
                    : 'Not set'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full mb-2">
            <h2>Free promotional product(s)</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-3/4">
                <TextInput placeholder="None" value={promotionData?.free_product_code} disabled />
              </div>
              <div className="w-1/4">
                <TextInput
                  placeholder="None"
                  value={promotionData?.quantity ? promotionData.quantity.toString() : ''}
                  disabled
                />
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full mb-2">
            <h2>Conditional product(s) for promotion eligibility</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-full">
                <TextInput
                  placeholder="None"
                  value={
                    promotionData?.required_conditions_products
                      ? promotionData.required_conditions_products.join(', ')
                      : 'None'
                  }
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="w-full mb-2">
            <h2>Conditional spend amount for promotion eligibility</h2>
            <div className="flex justify-between space-x-4">
              <div className="w-full">
                <TextInput
                  placeholder="None"
                  value={
                    promotionData?.minimum_order_gross_value_threshold_amount
                      ? `$ ${promotionData.minimum_order_gross_value_threshold_amount.toString()}`
                      : '$0.00'
                  }
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
