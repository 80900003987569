import * as React from 'react';

export const HamburgerMenu = ({ className, onClick }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" className={className} onClick={onClick}>
      <path d="m0 0h24v24h-24z" fill="#fff" opacity="0" transform="matrix(-1 0 0 -1 24 24)" />
      <g fill="#231f20">
        <rect height="2" rx=".95" width="18" x="3" y="11" />
        <rect height="2" rx=".95" width="18" x="3" y="16" />
        <rect height="2" rx=".95" width="18" x="3" y="6" />
      </g>
    </svg>
  );
};
