export const capitalize = (str: string | undefined) => {
  if (!str) return '';

  let strArr = str.split(' ');

  strArr = strArr.map((word) => {
    let transformed = word.toLocaleLowerCase();

    transformed = transformed.charAt(0).toLocaleUpperCase() + transformed.slice(1);

    return transformed;
  });

  return strArr.join(' ');
};
