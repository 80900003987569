import { useCallback, useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { PaginationProps } from '../component/Pagination';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

import { CustomerSearchParams } from '@/pages/CustomerLookupForm';

type Pagination = {
  count: number;
  next: string | null;
  previous: string | null;
};
export interface TResponseWithPagination<T> extends Pagination {
  results: T[];
}

type Props<T> = {
  endpoint: string;
  mapper: (element: T) => any;
  payload?: CustomerSearchParams;
  pageSize?: number;
};

const VISIBLE_PAGES_LENGTH_FOR_SELECT = 5;

export const useFetchWithPagination = <T>({
  endpoint,
  payload,
  mapper,
  pageSize = 25,
}: Props<T>) => {
  const [data, setData] = useState<T[] | null>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [paginationObject, setPaginationObject] = useState<Pagination>({
    next: null,
    previous: null,
    count: 0,
  });
  const [searchField, setSearchField] = useState<string | undefined>();
  const [searchText, setSearchText] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const renderPagination = useMemo(() => paginationObject.count > 10, [paginationObject.count]);
  const totalPages = useMemo(() => {
    return Math.ceil(paginationObject.count / pageSize);
  }, [pageSize, paginationObject.count]);

  const pages = useMemo(() => {
    const start =
      Math.floor((currentPage - 1) / VISIBLE_PAGES_LENGTH_FOR_SELECT) *
      VISIBLE_PAGES_LENGTH_FOR_SELECT;
    const end =
      start + VISIBLE_PAGES_LENGTH_FOR_SELECT > totalPages
        ? totalPages
        : start + VISIBLE_PAGES_LENGTH_FOR_SELECT;
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }, [currentPage, totalPages]);

  const fetch = (requestUrl?: string, params: { page?: number } = {}) => {
    setIsLoading(true);
    const apiRequest = requestUrl ? requestUrl : endpoint;
    let response;
    if (apiRequest.includes('customer_search') && !!payload) {
      response = axios.post(apiRequest, payload, {
        params,
      });
    } else {
      response = axios.get(apiRequest, {
        params: {
          ...(searchField ? { [searchField]: searchText } : undefined),
          ...params,
        },
      });
    }

    response
      .then((response) => {
        const { next, count, previous, results } = response.data as TResponseWithPagination<T>;
        setData(results.map((user: T) => mapper(user)));
        setPaginationObject({ next, previous, count });
        setIsLoading(false);
      })
      .catch((e) => {
        setIsLoading(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    fetch();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const goTo = (page: number) => {
    setCurrentPage(page);
    fetch(endpoint, { page });
  };

  const goNext = useCallback(() => {
    if (paginationObject.next) {
      setCurrentPage((prev) => prev + 1);
      fetch(endpoint, { page: currentPage + 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginationObject.next, endpoint]);

  const goPrev = useCallback(() => {
    if (paginationObject.previous) {
      setCurrentPage((prev) => prev - 1);
      fetch(endpoint, { page: currentPage - 1 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, paginationObject.previous, endpoint]);

  const searchBy = (text: string, field: string) => {
    setSearchField(field);
    setSearchText(text);
    setCurrentPage(1);
    fetch(endpoint, { [field]: text });
  };

  const reFetch = () => {
    setSearchText(undefined);
    fetch(endpoint, { [searchField as string]: undefined });
    setCurrentPage(1);
  };

  return {
    currentData: data,
    currentPage,
    canGo: {
      next: !!paginationObject.next,
      previous: !!paginationObject.previous,
    },
    pages,
    goTo,
    goNext,
    goPrev,
    renderPagination,
    searchBy,
    countOfResults: paginationObject.count,
    reFetch,
    isLoading,
  } as PaginationProps & {
    currentData: any;
    renderPagination: boolean;
    searchBy: (t: string, field: string) => void;
    countOfResults: number;
    reFetch: () => void;
    isLoading: boolean;
  };
};
