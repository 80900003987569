import { isSameDay, isAfter } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

import {
  discountTypesInfo,
  limitTypesList,
  discountTypesList,
  isActiveOptionsList,
  DiscountLimitType,
} from './constants';
import { axios } from '../api';
import { Pencil } from '../assets/Pencil';
import { Button } from '../component/Button';
import { Checkbox } from '../component/Checkbox';
import { ConfirmationModal } from '../component/ConfirmationModal';
import { Dropdown } from '../component/Dropdown';
import { TextInput } from '../component/TextInput';
import { LargeTooltip } from '../component/Tooltip';
import { TransferList } from '../component/TransferList';
import { formatDateTime } from '../dateUtils';
import 'react-datepicker/dist/react-datepicker.css';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const CreateDiscount = () => {
  const discountTypes = useMemo(() => discountTypesList, []);
  const isActiveOptions = useMemo(() => isActiveOptionsList, []);
  const limitTypes = useMemo(() => limitTypesList, []);

  const [code, setCode] = useState('');
  const [type, setType] = useState(discountTypes[0]);
  const [value, setValue] = useState(0);
  const [trialValue, setTrialValue] = useState(0);
  const [description, setDescription] = useState('');
  const [limitType, setLimitType] = useState<any>();
  const [limitValue, setLimitValue] = useState(0);
  const [startDate, setStartDate] = useState<any>();
  const [endDate, setEndDate] = useState<any>();
  const [isActive, setIsActive] = useState(isActiveOptions[0]);
  const [trial, setTrial] = useState(false);
  const [subscription, setSubcription] = useState(false);
  const [onetime, setOnetime] = useState(false);
  const [availableProducts, setAvailableProducts] = useState<string[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<string[]>([]);
  const [endDateError, setEndDateError] = useState('');
  const [validationErrors, setValidationErrors] = useState<any>(null);
  const [showSelectEligibleProducts, setShowSelectEligibleProducts] = useState(false);
  const [showConfirmCreateDiscountModal, setShowConfirmCreateDiscountModal] = useState(false);
  const navigate = useNavigate();

  const changeLimitType = (newSelection: any) => {
    if (newSelection?.value === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT) {
      setTrial(true);
      setSubcription(true);
      setOnetime(false);
    }
    setLimitType(newSelection);
  };

  const requiredFieldsAreValid =
    endDateError.length === 0 &&
    code &&
    type &&
    value &&
    limitType &&
    (limitType.value !== DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT ||
      trialValue) &&
    (!limitType.needsValue || limitValue) &&
    (!(startDate || endDate) || (startDate && endDate)) &&
    (trial || subscription || onetime);

  useEffect(() => {
    axios
      .get(`products/discount-eligible-codes`)
      .then((response) => {
        setAvailableProducts(response.data);
      })
      .catch((error) => console.error('Error:', error));
  }, []);

  const handleCreateDiscount = () => {
    const requestBody = {
      code,
      discount_type: type.value,
      value: Number(value),
      trial_value:
        limitType.value === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
          ? Number(trialValue)
          : null,
      description,
      limit_type: limitType.value,
      limit_value: limitType.needsValue ? Number(limitValue) : null,
      available_start_date: startDate,
      available_end_date: endDate,
      is_active: isActive.value === 'true',
      order_trial: trial,
      order_subscription: subscription,
      order_onetime: onetime,
      eligible_products: selectedProducts,
    };
    axios
      .post(`discounts`, requestBody)
      .then(() => {
        navigate('/discounts');
        toast.success(`Successfully created discount code ${code} `, BOTTOM_TOAST);
      })
      .catch((e) => {
        setValidationErrors(e.response.data);
        setShowConfirmCreateDiscountModal(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  const handleCodeChange = (value: string) => {
    setCode(value.toUpperCase());
  };

  return (
    <div className="flex justify-center items-center justify-self-start bg-slate50 p-8">
      <div className="bg-lightGray rounded-lg shadow-md p-4 w-[750px] relative border font-semibold">
        <h1 className="text-left">Create new discount</h1>
        <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
          <div className="text-md text-left">
            <div className="w-full mb-2">
              <h2>Discount code</h2>
              <TextInput
                placeholder="Enter code"
                value={code}
                setTextChange={(value) => handleCodeChange(value)}
                bottomText="Customer-facing discount code (e.g. WELCOME20)"
                error={validationErrors?.code}
              />
            </div>

            <div className="w-full mb-2">
              <h2>
                Description <span className="text-xs">(Optional)</span>
              </h2>
              <TextInput
                placeholder="Enter description"
                value={description}
                setTextChange={setDescription}
                bottomText="Internal description of discount code"
              />
            </div>

            <div className="w-full mb-2 {isActive.className}">
              <h2>Status</h2>
              <Dropdown
                options={isActiveOptions}
                selectedOption={isActive}
                onSelect={(newSelection) => setIsActive(newSelection)}
              />
            </div>
          </div>
        </div>
        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>
            Active dates <span className="text-xs">(Optional)</span>
          </h2>
          <p className="text-xs text-gray-500 font-normal">
            The customer will only receive the discount if applied within this time frame
          </p>

          <div className="flex mt-2">
            <div className="w-1/2 pr-2">
              <h3>Start Date</h3>
              <div className="w-full mb-2">
                <DatePicker
                  selected={startDate}
                  minDate={new Date()}
                  onChange={(date) => {
                    setStartDate(date);
                    if (startDate && !endDate) {
                      setEndDateError('End date is required');
                    } else if (date && endDate && date >= endDate) {
                      setEndDateError('End time must be later than start time');
                    } else {
                      setEndDateError('');
                    }
                  }}
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  dateFormat="dd/MM/yyyy"
                  customInput={
                    <div className="flex items-center cursor-pointer">
                      <span>{startDate ? formatDateTime(startDate, true) : 'Select Date'}</span>
                      <Pencil className="w-4 h-4 ml-1 mb-1" />
                    </div>
                  }
                />
              </div>
            </div>
            <div className="w-1/2">
              <h3>End Date</h3>
              <div className="w-full mb-2">
                <DatePicker
                  key={startDate?.toString()}
                  selected={endDate || startDate}
                  minDate={startDate || new Date()}
                  filterTime={(time) => {
                    if (startDate && isSameDay(time, startDate)) {
                      return isAfter(time, startDate);
                    }
                    return true;
                  }}
                  onChange={(date) => {
                    setEndDate(date);
                    if (date && date <= startDate) {
                      setEndDateError('End time must be later than start time');
                    } else {
                      setEndDateError('');
                    }
                  }}
                  dateFormat="dd/MM/yyyy"
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={30}
                  customInput={
                    <div className="flex items-center cursor-pointer">
                      <span>{endDate ? formatDateTime(endDate, true) : 'Select Date'}</span>
                      <Pencil className="w-4 h-4 ml-1 mb-1" />
                    </div>
                  }
                />
                {endDateError && (
                  <div className="error text-red-500 text-xs font-normal">{endDateError}</div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full pr-2 mb-2">
            <h2 className="flex">
              Discount limit type{' '}
              <span className="px-2">
                <LargeTooltip textArray={discountTypesInfo} />
              </span>
            </h2>
            <Dropdown
              options={limitTypes}
              selectedOption={limitType}
              onSelect={(newSelection) => changeLimitType(newSelection)}
              noDefaultSelection
              defaultText="Select limit type"
            />
          </div>
          {limitType?.needsValue ? (
            <>
              <h2>Discount limit</h2>
              {limitType?.value ===
                DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT && (
                <div className="flex">
                  <div className="w-1/4 mb-2">
                    <div>
                      <TextInput
                        placeholder="Enter value"
                        value="1"
                        setTextChange={(value) => setLimitValue(Number(value))}
                        type="number"
                        disabled
                        error={validationErrors?.limit_value}
                      />
                    </div>
                  </div>
                  <div className="w-3/4 items-center flex">
                    <span className="ml-2">Trial Order</span>
                  </div>
                </div>
              )}

              <div className="flex">
                <div className="w-1/4">
                  <div>
                    <TextInput
                      placeholder="Enter value"
                      value={limitValue.toString()}
                      setTextChange={(value) => setLimitValue(Number(value))}
                      type="number"
                      error={validationErrors?.limit_value}
                    />
                  </div>
                </div>
                <div className="w-3/4 items-center flex">
                  <span className="ml-2">{limitType?.valueText ?? ''}</span>
                </div>
              </div>
              <p className="mt-1 text-xs text-gray font-normal">
                {limitType?.valueBottomText ?? ''}
              </p>
            </>
          ) : null}
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>Discount value</h2>
          {limitType?.value === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT ? (
            <>
              <div className="flex">
                <div className="w-2/5 mb-2 pr-2">
                  <div className="w-full mb-2">
                    <Dropdown
                      options={discountTypes}
                      selectedOption={discountTypes[0]}
                      onSelect={(newSelection) => setType(newSelection)}
                      isDisabled
                    />
                  </div>
                </div>
                <div className="w-1/5">
                  <div className="w-full mb-2">
                    <TextInput
                      placeholder="Enter value"
                      value={trialValue.toString()}
                      type="number"
                      setTextChange={(value) => setTrialValue(Number(value))}
                      error={validationErrors?.trial_value}
                    />
                  </div>
                </div>
                <div className="w-2/5 items-center flex">
                  <span className="ml-2">off of Trial Order</span>
                </div>
              </div>

              <div className="flex">
                <div className="w-2/5 mb-2 pr-2">
                  <div className="w-full mb-2">
                    <Dropdown
                      options={discountTypes}
                      selectedOption={discountTypes[0]}
                      onSelect={(newSelection) => setType(newSelection)}
                      isDisabled
                    />
                  </div>
                </div>
                <div className="w-1/5">
                  <div className="w-full mb-2">
                    <TextInput
                      placeholder="Enter value"
                      value={value.toString()}
                      type="number"
                      setTextChange={(value) => setValue(Number(value))}
                      error={validationErrors?.value}
                    />
                  </div>
                </div>
                <div className="w-2/5 items-center flex">
                  <span className="ml-2">off of Subscription Order(s)</span>
                </div>
              </div>
            </>
          ) : (
            <div className="flex">
              <div className="w-3/5 mb-2 pr-2">
                <div className="w-full mb-2">
                  <Dropdown
                    options={discountTypes}
                    selectedOption={type}
                    onSelect={(newSelection) => setType(newSelection)}
                  />
                </div>
              </div>

              <div className="w-2/5">
                <div className="w-full mb-2">
                  <TextInput
                    placeholder="Enter value"
                    value={value.toString()}
                    type="number"
                    setTextChange={(value) => setValue(Number(value))}
                    error={validationErrors?.value}
                  />
                </div>
              </div>
            </div>
          )}

          <div className="mb-2">
            <h2 className="mb-2">Eligible order types</h2>
            <>
              <Checkbox
                label="Trial"
                isChecked={trial}
                onChange={(isChecked) => setTrial(isChecked)}
                disabled={
                  limitType?.value ===
                  DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
                }
              />
              <Checkbox
                label="Subscription"
                isChecked={subscription}
                onChange={(isChecked) => setSubcription(isChecked)}
                disabled={
                  limitType?.value ===
                  DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
                }
              />
              <Checkbox
                label="Onetime"
                isChecked={onetime}
                onChange={(isChecked) => setOnetime(isChecked)}
                disabled={
                  limitType?.value ===
                  DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT
                }
              />
            </>
          </div>
          <h2>Eligible products</h2>
          <p>
            Should this discount be applicable to all products or just a specific list of products?
          </p>
          <div className="py-2">
            <input
              type="radio"
              id="option1"
              name="option"
              value="Option 1"
              checked={showSelectEligibleProducts === false}
              onChange={() => setShowSelectEligibleProducts(false)}
            />
            <label htmlFor="option1" className="pb-2">
              All products
              <p className="text-xs text-gray-500 font-normal">
                Discount will be added to all products, including any new products we add in the
                future
              </p>
            </label>
            <input
              type="radio"
              id="option2"
              name="option"
              value="Option 2"
              checked={showSelectEligibleProducts === true}
              onChange={() => setShowSelectEligibleProducts(true)}
            />
            <label htmlFor="option2">
              Select products
              <p className="text-xs text-gray-500 font-normal">
                Discount will only be applicable to select list of products
              </p>
            </label>
          </div>
          {showSelectEligibleProducts && (
            <TransferList
              availableProducts={availableProducts}
              setAvailableProducts={setAvailableProducts}
              selectedProducts={selectedProducts}
              setSelectedProducts={setSelectedProducts}
            />
          )}
        </div>

        <div className="flex justify-center flex-col items-center mt-2">
          <Button
            variant={requiredFieldsAreValid ? 'primary' : 'secondary'}
            label="Create Discount"
            isDisabled={!requiredFieldsAreValid}
            onClick={() => setShowConfirmCreateDiscountModal(true)}
          />
        </div>

        {showConfirmCreateDiscountModal && (
          <ConfirmationModal
            title="Are you sure you want to create this new discount code?"
            message="Please ensure all fields are correct before proceeding, as they are not editable after the discount is created."
            confirmLabel="Create Discount"
            onConfirm={() => handleCreateDiscount()}
            onCancel={() => setShowConfirmCreateDiscountModal(false)}
          />
        )}
      </div>
    </div>
  );
};
