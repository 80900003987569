import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from './Button';
import { CheckboxTable } from './CheckboxTable';
import { ConfirmationModal } from './ConfirmationModal';
import { Dropdown } from './Dropdown';
import { LineInfo } from './LineInfo';
import { axios } from '../api';
import { Close } from '../assets/Close';
import { digitsOptions } from '../dropdownOptions';
import { generateFullName } from '../nameUtils';
import { ReplacementOrderHeader } from '../tableHeaders';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const ReplacementOrderModal = ({ customer, order, onConfirm, onCancel }: any) => {
  const [selectedRows, setSelectedRows] = useState<number[]>([]);
  const [shippingAddress, setShippingAddress] = useState<string>();
  const [tableData, setTableData] = useState<any[]>([]);
  const { addresses } = customer;
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleCheckboxChange = (index: number) => {
    if (selectedRows.includes(index)) {
      setSelectedRows((prevSelected) => prevSelected.filter((rowIndex) => rowIndex !== index));
    } else {
      setSelectedRows((prevSelected) => [...prevSelected, index]);
    }
  };

  const handleQuantityChange = useCallback((index: number, quantity: number) => {
    setTableData((prev) =>
      prev.map((item) => (item.index === index ? { ...item, _quantity: quantity } : item))
    );
  }, []);

  useEffect(() => {
    // TODO: Come up with a common Address Formatter
    const { address1, address2, city, state, zip, country } = addresses.find(
      (address: any) => address.type === 'SHIPPING' && address.is_primary
    );
    setShippingAddress([address1, address2, city, state, zip, country].filter(Boolean).join(', '));
  }, [addresses]);

  useEffect(() => {
    setTableData(
      order.products.map((product: any, index: number) => {
        return {
          'Pet Name': product.pet_plan.pet_name,
          Quantity: (
            <div className="w-[70px] mr-[10px]">
              <Dropdown
                options={digitsOptions.slice(0, product.quantity)}
                isDisabled={!selectedRows.includes(index)}
                onSelect={(newSelection) => handleQuantityChange(index, +newSelection.value)}
              />
            </div>
          ),
          'Product Code': product.code,
          'Product Description': <div className="w-[300px]">{product.name}</div>,
          index,
          _quantity: product.quantity,
          ...product,
        };
      })
    );
  }, [handleQuantityChange, order.products, selectedRows, selectedRows.length]);

  const onReplaceAnOrder = () => {
    axios
      .post(`orders`, {
        account: customer.id,
        order_products: selectedRows.map((index) => {
          const orderProduct = tableData[index];
          return {
            type: 'REPLACEMENT',
            product_code: orderProduct.code,
            pet_plan: orderProduct.pet_plan.id,
            quantity: orderProduct._quantity,
            replacement_parent_order_product: orderProduct.id,
          };
        }),
        order_type: 'REPLACEMENT',
        replacement_parent_order: order.id,
      })
      .then(() => {
        setShowConfirmModal(false);
        const urlParts = window.location.href.split('/');
        // Remove the last 2 segments (2 levels up)
        urlParts.pop();
        urlParts.pop();
        location.href = urlParts.join('/');
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 min-w-[700px] relative border max-h-[80vh] overflow-y-auto">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        <h1 className="font-bold text-left mb-2">Replace Order</h1>
        <LineInfo
          title="Customer"
          value={`${generateFullName(customer.first_name, customer.last_name)} (${customer.email})`}
        />
        <LineInfo title="Primary Shipping Address" value={shippingAddress} />
        <hr />
        <div className="bg-white pt-2">
          <b>Which product(s) should we include in this replacement order?</b>
          <CheckboxTable
            header={ReplacementOrderHeader}
            data={tableData}
            selectedRows={selectedRows}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>
        <div className="flex justify-center py-4">
          <Button
            isDisabled={selectedRows.length === 0}
            label="Replace Order"
            variant={selectedRows.length === 0 ? 'secondary' : 'primary'}
            onClick={() => setShowConfirmModal(true)}
          />
        </div>
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed with this replacement order?"
          message=""
          customMessage={
            <p className="text-sm mb-4 text-center">
              This action will create a new order that is processed immediately. The customer will{' '}
              <strong>not</strong> be charged for this replacement order.
            </p>
          }
          confirmLabel="Confirm"
          onConfirm={onReplaceAnOrder}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};
