import * as React from 'react';
import { Link } from 'react-router-dom';

import { DatabaseIcon } from '../assets/DatabaseIcon';
import { DiscountIcon } from '../assets/DiscountIcon';
import { HandGiftIcon } from '../assets/HandGiftIcon';
import { RightArrow } from '../assets/RightArrow';
import { Search } from '../assets/Search';
import { User } from '../assets/User';
import { Page } from '../pages/types';

interface ComponentBoxProps {
  title: string;
  page: Page;
}

const isHomePageItem = (page: Page) => {
  return (
    page === Page.UserManagement ||
    page === Page.CustomerLookup ||
    page === Page.DiscountsPage ||
    page === Page.DiscountLookup ||
    page === Page.ManageDataPage ||
    page === Page.PromotionsPage
  );
};
export const ComponentBox = ({ title, page }: ComponentBoxProps) => {
  if (!isHomePageItem(page)) {
    return <>This page is not supported!</>;
  }

  return (
    <Link to={page}>
      <div className="m-1 p-6 w-96 h-15 rounded-sm shadow-md cursor-pointer bg-secondary border flex items-center">
        {page === Page.UserManagement && <User className="w-6 h-6 mr-4" />}
        {page === Page.CustomerLookup && <Search className="w-6 h-6 mr-4" />}
        {page === Page.DiscountLookup && <DiscountIcon className="w-6 h-6 mr-4" />}
        {page === Page.DiscountsPage && <DiscountIcon className="w-6 h-6 mr-4" />}
        {page === Page.ManageDataPage && <DatabaseIcon className="w-6 h-6 mr-4" />}
        {page === Page.PromotionsPage && <HandGiftIcon className="w-6 h-6 mr-4" />}
        <h2 className="text-xl font-semibold">{title}</h2>
        <RightArrow className="ml-auto w-6 h-6" />
      </div>
    </Link>
  );
};
