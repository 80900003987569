import React from 'react';

import { computeCustomerSpend } from '../../financialUtils';
import {
  getFulfilledOrderCount,
  getReplacedOrderCount,
  getCxCodeCount,
  getWinbackDiscountEligiblility,
} from '../../orderUtils';
import { InfoTooltip } from '../Tooltip';

import { Customer } from '@/pages/types';

export const CustomerStats = ({ customer }: { customer: Customer }) => {
  return (
    <div className="flex-col">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Customer Stats</b>
      </h2>
      <div className="bg-white border rounded-lg p-4 shadow-md">
        <div className="mb-2">
          <strong>Total lifetime spend amount:</strong> ${computeCustomerSpend(customer.orders)}
        </div>
        <div className="mb-2">
          <strong>Number of fulfilled orders:</strong> {getFulfilledOrderCount(customer.orders)}
        </div>
        <div className="mb-2">
          <strong>Number of replaced orders:</strong> {getReplacedOrderCount(customer.orders)}
        </div>
        <div className="mb-2 flex items-center">
          <strong>Number of orders discounted by CX:</strong>{' '}
          <div className="flex items-center ml-1">
            <span>{getCxCodeCount(customer.orders)}</span>
            <div className="ml-1">
              <InfoTooltip text="Sum of discounted orders with codes starting with 10POFFX1, 30POFFX1, or CX-ACCOM" />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <strong>Winback discount code eligible: </strong>{' '}
          {getWinbackDiscountEligiblility(customer.orders)}
        </div>
      </div>
    </div>
  );
};
