import React from 'react';

import { Treatment } from '@/pages/types';

export const CustomerTreatments = ({ treatments }: { treatments: Treatment[] }) => {
  treatments.sort((a, b) => {
    const dateA = new Date(a.created);
    const dateB = new Date(b.created);
    return dateB.getTime() - dateA.getTime();
  });
  return (
    <div className="flex-col">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Customer Treatments ({treatments.length})</b>
      </h2>
      <div className="max-w-[550px] max-h-[225px] bg-white border rounded-lg p-4 shadow-md overflow-y-scroll flex flex-col gap-4">
        {treatments.map((treatment) => {
          const formattedDate = new Date(treatment.created);
          return (
            <div key={treatment.feature_flag} className="flex flex-col">
              <h2>{treatment.feature_flag}</h2>
              <p>
                <strong>Status: </strong> {treatment.feature_flag_status}
              </p>
              <p>
                <strong>Assignment date:</strong> {formattedDate.toLocaleDateString('default')}
              </p>
              <h3>
                <b>Treatment: </b>
                {treatment.treatment_name}
              </h3>
              <div className="flex justify-start align-top gap-4">
                <p>
                  <strong>Is Control:</strong> {treatment.treatment_control.toString()}
                </p>
                <p>
                  <strong>Distribution:</strong> {treatment.treatment_distribution}%
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
