import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal } from './ConfirmationModal';
import { axios } from '../api';
import { Key } from '../assets/Key';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface SendPasswordResetLinkProps {
  label: string;
}

export const SendPasswordResetLink = ({ label }: SendPasswordResetLinkProps) => {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleSendPasswordResetLink = () => {
    axios
      .post(`password_reset_request`, {
        email: label,
      })
      .then(() => {
        toast.success(
          `Success! ${label} will receive an email to reset their password within a couple of minutes.`,
          BOTTOM_TOAST
        );
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };
  if (label) {
    return (
      <div className="flex justify-between items-center">
        <div className="inline">{label}</div>
        <Key
          className="inline ml-3 w-5 h-5 cursor-pointer"
          onClick={() => setShowConfirmModal(true)}
        />
        {showConfirmModal && (
          <ConfirmationModal
            title={`Send password reset link to ${label}?`}
            confirmLabel="Send"
            onConfirm={handleSendPasswordResetLink}
            onCancel={() => setShowConfirmModal(false)}
          />
        )}
      </div>
    );
  }

  return <Key className="w-5 h-5" />;
};
