import * as React from 'react';

import { BarkUser, Group, UserGroup } from './types';
import { Spinner } from '../assets/Spinner';
import { SendPasswordResetLink } from '../component/SendPasswordResetLink';
import { Table } from '../component/Table';
import { TableTools } from '../component/TableTools';
import { UpdateUserStatus } from '../component/UpdateUserStatus';
import { formatDateTime } from '../dateUtils';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { UserManagementHeaders } from '../tableHeaders';

const groupNameMapper = (group: string) => {
  if (group === UserGroup.OpsAdmin) {
    return 'Ops Admin';
  }
  if (group === UserGroup.Admin) {
    return 'Admin';
  }
  if (group === UserGroup.TeamMember) {
    return 'Team Member';
  }
  if (group === UserGroup.AllModuleAccess) {
    return 'All Module Access User';
  }
  return 'Unsupported Group';
};

const displayGroup = (groups: Group[]) =>
  groups.map((group: Group) => groupNameMapper(group.name)).join(', ');

export const UserManagement = () => {
  const mapAccountToBarkUser = (user: BarkUser) => {
    return {
      Name: `${user.first_name} ${user.last_name}`,
      Email: <SendPasswordResetLink label={user.email} />,
      'User Group': `${displayGroup(user.groups)}`,
      Added: `${formatDateTime(user.created)}`,
      'Last Updated': `${formatDateTime(user.last_updated)}`,
      Status: (
        <UpdateUserStatus
          isActive={user.is_active}
          email={user.email}
          handleUserUpdated={reFetch}
        />
      ),
    };
  };

  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<BarkUser>({
    endpoint: '/bark_user/',
    mapper: mapAccountToBarkUser,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Manage Users</h1>
      </div>
      <TableTools
        renderPagination={renderPagination}
        forPagination={forPagination}
        isLoading={isLoading}
        searchBy={searchBy}
        reFetch={reFetch}
        hasData={data.length > 0}
        createLabel="Create User"
        createLink="/user-management/create-user"
      >
        <Table header={UserManagementHeaders} data={data} />
      </TableTools>
    </div>
  );
};
