import React, { useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import { toast } from 'react-toastify';

import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { ConfirmationModal } from './ConfirmationModal';
import { Dropdown } from './Dropdown';
import { MultiSelectDropdown } from './MultiSelectDropdown';
import { TextInput } from './TextInput';
import { axios } from '../api';
import { Close } from '../assets/Close';
import { DownArrow } from '../assets/DownArrow';
import { Pencil } from '../assets/Pencil';
import { InlineSpinner } from '../assets/Spinner';
import { formatDate, formatDateApi } from '../dateUtils';
import {
  booleanOptions,
  genderOptions,
  monthOptions,
  yearOptions,
  mealOptions,
} from '../dropdownOptions';
import { Customer, Option } from '../pages/types';
import { createPetAndPetPlan } from '../petApiUtils';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';
import 'react-datepicker/dist/react-datepicker.css';

enum AddPetStepper {
  InputData,
  SelectPlan,
  Review,
}

interface AddPetModalProps {
  customer: Customer;
  onConfirm: () => void;
  onCancel: () => void;
  lifestyleOptions: any;
  healthConditionOptions: any;
  bodyTypeOptions: any;
  breedOptions: any;
}

export const AddPetModal = ({
  customer,
  onConfirm,
  onCancel,
  lifestyleOptions,
  healthConditionOptions,
  bodyTypeOptions,
  breedOptions,
}: AddPetModalProps) => {
  const dogNamePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ.' -]*$/;
  const [step, setStep] = useState<AddPetStepper>(AddPetStepper.InputData);
  const [availableRecipes, setAvailableRecipes] = useState<null | any>(null);
  const [petPlan, setPetPlan] = useState<null | any>(null);
  const [dogName, setDogName] = useState('');
  const [selectedGender, setSelectedGender] = useState<Option>({
    value: '',
    label: 'Select gender',
  });
  const [isNeuteredOrSpayed, setIsNeuteredOrSpayed] = useState({
    value: '',
    label: 'Yes/No',
  });
  const [selectedBreed, setSelectedBreed] = useState<string[]>([]);
  const [selectedMonth, setSelectedMonth] = useState({ value: '', label: 'Select month' });
  const [selectedYear, setSelectedYear] = useState({ value: '', label: 'Select year' });
  const [selectedBodyType, setSelectedBodyType] = useState<any>({
    value: '',
    label: 'Select body type',
  });
  const [weight, setWeight] = useState('0');
  const [selectedLifestyle, setSelectedLifestyle] = useState<any>({
    value: '',
    label: 'Select lifestyle',
  });
  const [selectedHealthConditions, setSelectedHealthConditions] = useState<string[]>([]);
  const [mealSelected, setMealSelected] = useState(mealOptions[0]);
  const [recipeIds, setRecipeIds] = useState<string[]>([]);
  const [planSizeId, setPlanSizeId] = useState<null | string>(null);
  const [selectedDate, setSelectedDate] = useState(formatDate(new Date().toDateString()));
  const [isLoading, setIsLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handleDateChange = (date: any) => {
    setSelectedDate(date);
  };

  const onCreate = () => {
    createPetAndPetPlan(
      {
        dogName,
        weight,
        customerId: customer.id,
        isNeuteredOrSpayed: isNeuteredOrSpayed.value,
        selectedMonth: selectedMonth.value,
        selectedYear: selectedYear.value,
        selectedBodyType: selectedBodyType.value,
        selectedLifestyle: selectedLifestyle.value,
        selectedGender: selectedGender.value,
        selectedBreed,
        planSizeId,
        selectedHealthConditions,
      },
      petPlan,
      formatDateApi(selectedDate)
    );
    onConfirm();
  };

  const isEverythingRequiredSelected = useMemo(
    () =>
      !!selectedGender?.value &&
      !!isNeuteredOrSpayed?.value &&
      !!selectedYear?.value &&
      !!selectedBodyType?.value &&
      !!selectedLifestyle?.value &&
      +weight > 0 &&
      selectedBreed.length > 0 &&
      dogName.length > 0 &&
      dogNamePattern.test(dogName),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      selectedGender?.value,
      isNeuteredOrSpayed?.value,
      selectedYear?.value,
      selectedBodyType?.value,
      selectedLifestyle?.value,
      selectedBreed.length,
      weight,
      dogName.length,
      dogName,
    ]
  );

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 min-w-[550px] relative border">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        {step > 0 && (
          <>
            <div className="absolute top-1 left-2">
              <h3
                className="flex text-lg font-semibold text-left items-center cursor-pointer"
                onClick={() => {
                  setStep(step - 1);
                  setRecipeIds([]);
                }}
              >
                <div className="w-[20px] h-[28px] overflow-hidden relative">
                  <DownArrow className="absolute left-[-15px] top-[-2px] w-[40px] h-[40px] rotate-90" />
                </div>
                Back
              </h3>
            </div>
            <div className="w-1 h-3" />
          </>
        )}
        <h3 className="text-lg font-semibold text-left">Add a New Dog</h3>
        <p className="text-sm mb-2 text-left">
          <b>Customer</b>: {`${customer.first_name} ${customer.last_name} (${customer.email})`}
        </p>
        <hr />
        {step === AddPetStepper.InputData && (
          <div className="flex justify-left flex-col items-left">
            <div className="text-md font-semibold text-left">
              <h3>Dog name</h3>
            </div>
            <div className="w-full mb-2">
              <TextInput
                placeholder="Enter Dog's Name"
                value={dogName}
                setTextChange={setDogName}
              />
            </div>

            <div className="flex">
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Gender</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={genderOptions}
                    selectedOption={selectedGender}
                    onSelect={(newSelection) => setSelectedGender(newSelection)}
                  />
                </div>
              </div>

              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Neutered / Spayed</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={booleanOptions}
                    selectedOption={isNeuteredOrSpayed}
                    onSelect={(newSelection) => setIsNeuteredOrSpayed(newSelection)}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Breed</h3>
            </div>
            <div className="w-[515px] mb-2">
              <MultiSelectDropdown
                options={breedOptions}
                placeholder="Select breed"
                selectedOptions={selectedBreed}
                onSelect={setSelectedBreed}
              />
            </div>
            <div className="flex">
              {/* Birth Month */}
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Birth Month</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={monthOptions}
                    selectedOption={selectedMonth}
                    onSelect={(newSelection) => setSelectedMonth(newSelection)}
                  />
                </div>
              </div>

              {/* Birth Year */}
              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Birth Year</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={yearOptions}
                    selectedOption={selectedYear}
                    onSelect={(newSelection) => setSelectedYear(newSelection)}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Health Conditions</h3>
            </div>
            <div className="w-[515px] mb-2">
              <MultiSelectDropdown
                options={healthConditionOptions}
                selectedOptions={selectedHealthConditions}
                onSelect={setSelectedHealthConditions}
              />
            </div>

            <div className="flex">
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Body Type</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={bodyTypeOptions}
                    selectedOption={selectedBodyType}
                    onSelect={(newSelection) => setSelectedBodyType(newSelection)}
                  />
                </div>
              </div>

              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Lifestyle</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={lifestyleOptions}
                    selectedOption={selectedLifestyle}
                    onSelect={(newSelection) => setSelectedLifestyle(newSelection)}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Weight</h3>
            </div>
            <div className="w-1/2 mb-2 flex">
              <TextInput placeholder="Enter Weight" value={weight} setTextChange={setWeight} />
              <span className="ml-2 mt-2">pounds</span>
            </div>

            {/* CTA */}
            <div className="flex justify-center flex-col items-center mt-2">
              <Button
                variant={isEverythingRequiredSelected ? 'primary' : 'secondary'}
                label={isLoading ? <InlineSpinner /> : 'Continue'}
                isDisabled={!isEverythingRequiredSelected || isLoading}
                onClick={() => {
                  if (dogName === '') {
                    toast.error('Please enter dog name', BOTTOM_TOAST);
                  } else if (dogName && !dogNamePattern.test(dogName)) {
                    toast.error(
                      'Dog names may only contain uppercase and lowercase letters',
                      BOTTOM_TOAST
                    );
                  } else if (weight === '0' || weight === '') {
                    toast.error('Please enter dog weight', BOTTOM_TOAST);
                  } else if (weight.includes('.')) {
                    toast.error('Dog weight must be an integer', BOTTOM_TOAST);
                  } else {
                    setIsLoading(true);
                    axios
                      .get(
                        `calculator/plan_options?weight=${weight}&birth_year=${selectedYear.value}&birth_month=${selectedMonth.value}&body_type=${selectedBodyType.value}&neutered=${isNeuteredOrSpayed.value}&lifestyle=${selectedLifestyle.value}&account_id=${customer.id}`
                      )
                      .then((response) => {
                        const recipes = response.data.prices.unkibble
                          .concat(response.data.prices.fresh)
                          .flatMap((item: any) =>
                            item.subscription.product.recipes.map((recipe: any) => ({
                              ...recipe,
                              price: item.subscription.price,
                            }))
                          );
                        setPlanSizeId(response.data.plan_size.id);
                        setAvailableRecipes(recipes);
                        setIsLoading(false);
                        setStep(AddPetStepper.SelectPlan);
                      })
                      .catch((e) => {
                        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
                      });
                  }
                }}
              />
            </div>
          </div>
        )}
        {step === AddPetStepper.SelectPlan && availableRecipes && (
          <div className="flex justify-left flex-col items-left h-600">
            <div className="text-md font-semibold text-left">
              <h3>Dog name: {dogName}</h3>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Select Meal Type</h3>
            </div>
            <div className="w-32 mb-2">
              <Dropdown
                options={mealOptions}
                onSelect={(newSelection) => {
                  if (newSelection.value !== mealSelected.value) {
                    setRecipeIds([]);
                  }
                  setMealSelected(newSelection);
                }}
              />
            </div>

            {mealSelected.value === 'UNKIBBLE' && (
              <div className="min-h-[10em]">
                <div className="text-md font-semibold text-left">
                  <div className="text-lg">Select UnKibble recipes</div>
                  <div className="text-sm">Maximum 2 recipes</div>
                </div>
                <div className="w-full mb-2">
                  {availableRecipes
                    .filter((recipe: any) => recipe.type === 'UNKIBBLE')
                    .map((recipe: any) => (
                      <Checkbox
                        key={recipe.id}
                        label={`${recipe.name} ($${recipe.price.price_per_meal}/meal)`}
                        isChecked={recipeIds.includes(recipe.id)}
                        onChange={(isChecked) => {
                          if (isChecked) {
                            setRecipeIds([...recipeIds, recipe.id]);
                          } else {
                            setRecipeIds([...recipeIds.filter((id) => id !== recipe.id)]);
                          }
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
            {mealSelected.value === 'FRESH' && (
              <div className="min-h-[10em]">
                <div className="text-md font-semibold text-left">
                  <div className="text-lg">Select Fresh recipes</div>
                  <div className="text-sm">Maximum 3 recipes</div>
                </div>
                <div className="w-full mb-2">
                  {availableRecipes
                    .filter((recipe: any) => recipe.type === 'FRESH')
                    .map((recipe: any) => (
                      <Checkbox
                        key={recipe.id}
                        label={`${recipe.name} ($${recipe.price.price_per_meal}/meal)`}
                        isChecked={recipeIds.includes(recipe.id)}
                        onChange={(isChecked) => {
                          if (isChecked) {
                            setRecipeIds([...recipeIds, recipe.id]);
                          } else {
                            setRecipeIds([...recipeIds.filter((id) => id !== recipe.id)]);
                          }
                        }}
                      />
                    ))}
                </div>
              </div>
            )}
            {/* CTA */}
            <div className="flex justify-center flex-col items-center mt-2">
              <Button
                variant="primary"
                label={isLoading ? <InlineSpinner /> : 'Continue'}
                isDisabled={isLoading}
                onClick={() => {
                  setIsLoading(true);
                  axios
                    .post(`calculator/build_plan`, {
                      discounts: [],
                      plan_size: planSizeId,
                      recipes: recipeIds,
                      account_id: customer.id,
                    })
                    .then((response) => {
                      setPetPlan(response.data);
                      setIsLoading(false);
                      setStep(AddPetStepper.Review);
                    })
                    .catch((e) => {
                      setIsLoading(false);
                      toast.error(generateErrorMsg(e), BOTTOM_TOAST);
                    });
                }}
              />
            </div>
          </div>
        )}
        {step === AddPetStepper.Review && (
          <div className="flex justify-left flex-col items-left h-600">
            <div className="text-md font-semibold text-left">
              <h3>Dog name: {dogName}</h3>
            </div>

            <div className="text-md">
              <h3 className="text-md font-semibold">Meal Plan Summary</h3>
              {petPlan && (
                <>
                  <div className="text-sm">
                    <b>Meal Type</b>: {mealSelected.label}
                  </div>
                  <div className="text-sm">
                    <b>Meal Recipe(s)</b>:
                    {recipeIds
                      .map((recipeId: any) => {
                        const recipe = availableRecipes.find(
                          (availableRecipe: any) => availableRecipe.id === recipeId
                        );
                        return recipe.name;
                      })
                      .join(', ')}
                  </div>
                  <div className="text-sm">
                    <b>Product Code</b>: {petPlan?.subscription?.product?.code}
                  </div>
                  <div className="text-sm">
                    <b>Price</b>: ${petPlan?.subscription?.price?.price}
                  </div>
                  <div className="text-sm">
                    <b>Frequency</b>: Every {petPlan?.subscription?.product?.frequency} weeks
                  </div>
                  <div className="text-sm flex items-center">
                    <span className="mr-1">
                      <b>First Order Date</b>:
                    </span>
                    <DatePicker
                      selected={new Date()}
                      minDate={new Date()}
                      onChange={handleDateChange}
                      dateFormat="dd/MM/yyyy"
                      customInput={
                        <div className="flex items-center cursor-pointer">
                          <span>{selectedDate ? formatDate(selectedDate) : 'Select Date'}</span>
                          <Pencil className="w-4 h-4 ml-1 mb-1" />
                        </div>
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="flex justify-center flex-col items-center mt-2">
              <Button variant="primary" label="Add Dog" onClick={() => setShowConfirmModal(true)} />
            </div>
          </div>
        )}
      </div>
      {showConfirmModal && (
        <ConfirmationModal
          title={`Are you sure you want to proceed with adding ${dogName} to ${customer.first_name} ${customer.last_name}'s account?`}
          customMessage={
            <p className="text-sm mb-4 text-center text-red-600">
              For orders scheduled for current day, the customer's default credit card will be
              charged immediately after clicking "Confirm" below.
            </p>
          }
          confirmLabel="Confirm"
          onConfirm={onCreate}
          onCancel={() => setShowConfirmModal(false)}
        />
      )}
    </div>
  );
};
