import React, { useMemo, useState } from 'react';
import { toast } from 'react-toastify';

import { Button } from './Button';
import { ConfirmationModal } from './ConfirmationModal';
import { Dropdown } from './Dropdown';
import { MultiSelectDropdown } from './MultiSelectDropdown';
import { Table } from './Table';
import { TextInput } from './TextInput';
import { axios } from '../api';
import { Close } from '../assets/Close';
import { DownArrow } from '../assets/DownArrow';
import { InlineSpinner } from '../assets/Spinner';
import { booleanOptions, monthOptions, yearOptions, genderOptions } from '../dropdownOptions';
import { Customer } from '../pages/types';
import { updatePet, updatePetAndPetPlan } from '../petApiUtils';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

enum UpdatePetStepper {
  InputData,
  Review,
}

interface UpdatePetModalProps {
  customer: Customer;
  petId: string;
  onConfirm: () => void;
  onCancel: () => void;
  lifestyleOptions: any;
  bodyTypeOptions: any;
  breedOptions: any;
  healthConditionOptions: any;
}

export const UpdatePetModal = ({
  customer,
  petId,
  onConfirm,
  onCancel,
  lifestyleOptions,
  bodyTypeOptions,
  breedOptions,
  healthConditionOptions,
}: UpdatePetModalProps) => {
  const pet = useMemo(() => {
    return customer.pets.find((pet) => pet.id === petId);
  }, [customer.pets, petId]);
  const petplan = useMemo(() => {
    return customer.petplans.find((petplan: any) => petplan.pet === petId);
  }, [customer.petplans, petId]);
  const mealPlanProduct = useMemo(() => {
    return petplan.products.find((product: any) => product.product_type === 'MEAL');
  }, [petplan.products]);
  const previousWeight = pet?.properties?.weight;
  const previousNeuteredOrSpayedOption =
    booleanOptions.find((option) => option.value === pet?.properties?.neutered?.toString()) ??
    booleanOptions[0];
  const previousMonthOption =
    monthOptions.find((option) => option.value === pet?.birth_month?.toString()) ?? monthOptions[0];
  const previousYearOption =
    yearOptions.find((option) => option.value === pet?.birth_year?.toString()) ?? yearOptions[0];
  const previousLifestyleOption =
    lifestyleOptions.find(
      (option: any) => option.value === pet?.properties?.lifestyle?.toString()
    ) ?? lifestyleOptions[1];
  const previousBodyTypeOption =
    bodyTypeOptions.find(
      (option: any) => option.value === pet?.properties?.body_type?.toString()
    ) ?? bodyTypeOptions[1];
  const previousGenderOption =
    genderOptions.find((option) => option.value === pet?.gender?.toString()) ?? genderOptions[0];
  const previousHealthConditions = pet?.properties?.health_conditions;
  const previousBreedOptions = pet?.breeds ?? [];
  const dogNamePattern = /^[A-Za-zÀ-ÖØ-öø-ÿ.' -]*$/;
  const [step, setStep] = useState<UpdatePetStepper>(UpdatePetStepper.InputData);
  const [dogName, setDogName] = useState(pet?.name);
  const [isNeuteredOrSpayed, setIsNeuteredOrSpayed] = useState(previousNeuteredOrSpayedOption);
  const [selectedMonth, setSelectedMonth] = useState(previousMonthOption);
  const [selectedYear, setSelectedYear] = useState(previousYearOption);
  const [selectedBodyType, setSelectedBodyType] = useState(previousBodyTypeOption);
  const [weight, setWeight] = useState(previousWeight);
  const [selectedLifestyle, setSelectedLifestyle] = useState(previousLifestyleOption);
  const [isLoading, setIsLoading] = useState(false);
  const [recommendedMealPlan, setRecommendedMealPlan] = useState<any | null>(null);
  const [showCurrentPlanConfirmModal, setShowCurrentPlanConfirmModal] = useState(false);
  const [showRecommendedPlanConfirmModal, setShowRecommendedPlanConfirmModal] = useState(false);
  const [selectedBreed, setSelectedBreed] = useState<string[]>(previousBreedOptions);
  const [selectedGender, setSelectedGender] = useState(previousGenderOption);
  const [selectedHealthConditions, setSelectedHealthConditions] =
    useState<string[]>(previousHealthConditions);
  const possessiveAdj = pet?.gender === 'MALE' ? 'his' : 'her';

  const onKeepCurrentPlan = () => {
    updatePet({
      petId,
      dogName,
      weight,
      customerId: customer.id,
      isNeuteredOrSpayed: isNeuteredOrSpayed.value,
      recommended_calories_day: recommendedMealPlan.plan_size,
      selectedMonth: selectedMonth.value,
      selectedYear: selectedYear.value,
      selectedBodyType: selectedBodyType.value,
      selectedLifestyle: selectedLifestyle.value,
      selectedHealthConditions,
      breeds: selectedBreed,
      gender: selectedGender.value,
    });
    onConfirm();
  };

  const onUpdateToRecommendedPlan = () => {
    updatePetAndPetPlan({
      petId,
      dogName,
      weight,
      customerId: customer.id,
      isNeuteredOrSpayed: isNeuteredOrSpayed.value,
      recommended_calories_day: recommendedMealPlan.plan_size,
      selectedMonth: selectedMonth.value,
      selectedYear: selectedYear.value,
      selectedBodyType: selectedBodyType.value,
      selectedLifestyle: selectedLifestyle.value,
      selectedHealthConditions,
      productCode: recommendedMealPlan?.subscription?.product?.code,
      price: recommendedMealPlan?.subscription?.price?.price,
      frequency: recommendedMealPlan?.subscription?.product?.frequency,
      petPlanId: petplan.id,
      mealPlanProductId: mealPlanProduct.id,
      breeds: selectedBreed,
      gender: selectedGender.value,
    });
    onConfirm();
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center z-50 modal">
      <div className="bg-white rounded-lg shadow-md p-4 min-w-[550px] relative border">
        <Close className="absolute top-1 right-1 w-8 h-8 cursor-pointer" onClick={onCancel} />
        {step === UpdatePetStepper.Review && (
          <>
            <div className="absolute top-1 left-2">
              <h3
                className="flex text-lg font-semibold text-left items-center cursor-pointer"
                onClick={() => setStep(UpdatePetStepper.InputData)}
              >
                <div className="w-[20px] h-[28px] overflow-hidden relative">
                  <DownArrow className="absolute left-[-15px] top-[-2px] w-[40px] h-[40px] rotate-90" />
                </div>
                Back
              </h3>
            </div>
            <div className="w-1 h-3" />
          </>
        )}
        <h3 className="text-lg font-semibold text-left">Update Dog</h3>
        <p className="text-sm mb-2 text-left">
          <b>Customer</b>: {`${customer.first_name} ${customer.last_name} (${customer.email})`}
        </p>
        <hr />
        {step === UpdatePetStepper.InputData && (
          <div className="flex justify-left flex-col items-left">
            <div className="text-md font-semibold text-left">
              <h3>Dog name</h3>
            </div>
            <div className="w-full mb-2">
              <TextInput
                value={dogName}
                placeholder="Enter Dog's Name"
                setTextChange={setDogName}
              />
            </div>

            <div className="flex">
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Gender</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={genderOptions}
                    onSelect={(newSelection) => setSelectedGender(newSelection)}
                    selectedOption={selectedGender}
                  />
                </div>
              </div>

              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Neutered / Spayed</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={booleanOptions}
                    onSelect={(newSelection) => setIsNeuteredOrSpayed(newSelection)}
                    selectedOption={isNeuteredOrSpayed}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Breed</h3>
            </div>
            <div className="w-[515px] mb-2">
              <MultiSelectDropdown
                options={breedOptions}
                placeholder="Select breed"
                selectedOptions={selectedBreed}
                onSelect={setSelectedBreed}
              />
            </div>

            <div className="flex">
              {/* Birth Month */}
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Birth Month</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={monthOptions}
                    onSelect={(newSelection) => setSelectedMonth(newSelection)}
                    selectedOption={selectedMonth}
                  />
                </div>
              </div>

              {/* Birth Year */}
              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Birth Year</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={yearOptions}
                    onSelect={(newSelection) => setSelectedYear(newSelection)}
                    selectedOption={selectedYear}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Health Conditions</h3>
            </div>
            <div className="w-[515px] mb-2">
              <MultiSelectDropdown
                options={healthConditionOptions}
                selectedOptions={selectedHealthConditions}
                onSelect={setSelectedHealthConditions}
              />
            </div>

            <div className="flex">
              <div className="w-1/2 pr-2">
                <div className="text-md font-semibold text-left">
                  <h3>Body Type</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={bodyTypeOptions}
                    onSelect={(newSelection) => setSelectedBodyType(newSelection)}
                    selectedOption={selectedBodyType}
                  />
                </div>
              </div>

              <div className="w-1/2 pl-2">
                <div className="text-md font-semibold text-left">
                  <h3>Lifestyle</h3>
                </div>
                <div className="w-full mb-2">
                  <Dropdown
                    options={lifestyleOptions}
                    onSelect={(newSelection) => setSelectedLifestyle(newSelection)}
                    selectedOption={selectedLifestyle}
                  />
                </div>
              </div>
            </div>

            <div className="text-md font-semibold text-left">
              <h3>Weight</h3>
            </div>
            <div className="w-1/2 mb-2 flex">
              <TextInput
                value={weight ? weight.toString() : ''}
                placeholder="Enter Weight"
                setTextChange={(newValue) => setWeight(parseFloat(newValue) || 0)}
              />
              <span className="ml-2 mt-2">pounds</span>
            </div>

            {/* CTA */}
            <div className="flex justify-center flex-col items-center mt-2">
              <Button
                variant="primary"
                label={isLoading ? <InlineSpinner /> : 'Continue'}
                isDisabled={isLoading}
                onClick={() => {
                  if (dogName === '') {
                    toast.error('Please enter dog name', BOTTOM_TOAST);
                  } else if (dogName && !dogNamePattern.test(dogName)) {
                    toast.error(
                      'Dog names may only contain uppercase and lowercase letters',
                      BOTTOM_TOAST
                    );
                  } else if (weight.toString() === '0' || weight.toString() === '') {
                    toast.error('Please enter dog weight', BOTTOM_TOAST);
                  } else if (weight.toString().includes('.')) {
                    toast.error('Dog weight must be an integer', BOTTOM_TOAST);
                  } else {
                    setIsLoading(true);
                    axios
                      .get(
                        `calculator/plan_options?weight=${weight}&birth_year=${selectedYear.value}&birth_month=${selectedMonth.value}&body_type=${selectedBodyType.value}&neutered=${isNeuteredOrSpayed.value}&lifestyle=${selectedLifestyle.value}&account_id=${customer.id}`
                      )
                      .then((planOptionsResponse) => {
                        axios
                          .post(`calculator/build_plan`, {
                            discounts: [],
                            plan_size: planOptionsResponse.data.plan_size.id,
                            recipes: mealPlanProduct.recipes.map((recipe: any) => recipe.id),
                            account_id: customer.id,
                          })
                          .then((buildPlanResponse) => {
                            setIsLoading(false);
                            setRecommendedMealPlan(buildPlanResponse.data);
                            setStep(UpdatePetStepper.Review);
                          })
                          .catch((e) => {
                            setIsLoading(false);
                            toast.error(generateErrorMsg(e), BOTTOM_TOAST);
                          });
                      })
                      .catch((e) => {
                        setIsLoading(false);
                        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
                      });
                  }
                }}
              />
            </div>
          </div>
        )}
        {step === UpdatePetStepper.Review && (
          <div className="flex justify-left flex-col items-left h-600">
            <div className="text-md text-left">
              <h3>
                <b>Dog name</b>: {dogName}
              </h3>
              <h3 className="mt-2">
                <b>Dog Properties</b>
              </h3>
              <Table
                header={['', 'Previous', 'New']}
                data={[
                  {
                    '': 'Gender',
                    Previous: `${previousGenderOption?.label}`,
                    New: `${selectedGender?.label}`,
                  },
                  {
                    '': 'Neutered/Spayed?',
                    Previous: `${previousNeuteredOrSpayedOption?.label}`,
                    New: `${isNeuteredOrSpayed?.label}`,
                  },
                  {
                    '': 'Birth Month + Year',
                    Previous: `${previousMonthOption?.label} ${previousYearOption?.label}`,
                    New: `${selectedMonth?.label} ${selectedYear?.label}`,
                  },
                  {
                    '': 'Health Conditions',
                    Previous: `${previousHealthConditions.join(', ')}`,
                    New: `${selectedHealthConditions.join(', ')}`,
                  },
                  {
                    '': 'Body Type',
                    Previous: `${previousBodyTypeOption?.label}`,
                    New: `${selectedBodyType?.label}`,
                  },
                  {
                    '': 'Weight',
                    Previous: `${previousWeight}`,
                    New: `${weight}`,
                  },
                  {
                    '': 'Lifestyle',
                    Previous: `${previousLifestyleOption?.label}`,
                    New: `${selectedLifestyle?.label}`,
                  },
                ]}
              />
              <h3 className="mt-2">
                <b>Meal Plan</b>
              </h3>
              <Table
                header={['', 'Current', 'Recommended']}
                data={[
                  {
                    '': 'Price',
                    Current: `$${mealPlanProduct?.unit_price}`,
                    Recommended: `$${recommendedMealPlan?.subscription?.price?.price}`,
                  },
                  {
                    '': 'Code',
                    Current: `${mealPlanProduct?.code}`,
                    Recommended: `${recommendedMealPlan?.subscription?.product?.code}`,
                  },
                  {
                    '': 'Description',
                    Current: <div className="w-[175px]">{mealPlanProduct?.name}</div>,
                    Recommended: (
                      <div className="w-[175px]">
                        {recommendedMealPlan?.subscription?.product?.name}
                      </div>
                    ),
                  },
                ]}
              />
            </div>
            <div className="flex justify-center items-center space-x-4 mt-5">
              <Button
                variant="secondary"
                label="Keep Current Plan"
                onClick={() => setShowCurrentPlanConfirmModal(true)}
              />
              <Button
                variant="primary"
                label="Update to Recommended Plan"
                onClick={() => setShowRecommendedPlanConfirmModal(true)}
              />
            </div>
          </div>
        )}
      </div>
      {showCurrentPlanConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed?"
          message={`By clicking "Confirm" below, changes to ${dogName}'s profile will be saved but ${possessiveAdj} meal plan will remain the same.`}
          confirmLabel="Confirm"
          onConfirm={onKeepCurrentPlan}
          onCancel={() => setShowCurrentPlanConfirmModal(false)}
        />
      )}
      {showRecommendedPlanConfirmModal && (
        <ConfirmationModal
          title="Are you sure you want to proceed?"
          message={`By clicking "Confirm" below, changes to ${dogName}'s profile will be saved and ${possessiveAdj} meal plan will be updated to match the new recommended plan.`}
          confirmLabel="Confirm"
          onConfirm={onUpdateToRecommendedPlan}
          onCancel={() => setShowRecommendedPlanConfirmModal(false)}
        />
      )}
    </div>
  );
};
