import { Params, useParams } from 'react-router-dom';

import { customerDataFromParamsUtils } from '../customerDataFromParamsUtils';

// Get correct customerId from Params
export const useCustomParams = () => {
  const params = useParams();

  const customerData = customerDataFromParamsUtils(params?.customerId);
  const other = customerData ? { customerId: customerData[0] } : {};

  return {
    ...params,
    ...other,
  } as Readonly<Params<string>>;
};
