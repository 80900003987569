export const CustomerRefundsHeader = [
  'Product Code',
  'Product Description',
  'Pet Name',
  'Price Paid (after discount)',
  'Already Refunded',
  'Available for Refund',
  'Amount to Refund',
  'Total Refund Including Tax',
];

export const SplitFulfillmentHeaders = [
  'Fulfillment ID',
  'Pet Name',
  'Product Code',
  'Product Description',
];

export const ReplacementOrderHeader = [
  'Pet Name',
  'Quantity',
  'Product Code',
  'Product Description',
];

export const ProductsTableHeader = [
  'Pet Name',
  'Product Code',
  'Product Description',
  'Price',
  'Price with Tax',
];

export const FulfillmentHeader = [
  'Fulfillment ID',
  'Fulfillment Status',
  'Shipment Tracking Number(s)',
  'Pet(s)',
  'Product Code',
  'Product Description',
  'Price',
  'Price with Tax',
];

export const CustomerOrdersHeader = [
  'Order ID',
  'Order Status',
  'Order Date',
  'Order Type',
  'Order Total',
  'Pet(s)',
  'Payment Status',
  'Shipping Address',
];

export const CustomerPlansHeader = [
  'Pet Status',
  'Pet Name',
  'Pet Gender',
  'Pet ID',
  'Product Code',
  'Frequency',
  'Product Description',
  'Topper Plan',
  'Cost',
  'Next Order',
];

export const CustomerSearchHeaders = [
  'Name',
  'Email',
  'Pet Names',
  'Status',
  'Account Created',
  'Last Updated',
  'Primary Shipping Address',
];

export const UserManagementHeaders = [
  'Name',
  'Email',
  'User Group',
  'Added',
  'Last Updated',
  'Status',
];

export const DiscountsHeader = [
  'Discount Code',
  'Discount Type',
  'Value',
  'Start date',
  'End date',
  'Limit type',
  'Limit value',
  'Order Types',
  'Status',
];

export const PromotionsHeader = ['Promotion', 'Status', 'Start date', 'End date', 'Free Product'];

export const ItemsHeader = ['Item SKU', 'Name', 'Weight (oz)', 'Volume', 'Recipe ID'];

export const ProductItemsHeader = ['Products', 'Product Name', 'Product Status', 'Items'];
