import React, { useEffect, useMemo, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';

import {
  DiscountLimitType,
  disabledBackgroundGray,
  discountTypesInfo,
  limitTypesList,
} from './constants';
import { axios } from '../api';
import { Badge } from '../component/Badge';
import { ChangeDiscountStatus } from '../component/ChangeDiscountStatus';
import { Dropdown } from '../component/Dropdown';
import { TextInput } from '../component/TextInput';
import { LargeTooltip } from '../component/Tooltip';
import { formatDateTime } from '../dateUtils';

interface Usage {
  session: number;
  account: number;
  order: number;
  charged: number;
}

interface DiscountFormValues {
  id: string;
  code: string;
  discount_type: string;
  value: number;
  trial_value: number;
  created_by: string;
  description: string;
  limit_type: string;
  limit_value: number;
  available_start_date: string;
  available_end_date: string;
  is_active: boolean;
  order_trial: boolean;
  order_subscription: boolean;
  order_onetime: boolean;
  eligible_products: string[];
  usage: Usage;
}

type UpdateDiscountProps = {
  isStatusEditable: boolean;
};

export const UpdateDiscount = ({ isStatusEditable }: UpdateDiscountProps) => {
  const limitTypes = useMemo(() => limitTypesList, []);

  const { discountId } = useParams<{ discountId: string }>();
  const [data, setData] = useState<DiscountFormValues>();
  const [loading, setLoading] = useState(true);
  const limitType = limitTypes.find((element) => element.value === data?.limit_type);

  const orderTypesDisplay = [
    `${data?.order_trial ? '✅' : '❌'} Trial`,
    `${data?.order_subscription ? '✅' : '❌'} Subscription`,
    `${data?.order_onetime ? '✅' : '❌'} Onetime`,
  ];

  const fetchData = () => {
    axios
      .get(`discounts/${discountId}`)
      .then((response) => {
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => console.error('Error:', error));
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="flex justify-center items-center justify-self-start bg-slate50 p-8">
      <div className="bg-lightGray rounded-lg shadow-md p-4 w-[750px] relative border font-semibold">
        <h1 className="text-left">Update discount</h1>
        <div className="bg-white mt-4 mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border padd">
          <div className="text-md text-left">
            <div className="w-full mb-2">
              <h2>Discount code</h2>
              <TextInput
                placeholder="Enter code"
                value={data?.code}
                bottomText="Customer-facing discount code (e.g. WELCOME20)"
                disabled
              />
            </div>

            <div className="w-full mb-2">
              <h2>
                Description <span className="text-xs">(Optional)</span>
              </h2>
              <TextInput
                value={data?.description}
                bottomText="Internal description of discount code"
                disabled
              />
            </div>

            <div className="w-full mb-2">
              <div className="flex flex-row justify-between">
                <div className="flex-1 mr-4">
                  <h2>Status</h2>
                  <div className="flex">
                    {isStatusEditable && data && (
                      <ChangeDiscountStatus
                        isActive={data.is_active}
                        discountId={data.id}
                        discountCode={data.code}
                        reFetch={fetchData}
                      />
                    )}
                    {!isStatusEditable && data && (
                      <div className="flex justify-between items-center">
                        <div className="inline">
                          {data.is_active ? (
                            <span className="bg-green-300  px-2 py-0.5 rounded">Active</span>
                          ) : (
                            <span className="bg-red-400  px-2 py-0.5 rounded">Inactive</span>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="flex-1">
                  <h2>Creator</h2>
                  <Badge label={data?.created_by ?? 'n/a'} />
                </div>
              </div>
            </div>
            <div className="w-full mb-2">
              <h2>Usage</h2>
              <div className="px-2 font-normal">
                <ul className="list-disc list-inside">
                  <li>Used by {data?.usage.account} customers</li>
                  <li>Used on {data?.usage.charged} charged order</li>
                  <li>Applied to {data?.usage.order} upcoming orders</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>
            Active dates <span className="text-xs">(Optional)</span>
          </h2>
          <p className="text-xs text-gray-500 font-normal">
            The customer will only receive the discount if applied within this time frame
          </p>

          <div className="flex mt-2">
            <div className="w-1/2 pr-2">
              <h3>Start Date</h3>
              <div className="w-full mb-2">
                <span>
                  {data?.available_start_date
                    ? formatDateTime(data?.available_start_date, true)
                    : 'Not set'}
                </span>
              </div>
            </div>
            <div className="w-1/2">
              <h3>End Date</h3>
              <div className="w-full mb-2">
                <span>
                  {data?.available_end_date
                    ? formatDateTime(data?.available_end_date, true)
                    : 'Not set'}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <div className="w-full pr-2 mb-2">
            <h2 className="flex">
              Discount limit type
              <LargeTooltip textArray={discountTypesInfo} />
            </h2>

            <Dropdown
              options={limitTypes}
              selectedOption={limitType}
              isDisabled
              onSelect={() => null}
            />
          </div>
          {limitType?.needsValue && (
            <>
              <h2>Discount limit</h2>
              {limitType?.value ===
              DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT ? (
                <>
                  <div className="flex">
                    <div className="w-1/4 mb-2">
                      <div>
                        <TextInput placeholder="Enter value" value="1" type="number" disabled />
                      </div>
                    </div>
                    <div className="w-3/4 items-center flex">
                      <span className="ml-2">Trial Order</span>
                    </div>
                  </div>

                  <div className="flex">
                    <div className="w-1/4">
                      <div>
                        <TextInput
                          placeholder="Enter value"
                          value={data?.limit_value.toString()}
                          type="number"
                          disabled
                        />
                      </div>
                    </div>
                    <div className="w-3/4 items-center flex">
                      <span className="ml-2">{limitType?.valueText ?? ''}</span>
                    </div>
                  </div>
                  <p className="mt-1 text-xs text-gray font-normal">
                    {limitType?.valueBottomText ?? ''}
                  </p>
                </>
              ) : (
                <div className="flex">
                  <div className="w-1/4 mb-2 items-center">
                    <div>
                      <TextInput value={data?.limit_value.toString()} type="number" disabled />
                    </div>
                  </div>
                  <div className="pt-6 w-3/4 mb-2 flex items-center">
                    <span className="flex ml-2 align-bottom">{limitType?.valueText}</span>
                  </div>
                </div>
              )}
            </>
          )}
        </div>

        <div className="bg-white mb-4 rounded-lg shadow-md p-4 min-w-[550px] relative border">
          <h2>Discount value</h2>
          {limitType?.value === DiscountLimitType.X_ORDERS_PER_CUSTOMER_SUBSCRIPTION_TRIAL_SPLIT ? (
            <>
              <div className="flex">
                <div className="w-2/5 mb-2 pr-2">
                  <div className="w-full mb-2">
                    <TextInput value={data?.discount_type} type="text" disabled />
                  </div>
                </div>
                <div className="w-1/5">
                  <div className="w-full mb-2">
                    <TextInput value={data?.trial_value.toString()} type="number" disabled />
                  </div>
                </div>
                <div className="w-2/5 items-center flex">
                  <span className="ml-2">off of Trial Order</span>
                </div>
              </div>

              <div className="flex">
                <div className="w-2/5 mb-2 pr-2">
                  <div className="w-full mb-2">
                    <TextInput value={data?.discount_type} type="text" disabled />
                  </div>
                </div>
                <div className="w-1/5">
                  <div className="w-full mb-2">
                    <TextInput value={data?.value.toString()} type="number" disabled />
                  </div>
                </div>
                <div className="w-2/5 items-center flex">
                  <span className="ml-2">off of Subscription Order(s)</span>
                </div>
              </div>
            </>
          ) : (
            <div className="flex">
              <div className="w-3/5 mb-2 pr-2">
                <div className="w-full mb-2">
                  <TextInput value={data?.discount_type} type="text" disabled />
                </div>
              </div>

              <div className="w-2/5">
                <div className="w-full mb-2">
                  <TextInput value={data?.value.toString()} type="number" disabled />
                </div>
              </div>
            </div>
          )}

          <div className="mb-2">
            <h2 className="mb-2">Eligible order types</h2>
            <ul>
              {orderTypesDisplay.map((listItem: string, listIndex: number) => (
                <li key={String(listIndex)} className="whitespace-nowrap text-left">
                  {listItem}
                </li>
              ))}
            </ul>
          </div>
          <h2>Eligible products</h2>

          <div className="py-2">
            {data?.eligible_products && data.eligible_products.length === 0 ? (
              <p>Discount will be applicable to all products</p>
            ) : (
              <div>
                Selected products
                <p className="text-xs text-gray-500 font-normal">
                  Discount will only be applicable to select list of products
                </p>
                {data?.eligible_products && data.eligible_products.length !== 0 && (
                  <div>
                    <div
                      className={`overflow-y-auto text-xxs rounded-md border border-black ${disabledBackgroundGray} h-72 w-2/5`}
                    >
                      {data.eligible_products.map((value, index) => (
                        <div className="text-center" key={value}>
                          <div>
                            <button
                              key={value}
                              value={value}
                              className="px-2 py-1 items-center flex justify-between w-full text-left"
                            >
                              <span>{value}</span>
                            </button>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
