import React, { useMemo, useState } from 'react';

import { capitalize } from '../capitalize';

type OrderSummaryProps = {
  summary: any;
  setDiscountCodes: any;
  discountCodes: string[];
};

export const OrderSummary = ({ summary, discountCodes, setDiscountCodes }: OrderSummaryProps) => {
  const {
    summaryProducts: { products, doesExist },
  } = summary;

  const [showDiscountForm, setShowDiscountForm] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState('');

  const toggleDiscountForm = () => {
    setDiscountCode('');
    setShowDiscountForm((prev) => !prev);
  };

  const handleDiscountChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDiscountCode(event.target.value.toUpperCase());
  };

  const actionWithDiscountCode = (action: 'APPLY' | 'REMOVE') => {
    if (action === 'APPLY') {
      setShowDiscountForm(false);
      setDiscountCodes([discountCode]);
    } else {
      setDiscountCode('');
      setDiscountCodes([]);
    }
  };

  // Show existed products in order if existingOrderProducts was passed in useGetOrderSummary hook (Grouped by pet)
  const productsViews = useMemo(() => {
    const pets = Object.keys(products);
    return pets.map((petName, index) => {
      const productTypes = Object.keys(products[petName]);
      return (
        <div key={petName + index}>
          {index !== 0 && <h2 className="font-bold mb-2 text-center">{petName}</h2>}
          {productTypes.map((type: string, index: number) => {
            return (
              <div key={type + index}>
                <span className="font-semibold">{capitalize(type) + 's'}</span>
                {products[petName][type].map((product: any, index: number) => (
                  <div
                    key={product.product_code + index}
                    className="pt-1 pb-1 flex items-center justify-between"
                  >
                    {product.product_code}
                    <div className="font-semibold">{`$${product.unit_price}`}</div>
                  </div>
                ))}
                <div className="w-full h-[1px] bg-lightGray mt-2 mb-3" />
              </div>
            );
          })}
        </div>
      );
    });
  }, [products]);

  return (
    <>
      {doesExist && productsViews}
      <div className="w-full mb-2 flex justify-between">
        <span className="font-semibold">Subtotal</span>
        <span className="font-semibold">${summary?.subtotal_price ?? '0.00'}</span>
      </div>
      <div className="w-full mb-2 flex justify-between items-center">
        <span className="font-semibold">Discount</span>
        <div>
          {!showDiscountForm ? (
            <>
              {discountCodes[0] ? (
                <div className="flex">
                  <a
                    className="cursor-pointer border border-black rounded-lg pl-[10px] pr-[8px] flex items-center"
                    onClick={() => actionWithDiscountCode('REMOVE')}
                  >
                    {discountCodes[0]} <span className="text-red-600 ml-2">X</span>
                  </a>
                  {summary?.total_discounts && (
                    <span className="font-bold ml-[10px]">-${summary?.total_discounts}</span>
                  )}
                </div>
              ) : (
                <a className="underline cursor-pointer" onClick={toggleDiscountForm}>
                  Add a discount code
                </a>
              )}
            </>
          ) : (
            <div className="flex items-center">
              <input
                type="text"
                placeholder="Enter discount code"
                className="border border-black rounded pl-2"
                value={discountCode}
                onChange={handleDiscountChange}
              />
              <a
                className="cursor-pointer underline font-semibold pl-[10px] pr-[8px]"
                onClick={() => actionWithDiscountCode('APPLY')}
              >
                APPLY
              </a>
              <a
                className="cursor-pointer text-red-600 pl-[10px] pr-[8px]"
                onClick={toggleDiscountForm}
              >
                X
              </a>
            </div>
          )}
        </div>
      </div>
      <div className="w-full mb-2 flex justify-between">
        <span className="font-semibold">Shipping</span>
        <span className="font-semibold">$0.00</span>
      </div>
      <div className="w-full mb-2 flex justify-between">
        <span className="font-semibold">Estimated Tax</span>
        <span className="font-semibold">${summary?.total_tax ?? '0.00'}</span>
      </div>
      <div className="w-full h-[1px] bg-lightGray mt-2 mb-3" />
      <div className="w-full mb-2 flex justify-between">
        <span className="font-bold">Total</span>
        <span className="font-bold">${summary?.total_price ?? '0.00'}</span>
      </div>
    </>
  );
};
