import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { API_URL } from '../api';
import { Button } from '../component/Button';
import { TextInput } from '../component/TextInput';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const ResetPassword = () => {
  const { tokenId, tokenKey } = useParams();
  const [password, setPassword] = useState('');
  const [passwordConfirmation, setPasswordConfirmation] = useState('');

  const navigate = useNavigate();

  const handleResetPassword = () => {
    axios
      .post(
        `${API_URL()}/reset_password`,
        {
          password,
          password_confirm: passwordConfirmation,
          reset_token_id: tokenId,
          reset_token_key: tokenKey,
        },
        {
          headers: {
            'x-application': 'BARK',
          },
        }
      )
      .then(() => {
        localStorage.removeItem('token');
        navigate('/login');
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex h-screen items-center justify-center bg-slate50">
      <div className="grid gap-2 min-w-[300px]">
        <div className="flex justify-center mb-5">
          <img src={require('../assets/fullBarkLogo.png')} />
        </div>
        <TextInput variant="password" label="New Password" setTextChange={setPassword} />
        <TextInput
          variant="password"
          label="Confirm Password"
          setTextChange={setPasswordConfirmation}
        />
        <div className="flex justify-center mt-5">
          <Button label="Reset Password" onClick={handleResetPassword} />
        </div>
      </div>
    </div>
  );
};
