import React from 'react';
import QRCode from 'react-qr-code';

interface TOTPQRCodeProps {
  configUrl: string;
}

export const TOTPQRCode = ({ configUrl }: TOTPQRCodeProps) => {
  if (!configUrl) {
    return <p>Error: No configuration URL provided!</p>;
  }
  return (
    <div className="flex flex-col p-2 items-center justify-center">
      <h2 className="p-2">Scan this QR code with your authenticator app</h2>
      <QRCode value={configUrl} size={256} />
    </div>
  );
};
