import React, { useState } from 'react';

import { Button } from './Button';

function not(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) === -1);
}

function intersection(a: any, b: any) {
  return a.filter((value: any) => b.indexOf(value) !== -1);
}

export const TransferList = ({
  availableProducts,
  setAvailableProducts,
  selectedProducts,
  setSelectedProducts,
}: {
  availableProducts: string[];
  setAvailableProducts: React.Dispatch<React.SetStateAction<string[]>>;
  selectedProducts: string[];
  setSelectedProducts: React.Dispatch<React.SetStateAction<string[]>>;
}) => {
  const [checked, setChecked] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const filteredProducts = availableProducts.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const leftChecked = intersection(checked, filteredProducts);
  const rightChecked = intersection(checked, selectedProducts);

  const handleToggle = (event: any, value: any, isRight: boolean) => {
    const newChecked = [...checked];
    const currentChecked = isRight ? rightChecked : leftChecked;

    if (!event.altKey && !event.metaKey && !event.ctrlKey && !event.shiftKey) {
      newChecked.length = 0;
    }
    const currentIndex = currentChecked.indexOf(value);
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const handleKeyDown = (event: any, index: any, isRight: boolean) => {
    const currentProducts = isRight ? selectedProducts : filteredProducts;

    if (event.key === 'ArrowDown') {
      handleToggle(event, currentProducts[index], isRight);
      const nextIndex = index + 1;
      handleToggle(event, currentProducts[nextIndex], isRight);
      const nextButton = document.querySelector(`button[value="${currentProducts[nextIndex]}"]`);
      nextButton && (nextButton as HTMLButtonElement).focus();
      if (
        event.shiftKey &&
        currentProducts[nextIndex] &&
        !checked.includes(currentProducts[nextIndex])
      ) {
        handleToggle(event, currentProducts[nextIndex], isRight);
      }
    } else if (event.key === 'ArrowUp') {
      handleToggle(event, currentProducts[index], isRight);
      const prevIndex = index - 1;
      handleToggle(event, currentProducts[prevIndex], isRight);
      const prevButton = document.querySelector(`button[value="${currentProducts[prevIndex]}"]`);
      prevButton && (prevButton as HTMLButtonElement).focus();
      if (
        event.shiftKey &&
        currentProducts[prevIndex] &&
        !checked.includes(currentProducts[prevIndex])
      ) {
        handleToggle(event, currentProducts[prevIndex], isRight);
      }
    }
  };

  const handleAllRight = () => {
    setSelectedProducts(selectedProducts.concat(filteredProducts));
    setAvailableProducts(not(availableProducts, filteredProducts));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedRight = () => {
    setSelectedProducts(selectedProducts.concat(leftChecked));
    setAvailableProducts(not(availableProducts, leftChecked));
    setChecked(not(checked, leftChecked));
  };

  const handleCheckedLeft = () => {
    setAvailableProducts(availableProducts.concat(rightChecked));
    setSelectedProducts(not(selectedProducts, rightChecked));
    setChecked(not(checked, rightChecked));
  };

  const handleAllLeft = () => {
    setAvailableProducts(availableProducts.concat(selectedProducts));
    setSelectedProducts([]);
    setChecked(not(checked, selectedProducts));
  };

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const leftListDisplay = (
    <div className="text-xxs rounded-md border border-black bg-secondary h-72 overflow-y-auto">
      <div className="flex py-2 mr-2 ml-1">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-5 text-gray-600"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          placeholder="Filter"
          className="w-full border border-black rounded px-2 py-1"
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
      </div>
      <div className="border-t">
        {filteredProducts.map((value, index) => (
          <button
            key={value}
            value={value}
            onClick={(event) => handleToggle(event, value, false)}
            onKeyDown={(event) => handleKeyDown(event, index, false)}
            className={`px-2 py-1 items-center flex justify-between w-full text-left ${
              leftChecked.indexOf(value) !== -1 ? 'bg-indigo-600 text-white' : ''
            }`}
          >
            <span>{value}</span>
          </button>
        ))}
      </div>
    </div>
  );
  const rightListDisplay = (
    <div className="overflow-y-auto text-xxs rounded-md border border-black bg-secondary h-72">
      {selectedProducts.map((value, index) => (
        <div className="text-center" key={value}>
          <div>
            <button
              key={value}
              value={value}
              onClick={(event) => handleToggle(event, value, true)}
              onKeyDown={(event) => handleKeyDown(event, index, true)}
              className={`px-2 py-1 items-center flex justify-between w-full text-left ${
                rightChecked.indexOf(value) !== -1 ? 'bg-indigo-600 text-white' : ''
              }`}
            >
              <span>{value}</span>
            </button>
          </div>
        </div>
      ))}
    </div>
  );

  return (
    <div className="flex">
      <div className="w-2/5">
        Available products
        {leftListDisplay}
        <div className="flex flex-col justify-center">
          <Button
            isDisabled={filteredProducts.length === 0}
            className="text-blue-500 hover:underline bg-transparent border-none transform scale-75"
            variant="secondary"
            label="Select all &#8594;"
            onClick={() => {
              handleAllRight();
            }}
          />
        </div>
      </div>
      <div className="w-1/5 transform scale-50 items-center">
        <div className="flex flex-col justify-center text-lg">
          <Button
            isDisabled={leftChecked.length === 0}
            className="py-1"
            variant="primary"
            label="&#8594;"
            onClick={() => {
              handleCheckedRight();
            }}
          />
          <br />
          <Button
            isDisabled={rightChecked.length === 0}
            className="py-1"
            variant="primary"
            label="&#8592;"
            onClick={() => {
              handleCheckedLeft();
            }}
          />
        </div>
      </div>
      <div className="w-2/5 items-center">
        Selected products
        {rightListDisplay}
        <div className="flex flex-col justify-center">
          <Button
            isDisabled={selectedProducts.length === 0}
            className="text-blue-500 hover:underline bg-transparent border-none transform scale-75"
            variant="secondary"
            label="&#8592; Remove all"
            onClick={() => {
              handleAllLeft();
            }}
          />
        </div>
      </div>
    </div>
  );
};
