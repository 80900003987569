import './css/tailwind.css';
import React from 'react';
import * as ReactDOMClient from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import BarkApp from './BarkRouter';

const rootElement = document.getElementById('root');
if (rootElement) {
  const root = ReactDOMClient.createRoot(rootElement);
  root.render(
    <>
      <BarkApp />
      <ToastContainer />
    </>
  );
}
