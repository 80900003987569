import * as React from 'react';

import { Item } from './types';
import { getItemsExport } from '../api';
import { Spinner } from '../assets/Spinner';
import { Table } from '../component/Table';
import { TableTools } from '../component/TableTools';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { ItemsHeader } from '../tableHeaders';

export const Items = () => {
  const mapItem = (item: Item) => {
    return {
      'Item SKU': `${item.sku}`,
      Name: item.name,
      'Weight (oz)': `${item.weight_oz}`,
      Volume: `${item.volume}`,
      'Recipe ID': `${item.recipe}`,
    };
  };

  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<Item>({
    endpoint: '/items/',
    mapper: mapItem,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Items</h1>
      </div>
      <TableTools
        renderPagination={renderPagination}
        forPagination={forPagination}
        isLoading={isLoading}
        searchBy={searchBy}
        reFetch={reFetch}
        hasData={data.length > 0}
        createLabel="Create Item"
        createLink="/items/create-item"
        changelogLink="/items/changelog"
        onClickDownload={getItemsExport}
      >
        <Table header={ItemsHeader} data={data} internalPagination={false} />
      </TableTools>
    </div>
  );
};
