export const formatDate = (dateStr: string | undefined) => {
  if (!dateStr) {
    return '--';
  }
  const date = new Date(dateStr);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');

  return `${month}-${day}-${year}`;
};

export const formatDateApi = (dateStr: string | undefined) => {
  if (!dateStr) {
    return '--';
  }
  const date = new Date(dateStr);
  const year: number = date.getFullYear();
  const month: string = String(date.getMonth() + 1).padStart(2, '0');
  const day: string = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const formatDateTime = (dateStr: string | undefined, use_ET = false) => {
  if (!dateStr) {
    return '--';
  }
  const date = new Date(dateStr);
  const formattedDate = date.toLocaleString().replace(',', '');
  if (use_ET) {
    return formattedDate + ' ET';
  }

  const formattedTimeZone = Intl.DateTimeFormat('en-US', { timeZoneName: 'short' })
    .formatToParts(new Date())
    .find((timeZone: any) => timeZone.type === 'timeZoneName')?.value;

  return formattedDate + ' ' + formattedTimeZone;
};

export const mapSntApiDate = (dateStr: string | undefined) => {
  if (!dateStr) {
    return '--';
  }
  dateStr = dateStr.split('T')[0];
  const dateList = dateStr.split('-');
  const year = dateList[0];
  const month = dateList[1];
  const day = dateList[2];

  return `${month}/${day}/${year}`;
};
