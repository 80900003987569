import React from 'react';

export const TrashIcon = ({ className, onClick }: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg viewBox="0 0 90 90" fill="none" className={className} onClick={onClick}>
      <path
        d="M64.71 90H25.291c-4.693 0-8.584-3.67-8.859-8.355l-3.928-67.088c-0.048-0.825 0.246-1.633 0.812-2.234 0.567-0.601 1.356-0.941 2.183-0.941h59.002c0.826 0 1.615 0.341 2.183 0.941 0.566 0.601 0.86 1.409 0.813 2.234l-3.928 67.089C73.294 86.33 69.403 90 64.71 90zM18.679 17.381l3.743 63.913c0.088 1.519 1.349 2.707 2.869 2.707H64.71c1.52 0 2.779-1.188 2.868-2.705l3.742-63.914H18.679z"
        fill="currentColor"
      />
      <path
        d="M80.696 17.381H9.304c-1.657 0-3-1.343-3-3s1.343-3 3-3h71.393c1.657 0 3 1.343 3 3s-1.343 3-3 3z"
        fill="currentColor"
      />
      <path
        d="M58.729 17.381H31.271c-1.657 0-3-1.343-3-3V8.789C28.271 3.943 32.214 0 37.061 0h15.879c4.847 0 8.789 3.943 8.789 8.789v5.592c0 1.657-1.343 3-3 3zM34.271 11.381h21.457V8.789C55.729 7.251 54.478 6 52.939 6H37.061c-1.538 0-2.789 1.251-2.789 2.789v2.592z"
        fill="currentColor"
      />
      <path
        d="M58.33 74.991c-0.06 0-0.118-0.002-0.179-0.005-1.653-0.097-2.916-1.517-2.819-3.171l2.474-42.244c0.097-1.655 1.508-2.933 3.171-2.819 1.653 0.097 2.916 1.516 2.819 3.17l-2.474 42.245c-0.093 1.655-1.416 2.885-3.012 2.919z"
        fill="currentColor"
      />
      <path
        d="M31.669 74.991c-1.577 0-2.898-1.23-2.992-2.824l-2.473-42.245c-0.097-1.654 1.165-3.073 2.819-3.17 1.646-0.111 3.073 1.165 3.17 2.819l2.473 42.244c0.097 1.654-1.165 3.074-2.819 3.171-0.061 0.003-0.12 0.005-0.179 0.005z"
        fill="currentColor"
      />
      <path
        d="M45 74.991c-1.657 0-3-1.343-3-3V29.747c0-1.657 1.343-3 3-3 1.657 0 3 1.343 3 3v42.244c0 1.657-1.343 3-3 3z"
        fill="currentColor"
      />
    </svg>
  );
};
