import { Orders, Order } from './pages/types';

export const addLeadingZeroToMonth = (month: string) => {
  // Ensure that the month is a number between 1 and 12
  const numericMonth = parseInt(month, 10);
  if (isNaN(numericMonth) || numericMonth < 1 || numericMonth > 12) {
    return '--';
  }

  // Use String.padStart to add a leading zero
  const formattedMonth = numericMonth.toString().padStart(2, '0');
  return formattedMonth;
};

export const getCardSummary = (cardNumber: string, cardType: string, cardDetails: any) => {
  let summary = '';
  let expiration = '';
  if (cardNumber && cardType) {
    summary = `${cardType} x${cardNumber}`;
    if (cardDetails?.exp_month && cardDetails?.exp_year) {
      expiration = `${addLeadingZeroToMonth(cardDetails.exp_month)}/${cardDetails.exp_year}`;
    }
    return `${summary} ${expiration}`;
  }
  return null;
};

export const computeCustomerSpend = (orders: Orders): string => {
  const orderList = [...orders.past, ...orders.processing].filter(
    (pastOrder: any) =>
      pastOrder.status !== 'CANCELLED' &&
      pastOrder.status !== 'HOLD' &&
      pastOrder.status !== 'ERROR'
  );
  const orderSum = sumList(mapOrderToTotalPrice(orderList));

  return Number(orderSum).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
};

export const calculateUnitPriceAfterDiscount = (product: any) => {
  if (!product) {
    throw new Error('Product is null or undefined');
  }
  const unitPrice = parseNumber(product.unit_price);
  const priceAfterDiscount =
    unitPrice - parseNumber(product.discount_amount) / parseNumber(product.quantity);
  return priceAfterDiscount.toFixed(2);
};

export const calculatePriceAfterDiscount = (product: any) => {
  if (!product) {
    throw new Error('Product is null or undefined');
  }
  const price = parseNumber(product.unit_price) * parseNumber(product.quantity);
  const priceAfterDiscount = price - parseNumber(product.discount_amount);
  return priceAfterDiscount.toFixed(2);
};

export const calculateAmountRefundedWithoutTax = (
  priceAfterDiscount: any,
  alreadyRefundedAmount: any,
  taxAmount: any
) => {
  const priceAfterDiscountNumber = parseNumber(priceAfterDiscount);
  const alreadyRefundedNumber = parseNumber(alreadyRefundedAmount);
  if (alreadyRefundedNumber === 0) {
    return '0.00';
  }

  const taxRate = parseNumber(taxAmount) / priceAfterDiscountNumber;
  const amountRefundedWithoutTax = alreadyRefundedNumber / (1 + taxRate);
  return amountRefundedWithoutTax.toFixed(2);
};

export const calculatePriceAfterDiscountWithTax = (product: any) => {
  if (!product) {
    throw new Error('Product is null or undefined');
  }
  const price = parseNumber(product.unit_price) * parseNumber(product.quantity);
  const priceAfterDiscount = price - parseNumber(product.discount_amount);
  const priceWithTax = priceAfterDiscount + parseNumber(product.tax_amount);
  return priceWithTax.toFixed(2);
};

export const calculateOrderProductRefundTaxAmount = (
  pricePaid: string,
  amountToRefund: string,
  product: any
) => {
  if (!product) {
    throw new Error('Product is null or undefined');
  }
  if (parseNumber(product.unit_price) === 0) {
    return product.unit_price;
  }
  const taxRate = parseNumber(product.tax_amount) / parseNumber(pricePaid);
  const refundTax = taxRate * parseNumber(amountToRefund);
  return refundTax.toFixed(2);
};

export const calculateOrderProductRefundAmount = (
  pricePaid: string,
  amountToRefund: string,
  product: any
) => {
  if (!product) {
    throw new Error('Product is null or undefined');
  }
  if (parseNumber(product.unit_price) === 0) {
    return product.unit_price;
  }
  const refundTax = parseNumber(
    calculateOrderProductRefundTaxAmount(pricePaid, amountToRefund, product)
  );
  const totalRefundAmount = parseNumber(amountToRefund) + refundTax;
  return totalRefundAmount.toFixed(2);
};

const mapOrderToTotalPrice = (orders: Order[]) =>
  orders.map((order: Order) => parseNumber(order.total_price) - parseNumber(order.total_refunds));

const sumList = (numbersList: number[]): number => {
  const sum = numbersList.reduce((accumulator, currentValue) => {
    return accumulator + currentValue;
  }, 0);

  // Truncate the result to 2 decimal places
  return parseFloat(sum.toFixed(2));
};

export const parseNumber = (number: string | null | undefined) => {
  if (number) {
    return Number(number);
  }

  console.warn('Number is null or undefined defaulting to 0');
  return Number('0');
};
