import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';

import { ConfirmationModal, ConfirmationModalProps } from './ConfirmationModal';
import { OneTimeProductModal } from './OneTimeProductModal';
import ProductPrice from './ProductPrice';
import { Table } from './Table';
import { axios } from '../api';
import { mapSntApiDate } from '../dateUtils';
import { calculatePriceAfterDiscountWithTax } from '../financialUtils';
import { Customer } from '../pages/types';
import { ProductsTableHeader } from '../tableHeaders';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

interface ProductsProps {
  customer: Customer;
  refreshCustomer: () => void;
  products: any;
  order: any;
}

// Meal products should come first
export const sortProducts = (unorderedProducts: any) => {
  const orderedProducts = [];
  // Handle Products of type meal first
  for (const product of unorderedProducts) {
    if (product.product_type === 'MEAL') {
      orderedProducts.push(product);
    }
  }

  // Handle SNACKS, SUPPLEMENTS, Etc next within the petplan
  for (const product of unorderedProducts) {
    if (product.product_type !== 'MEAL') {
      orderedProducts.push(product);
    }
  }

  return orderedProducts;
};

export const Products = ({ customer, products, order, refreshCustomer }: ProductsProps) => {
  const [showOneTimeProductModal, setShowOneTimeProductModal] = useState(false);
  const orderedProducts = sortProducts(products);

  const [deleteModalData, setDeletedModalData] = useState<ConfirmationModalProps | null>(null);
  const discount_codes = order.discounts?.length > 0 ? [order.discounts[0].discount.code] : [];

  const deleteOneTimeProduct = (productToRemove: any) => {
    axios
      .put(`orders/${order.id}`, {
        account: customer.id,
        order_products: order.products
          .filter((product: any) => product.code !== productToRemove.code)
          .map((product: any) => {
            return {
              pet_plan: product.pet_plan.id,
              product_code: product.code,
              quantity: product.quantity,
              type: product.type,
            };
          }),
        discount_codes,
        order_type: order.order_type,
        order_id: order.id,
      })
      .then(() => {
        setDeletedModalData(null);
        refreshCustomer();
        toast.success(
          `We removed one-time product ${productToRemove.code} from this customer's upcoming order.`,
          BOTTOM_TOAST
        );
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  const showDeleteProductModal = (product: any) => {
    setDeletedModalData({
      title: 'Are you sure you want to remove this one-time product from this order?',
      message: `
      By clicking “Confirm” below, you will remove ${product.code}
        from ${product?.pet_plan?.pet_name}'s
        next order dated ${mapSntApiDate(order.scheduled)}.`,
      onCancel: () => setDeletedModalData(null),
      onConfirm: () => {
        deleteOneTimeProduct(product);
        setDeletedModalData(null);
      },
      confirmLabel: 'Confirm',
    });
  };

  return (
    <div className="py-3">
      <h2 className="mb-1 flex items-center">
        <b className="inline-block">Products</b>
        {order.status === 'PREVIEW' && (
          <div className="text-sm ml-2">
            <a
              onClick={() => setShowOneTimeProductModal(true)}
              className="cursor-pointer underline text-anchor"
            >
              Add One-Time Product
            </a>
          </div>
        )}
      </h2>
      <div className="bg-white">
        <Table
          header={ProductsTableHeader}
          data={orderedProducts.map((product: any) => {
            return {
              'Pet Name': product.pet_plan?.pet_name ?? '',
              'Product Code': (
                <div>
                  {product.code ?? '--'}
                  {product.type === 'ONETIME' && (
                    <a
                      className="cursor-pointer text-red-600 pl-[2px] pr-[8px]"
                      onClick={() => showDeleteProductModal(product)}
                    >
                      X
                    </a>
                  )}
                </div>
              ),
              'Product Description': product.name ?? '--',
              Price: <ProductPrice product={product} />,
              'Price with Tax': `$${calculatePriceAfterDiscountWithTax(product)}`,
            };
          })}
        />
      </div>
      {showOneTimeProductModal && (
        <OneTimeProductModal
          customer={customer}
          order={order}
          onConfirm={() => {
            setShowOneTimeProductModal(false);
            refreshCustomer();
          }}
          onCancel={() => setShowOneTimeProductModal(false)}
        />
      )}
      {!!deleteModalData && <ConfirmationModal {...deleteModalData} />}
    </div>
  );
};
