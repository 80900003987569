import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { stateAbbreviations } from './constants';
import { Button } from '../component/Button';
import { TextInput } from '../component/TextInput';
import { BOTTOM_TOAST } from '../toastUtils';

export type CustomerSearchParams = {
  email: string;
  order_id: string;
  phone_number: string;
  first_name: string;
  last_name: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zip_code: string;
};
interface CustomerLookupFormProps {
  handleSearch: (params: CustomerSearchParams) => void;
}

export const CustomerLookupForm = ({ handleSearch }: CustomerLookupFormProps) => {
  const [email, setEmail] = useState('');
  const [orderId, setOrderId] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [address1, setAddress1] = useState('');
  const [address2, setAddress2] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [zipCode, setZipCode] = useState('');

  const searchCustomers = () => {
    if (phoneNumber && phoneNumber.length !== 10) {
      toast.error(
        'Phone number must be 10 digits with no spaces, parenthesis, or dashes',
        BOTTOM_TOAST
      );
      return;
    }

    let sanitizedState = state;
    if (state && state.length > 2) {
      const stateNameOptions = stateAbbreviations.find((stateNames) =>
        stateNames.long.toLocaleLowerCase().includes(state.toLocaleLowerCase())
      );
      if (stateNameOptions) {
        sanitizedState = stateNameOptions.short;
      }
    }

    handleSearch({
      email,
      order_id: orderId,
      phone_number: phoneNumber === '' ? phoneNumber : `+1${phoneNumber}`,
      first_name: firstName,
      last_name: lastName,
      address_1: address1,
      address_2: address2,
      city,
      state: sanitizedState,
      zip_code: zipCode,
    });
  };

  return (
    <div className="w-full h-full max-w-screen-lg flex flex-col items-center justify-center gap-4">
      <h1>Customer Lookup</h1>
      <div className="w-full flex h-full items-start gap-12">
        <div className="w-full h-full flex flex-col justify-start gap-2">
          <TextInput label="Customer's Email" setTextChange={setEmail} onEnter={searchCustomers} />
          <TextInput label="Order ID" setTextChange={setOrderId} onEnter={searchCustomers} />
          <TextInput
            label="Phone Number"
            setTextChange={setPhoneNumber}
            onEnter={searchCustomers}
          />
          <TextInput
            label="Customer's First Name"
            setTextChange={setFirstName}
            onEnter={searchCustomers}
          />
          <TextInput
            label="Customer's Last Name"
            setTextChange={setLastName}
            onEnter={searchCustomers}
          />
        </div>
        <div className="w-full h-full flex flex-col justify-start gap-2">
          <TextInput label="Address Line 1" setTextChange={setAddress1} onEnter={searchCustomers} />
          <TextInput label="Address Line 2" setTextChange={setAddress2} onEnter={searchCustomers} />
          <TextInput label="City" setTextChange={setCity} onEnter={searchCustomers} />
          <TextInput label="State" setTextChange={setState} onEnter={searchCustomers} />
          <TextInput label="Zip" setTextChange={setZipCode} onEnter={searchCustomers} />
        </div>
      </div>
      <div className="flex items-center justify-center mt-5">
        <Button label="Search" onClick={searchCustomers} />
      </div>
    </div>
  );
};
