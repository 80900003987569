import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { axios } from '../api';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

import { Customer } from '@/pages/types';

export const useGetCustomer = (customerId: any) => {
  const [customer, setCustomer] = useState<Customer | null>(null);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [triggerRequest, setTriggerRequest] = useState(0);

  const refreshCustomer = () => {
    setTriggerRequest((prev) => prev + 1);
  };

  const getCustomer = () => {
    axios
      .get(`accounts/${customerId}`)
      .then((response) => {
        setCustomer(response.data);
        setIsLoading(false);
        setIsError(false);
      })
      .catch((e) => {
        setIsError(true);
        setIsLoading(false);
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  useEffect(() => {
    getCustomer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [triggerRequest]);

  return {
    customer,
    refreshCustomer,
    isLoading,
    isError,
  } as {
    customer: Customer;
    refreshCustomer: () => void;
    isLoading: boolean;
    isError: boolean;
  };
};
