import React, { useState } from 'react';

import { Button } from './Button';
import { TOTPQRCode } from './TOTPQRCode';
import { TextInput } from './TextInput';
import { Close } from '../assets/Close';

interface MFAModalProps {
  totpSecret: string;
  requiresTotpSetup: boolean;
  onClose: () => void;
  onVerify: (token: string) => void;
}

export const MFAModal = ({ totpSecret, requiresTotpSetup, onClose, onVerify }: MFAModalProps) => {
  const [totp, setTotpChange] = useState('');

  const handleVerify = () => {
    if (totp.length > 0) {
      onVerify(totp);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center p-4 justify-center z-50 bg-gray-500 bg-opacity-50">
      <div className="bg-white rounded-lg shadow-md p-8 w-[400px] relative">
        <Close
          className="absolute top-4 right-4 w-6 h-6 cursor-pointer text-gray-500 hover:text-gray-700"
          onClick={onClose}
        />
        <div className="flex flex-col items-center text-center">
          {requiresTotpSetup && <TOTPQRCode configUrl={totpSecret} />}
          <TextInput
            label="Enter the code generated by your authenticator app"
            className="w-full max-w-xs mx-auto "
            setTextChange={setTotpChange}
            onEnter={() => {
              handleVerify();
            }}
          />
          <div className="flex items-center justify-center mt-5">
            <Button label="Verify" onClick={handleVerify} isDisabled={totp.length === 0} />
          </div>
        </div>
      </div>
    </div>
  );
};
