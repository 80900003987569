import React, { useState, useMemo, useCallback, useEffect } from 'react';
import { toast } from 'react-toastify';

import { SplitFulfillmentCTA } from './Button';
import { useSplitFulfillment } from './OrderFulfillments';
import { Pagination } from './Pagination';
import { isOpsAdmin, isAllModuleAccessUser } from '../pages/constants';
import { BOTTOM_TOAST } from '../toastUtils';

interface TableProps<T> {
  header: string[];
  data: T[];
  pageSize?: number;
  paginatorsPerPageBlock?: number;
  internalPagination?: boolean;
  tdClass?: string;
}

const usePaginationPages = (
  data: any[],
  length: number,
  pageSize: number,
  paginatorsPerPageBlock = 5
) => {
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = useMemo(() => {
    return Math.ceil(length / pageSize);
  }, [length, pageSize]);

  const canGo = useMemo(() => {
    return {
      next: currentPage < totalPages,
      previous: currentPage - 1 > 0,
    };
  }, [currentPage, totalPages]);

  const pages = useMemo(() => {
    const start = Math.floor((currentPage - 1) / paginatorsPerPageBlock) * paginatorsPerPageBlock;
    const end =
      start + paginatorsPerPageBlock > totalPages ? totalPages : start + paginatorsPerPageBlock;
    return Array.from({ length: end - start }, (_, i) => start + i + 1);
  }, [currentPage, paginatorsPerPageBlock, totalPages]);

  const goTo = (pg: number) => {
    setCurrentPage(pg);
  };

  const goNext = useCallback(() => {
    if (canGo.next) {
      setCurrentPage((prev) => prev + 1);
    }
  }, [canGo]);

  const goPrev = useCallback(() => {
    if (canGo.previous) {
      setCurrentPage((prev) => prev - 1);
    }
  }, [canGo]);

  const currentData = data.slice((currentPage - 1) * pageSize, pageSize * currentPage);
  const renderPagination = data.length > pageSize;

  return {
    canGo,
    currentPage,
    pages,
    goTo,
    goNext,
    goPrev,
    currentData,
    renderPagination,
    countOfResults: length,
  };
};

export const Table = <T extends Record<string, any>>({
  header,
  data,
  pageSize = 25,
  paginatorsPerPageBlock,
  internalPagination = true,
  tdClass,
}: TableProps<T>) => {
  const { currentData, renderPagination, ...forPagination } = usePaginationPages(
    data,
    data.length,
    pageSize,
    paginatorsPerPageBlock
  );
  if (currentData.length) {
    return (
      <>
        <table className="min-w-full table-auto rounded border border-separate text-center">
          <thead className="bg-slate50">
            <tr>
              {header.map((column, index) => (
                <th key={index} className="px-4 py-2">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index} className="bg-white">
                {header.map((column, colIndex) => (
                  <td key={colIndex} className={`px-4 py-2 ${tdClass}`}>
                    {Array.isArray(item[column]) ? (
                      <ul>
                        {item[column].map((listItem: string, listIndex: number) => (
                          <li key={String(listIndex)} className="whitespace-nowrap text-left">
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      item[column]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {internalPagination && renderPagination && <Pagination {...forPagination} />}
      </>
    );
  }
  return null;
};

export const FulfillmentsTable = <T extends Record<string, any>>({
  header,
  data,
  pageSize = 25,
  paginatorsPerPageBlock,
  internalPagination = true,
  tdClass,
}: TableProps<T>) => {
  const { currentData, renderPagination, ...forPagination } = usePaginationPages(
    data,
    data.length,
    pageSize,
    paginatorsPerPageBlock
  );
  const showSplitFulfillmentCTA = isOpsAdmin() || isAllModuleAccessUser();
  const splitFulfillment = useSplitFulfillment(); // Get the function from the hook

  const allFulfillmentsByID = useMemo(() => {
    const map: Record<string, any[]> = {};
    for (const index in data) {
      const fulfillment = data[index];
      const id = fulfillment['Fulfillment ID'];
      if (id in map) {
        map[id].push(fulfillment);
      } else {
        map[id] = [fulfillment];
      }
    }
    return map;
  }, [data]);

  useEffect(() => {
    const tableData = Object.keys(allFulfillmentsByID);

    // Gets the highest tag (-S#) from fulfillment ID
    const findHighestTag = (baseId: string): string => {
      const extractSuffix = (id: string): number => {
        const suffix = id.split('-S').pop();
        return suffix ? parseInt(suffix, 10) : NaN; // Return NaN for invalid parsing
      };

      const matchingIds = tableData.filter((id) => id.startsWith(`${baseId}-S`));
      if (matchingIds.length === 0) {
        return baseId;
      }

      const sortedIds = matchingIds.sort((a, b) => extractSuffix(b) - extractSuffix(a));

      return sortedIds[0];
    };

    const savedToast = localStorage.getItem('toastMessage');

    if (savedToast) {
      const { message: fulfillmentId, type } = JSON.parse(savedToast);

      if (fulfillmentId) {
        const highestTag = findHighestTag(fulfillmentId);
        const toastMsg = `Fulfillment ${highestTag} successfully split off from fulfillment ${fulfillmentId}`;

        if (type === 'success') {
          toast.success(toastMsg, BOTTOM_TOAST);
        } else if (type === 'error') {
          toast.error(toastMsg);
        }
      }
      localStorage.removeItem('toastMessage');
    }
  }, [allFulfillmentsByID]);

  const seenFulfillmentIDs = new Set();

  if (currentData.length) {
    return (
      <>
        <table className="min-w-full table-auto rounded border border-separate text-center">
          <thead className="bg-slate50">
            <tr>
              {header.map((column, index) => (
                <th key={index} className="px-4 py-2">
                  {column}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {currentData.map((item, index) => (
              <tr key={index} className="bg-white">
                {header.map((column, colIndex) => (
                  <td key={colIndex} className={`px-4 py-2 ${tdClass}`}>
                    {Array.isArray(item[column]) ? (
                      <ul>
                        {item[column].map((listItem: string, listIndex: number) => (
                          <li key={String(listIndex)} className="whitespace-nowrap text-left">
                            {listItem}
                          </li>
                        ))}
                      </ul>
                    ) : column === 'Fulfillment ID' ? (
                      <div>
                        {(() => {
                          if (seenFulfillmentIDs.has(item[column])) {
                            return '--';
                          } else {
                            seenFulfillmentIDs.add(item[column]);
                            return item.Is_Splittable &&
                              item[column] !== '--' &&
                              showSplitFulfillmentCTA ? (
                              <div className="flex flex-col">
                                {item[column]}
                                <SplitFulfillmentCTA
                                  className="px-[1px] py-[1px] ml-1"
                                  variant="secondary"
                                  textClassName="text-sm font-normal"
                                  label="Split Fulfillment"
                                  onClick={() => splitFulfillment(item[column])}
                                />
                              </div>
                            ) : (
                              item[column]
                            );
                          }
                        })()}
                      </div>
                    ) : (
                      item[column]
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {internalPagination && renderPagination && <Pagination {...forPagination} />}
      </>
    );
  }
  return null;
};
