import * as React from 'react';
import { useEffect, useState } from 'react';

import { SystemChangelogItem } from './types';
import { axios } from '../api';
import { ChangelogList } from '../component/ChangelogList';

export enum Category {
  Items,
  ProductItems,
  Discounts,
}

interface SystemChangelogProps {
  category: Category;
}

export const SystemChangelog = ({ category }: SystemChangelogProps) => {
  const [changelog, setChangelog] = useState<SystemChangelogItem[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [changelogError, setChangelogError] = useState('');
  const categoryData = {
    [Category.Items]: { url: 'items', title: 'Items' },
    [Category.ProductItems]: { url: 'product_items', title: 'Product Items' },
    [Category.Discounts]: { url: 'discounts', title: 'Discounts' },
  };

  const url = `system_changelog/${categoryData[category].url}`;

  const fetchChangelogResolvePart = () =>
    axios.get(url).then((response) => {
      // changelog data is pre-sorted by date in ascending order in DynamoDB
      // reverse to get descending order
      setChangelog((response.data as any[]).reverse());
      setIsLoading(false);
    });

  const getChangelog = () => {
    fetchChangelogResolvePart().catch(() => {
      fetchChangelogResolvePart().catch(() => {
        setIsLoading(false);
        setChangelogError('Request failed, please try to refresh the page');
      });
    });
  };

  useEffect(() => {
    getChangelog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div className="h-[calc(100vh-77px)] w-full bg-slate50 py-8 px-[150px]">
      <div className="py-3">
        <h1 className="text-3xl">
          <b>{categoryData[category].title}</b>
        </h1>
      </div>
      <div className="mt-2 max-h-[500px] bg-white border rounded-lg p-4">
        <div className="pb-3">
          <h1>
            <b>Changelog</b>
          </h1>
        </div>
        <ChangelogList
          type="system"
          isLoading={isLoading}
          changelogError={changelogError}
          changelogItems={changelog}
        />
      </div>
    </div>
  );
};
