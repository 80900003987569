import * as React from 'react';

type LineInfoProps = {
  title: string;
  value: string | undefined;
  placeholder?: string;
};
export const LineInfo = ({ title, value, placeholder }: LineInfoProps) => {
  return (
    <div className="mb-2">
      <strong>{title}:</strong> {value || placeholder}
    </div>
  );
};
