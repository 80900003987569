import * as React from 'react';

export type TableRowData = {
  [key: string]: any;
};

export interface CheckboxTableProps<T> {
  header: string[];
  data: T[];
  selectedRows: number[];
  onCheckboxChange: (index: number) => void;
}

export const CheckboxTable = <T extends TableRowData>({
  header,
  data,
  selectedRows,
  onCheckboxChange,
}: CheckboxTableProps<T>) => {
  return (
    <table className="min-w-full table-auto rounded border border-separate">
      <thead className="bg-slate50">
        <tr>
          <th className="px-4 py-2" />
          {header.map((column, index) => (
            <th key={index} className="px-4 py-2">
              {column}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, index) => (
          <tr key={index} className="bg-white">
            <td className="px-4 py-2">
              <input
                type="checkbox"
                checked={selectedRows.includes(index)}
                onChange={() => onCheckboxChange(index)}
              />
            </td>
            {header.map((column, colIndex) => (
              <td key={colIndex} className="px-4 py-2">
                {item[column]}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
