import axios from 'axios';
import React, { useState } from 'react';
import { toast } from 'react-toastify';

import { API_URL } from '../api';
import { Button } from '../component/Button';
import { TextInput } from '../component/TextInput';
import { BOTTOM_TOAST, generateErrorMsg } from '../toastUtils';

export const ForgotPassword = () => {
  const [email, setEmailChange] = useState('');

  const sendResetLink = () => {
    axios
      .post(
        `${API_URL()}/password_reset_request`,
        {
          email: email.toLowerCase(),
        },
        {
          headers: {
            'x-application': 'BARK',
          },
        }
      )
      .then(() => {
        toast.success(
          `If the email address is associated with a BARK account, you'll receive an email to create your password. If you don't receive an email within a couple of minutes, please check your spam folder.`,
          BOTTOM_TOAST
        );
      })
      .catch((e) => {
        toast.error(generateErrorMsg(e), BOTTOM_TOAST);
      });
  };

  return (
    <div className="flex h-screen items-center justify-center bg-slate50">
      <div className="grid gap-2 min-w-[300px]">
        <div className="flex justify-center mb-5">
          <img src={require('../assets/fullBarkLogo.png')} />
        </div>
        <div className="justify-center max-w-[370px] text-center mb-6">
          <h4 className="font-bold mb-2">Reset password</h4>
          <span>
            Enter the email address associated with your BARK account{' '}
            <strong>(e.g., jamie+BARK@hirehoratio.co)</strong>, and we'll email you a link to reset
            your password.
          </span>
        </div>
        <TextInput label="Email" setTextChange={setEmailChange} />
        <div className="flex items-center justify-center mt-5">
          <Button label="Send Reset Link" onClick={sendResetLink} isDisabled={email.length === 0} />
        </div>
      </div>
    </div>
  );
};
