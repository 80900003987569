import React from 'react';

export type PaginationProps = {
  currentPage: number;
  canGo: {
    next: boolean;
    previous: boolean;
  };
  pages: number[];
  goTo: (pg: number) => void;
  goNext: () => void;
  goPrev: () => void;
  countOfResults?: number | null;
};

export const Pagination = ({
  currentPage,
  canGo,
  pages,
  goTo,
  goNext,
  goPrev,
  countOfResults = null,
}: PaginationProps) => {
  return (
    <div className="flex flex-col items-center">
      <div
        className="min-w-full"
        style={{
          marginTop: '1em',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <button disabled={!canGo.previous} className="p-5" onClick={goPrev}>
          ←
        </button>
        {pages.map((page, index) => (
          <button
            key={`page+${index}`}
            disabled={currentPage === page}
            className={`${currentPage === page ? 'bg-primary' : 'bg-secondary'} px-4 py-2 rounded`}
            onClick={() => {
              goTo(page);
            }}
          >
            {page}
          </button>
        ))}
        <button disabled={!canGo.next} className="p-5" onClick={goNext}>
          →
        </button>
      </div>
      {countOfResults && <p className="pb-[10px] text-xs">{countOfResults} results</p>}
    </div>
  );
};
