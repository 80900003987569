import * as React from 'react';
import { Link } from 'react-router-dom';

import { Discount } from './types';
import { Spinner } from '../assets/Spinner';
import { ChangeDiscountStatus } from '../component/ChangeDiscountStatus';
import { Table } from '../component/Table';
import { TableTools } from '../component/TableTools';
import { formatDateTime } from '../dateUtils';
import { useFetchWithPagination } from '../hooks/useFetchWithPagination';
import { DiscountsHeader } from '../tableHeaders';

export const Discounts = () => {
  const mapDiscount = (discount: Discount) => {
    const orderTypes = {
      Trial: discount.order_trial ? '✅' : '❌',
      Subscription: discount.order_subscription ? '✅' : '❌',
      Onetime: discount.order_onetime ? '✅' : '❌',
    };
    return {
      'Discount Code': (
        <Link to={`/discounts/${discount.id}`} className="text-blue-500 hover:underline">
          {discount.code}
        </Link>
      ),
      'Discount Type': discount.discount_type.replace(/ Discount$/, ''),
      Status: (
        <ChangeDiscountStatus
          isActive={discount.is_active}
          discountId={discount.id}
          discountCode={discount.code}
          reFetch={reFetch}
        />
      ),
      Value: discount.value,
      'Start date': `${formatDateTime(discount.available_start_date)}`,
      'End date': `${formatDateTime(discount.available_end_date)}`,
      'Limit type': discount.limit_type,
      'Limit value': discount.limit_value ?? '--',
      'Order Types': [
        `${orderTypes.Trial} Trial`,
        `${orderTypes.Subscription} Subscription`,
        `${orderTypes.Onetime} Onetime`,
      ],
    };
  };

  const {
    currentData: data,
    renderPagination,
    reFetch,
    searchBy,
    isLoading,
    ...forPagination
  } = useFetchWithPagination<Discount>({
    endpoint: '/discounts/',
    mapper: mapDiscount,
  });

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div className="relative h-[calc(100vh-77px)] bg-slate50 p-8">
      <div className="flex items-center justify-between mb-3">
        <h1>Manage Discounts</h1>
      </div>
      <TableTools
        renderPagination={renderPagination}
        forPagination={forPagination}
        isLoading={isLoading}
        searchBy={searchBy}
        reFetch={reFetch}
        hasData={data.length > 0}
        createLabel="Create Discount"
        createLink="/discounts/create-discount"
        changelogLink="/discounts/changelog"
      >
        <Table header={DiscountsHeader} data={data} internalPagination={false} />
      </TableTools>
    </div>
  );
};
